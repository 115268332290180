var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "process-panel__container",
      style: { "max-width": this.width + "px", width: "38%" }
    },
    [
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.activeTab,
            callback: function($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab"
          }
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { name: "base" } },
            [
              _c(
                "div",
                {
                  staticClass: "panel-tab__title",
                  attrs: { slot: "title" },
                  slot: "title"
                },
                [_c("i", { staticClass: "el-icon-info" }), _vm._v("常规 ")]
              ),
              _c("element-base-info", {
                attrs: {
                  "id-edit-disabled": _vm.idEditDisabled,
                  "business-object": _vm.elementBusinessObject,
                  type: _vm.elementType,
                  model: _vm.model,
                  id: _vm.elementId
                }
              })
            ],
            1
          ),
          _vm.conditionFormVisible
            ? _c(
                "el-collapse-item",
                { key: "condition", attrs: { name: "condition" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "panel-tab__title",
                      attrs: { slot: "title" },
                      slot: "title"
                    },
                    [
                      _c("i", { staticClass: "el-icon-s-promotion" }),
                      _vm._v("流转条件 ")
                    ]
                  ),
                  _c("flow-condition", {
                    attrs: {
                      "business-object": _vm.elementBusinessObject,
                      type: _vm.elementType,
                      model: _vm.model
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.elementType.indexOf("Task") !== -1
            ? _c(
                "el-collapse-item",
                { key: "formPermissions", attrs: { name: "formPermissions" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "panel-tab__title",
                      attrs: { slot: "title" },
                      slot: "title"
                    },
                    [
                      _c("i", { staticClass: "el-icon-s-ticket" }),
                      _vm._v("表单权限 ")
                    ]
                  ),
                  _c("formPermissions", {
                    attrs: {
                      bpmnModeler: _vm.bpmnModeler,
                      "business-object": _vm.elementBusinessObject,
                      model: _vm.model
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }