var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("node", {
    attrs: {
      title: "发起人",
      "is-root": true,
      content: _vm.content,
      placeholder: "所有人",
      "header-bgc": "#576a95",
      "header-icon": "el-icon-user-solid"
    },
    on: {
      insertNode: function(type) {
        return _vm.$emit("insertNode", type)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }