var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          staticClass: "avatar-uploader",
          attrs: {
            action: _vm.uploadAction,
            "show-file-list": false,
            data: _vm.uploadData,
            "on-success": _vm.handleSuccess,
            "before-upload": _vm.beforeAvatarUpload,
            accept: "image/*"
          }
        },
        [
          _vm.imageUrl.url
            ? _c("img", {
                staticClass: "avatar",
                attrs: { src: _vm.imageUrl.url }
              })
            : _c("i", { staticClass: "el-icon-plus avatar-uploader-icon" })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }