<template>
  <div id="app" class="boxbox">
    <keep-alive>
      <router-view v-if="refresh" :key="$route.fullPath + key"></router-view>
    </keep-alive>
  </div>
</template>

<script>
import actions from "./actions";
export default {
  data() {
    return {
      refresh: true,
      key: 1,
    };
  },
  created() {
  },
  mounted() {
    actions.onGlobalStateChange((state) => {
      console.log("我是子应用，我检测到数据了：", state);
      if (this.$route.path.includes("/main/workbench/ProcessSetting")) {
        if (state.refreshaaa == false) {
          this.refresh = state.refreshaaa;
          setTimeout(() => {
            this.key++;
            this.refresh = true;
          }, 100);
        }
      }
    }, true); //onGlobalStateChange的第二个参数设置为true，则会立即触发一次观察者函数
  },
  methods: {},
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
  height: calc(100vh - 60px);
}
</style>
