var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { attrs: { "label-position": "top", "label-width": "90px" } },
        [
          _c(
            "el-form-item",
            {
              staticClass: "user-type",
              attrs: { label: "选择触发的动作", prop: "text" }
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.type,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "type", $$v)
                    },
                    expression: "config.type"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: "WEBHOOK" } }, [
                    _vm._v("发送网络请求")
                  ]),
                  _c("el-radio", { attrs: { label: "EMAIL" } }, [
                    _vm._v("发送邮件")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm.config.type === "WEBHOOK"
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "请求地址", prop: "text" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            placeholder: "请输入URL地址",
                            size: "medium"
                          },
                          model: {
                            value: _vm.config.http.url,
                            callback: function($$v) {
                              _vm.$set(_vm.config.http, "url", $$v)
                            },
                            expression: "config.http.url"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "85px" },
                              attrs: { slot: "prepend", placeholder: "URL" },
                              slot: "prepend",
                              model: {
                                value: _vm.config.http.method,
                                callback: function($$v) {
                                  _vm.$set(_vm.config.http, "method", $$v)
                                },
                                expression: "config.http.method"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "GET", value: "GET" }
                              }),
                              _c("el-option", {
                                attrs: { label: "POST", value: "POST" }
                              }),
                              _c("el-option", {
                                attrs: { label: "PUT", value: "PUT" }
                              }),
                              _c("el-option", {
                                attrs: { label: "DELETE", value: "DELETE" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Header请求头", prop: "text" } },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "10px" } },
                            [_vm._v("Header请求头")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.addItem(_vm.config.http.headers)
                                }
                              }
                            },
                            [_vm._v(" + 添加")]
                          )
                        ],
                        1
                      ),
                      _vm._l(_vm.config.http.headers, function(header, index) {
                        return _c(
                          "div",
                          { key: header.name },
                          [
                            _vm._v(" - "),
                            _c("el-input", {
                              staticStyle: { width: "100px" },
                              attrs: { placeholder: "参数名", size: "small" },
                              model: {
                                value: header.name,
                                callback: function($$v) {
                                  _vm.$set(header, "name", $$v)
                                },
                                expression: "header.name"
                              }
                            }),
                            _c(
                              "el-radio-group",
                              {
                                staticStyle: { margin: "0 5px" },
                                attrs: { size: "small" },
                                model: {
                                  value: header.isField,
                                  callback: function($$v) {
                                    _vm.$set(header, "isField", $$v)
                                  },
                                  expression: "header.isField"
                                }
                              },
                              [
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: true } },
                                  [_vm._v("表单")]
                                ),
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: false } },
                                  [_vm._v("固定")]
                                )
                              ],
                              1
                            ),
                            header.isField
                              ? _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "180px" },
                                    attrs: {
                                      size: "small",
                                      placeholder: "请选择表单字段"
                                    },
                                    model: {
                                      value: header.value,
                                      callback: function($$v) {
                                        _vm.$set(header, "value", $$v)
                                      },
                                      expression: "header.value"
                                    }
                                  },
                                  _vm._l(_vm.forms, function(form) {
                                    return _c("el-option", {
                                      key: form.id,
                                      attrs: {
                                        label: form.title,
                                        value: form.title
                                      }
                                    })
                                  }),
                                  1
                                )
                              : _c("el-input", {
                                  staticStyle: { width: "180px" },
                                  attrs: {
                                    placeholder: "请设置字段值",
                                    size: "small"
                                  },
                                  model: {
                                    value: header.value,
                                    callback: function($$v) {
                                      _vm.$set(header, "value", $$v)
                                    },
                                    expression: "header.value"
                                  }
                                }),
                            _c("el-icon", {
                              staticClass: "el-icon-delete",
                              staticStyle: {
                                "margin-left": "5px",
                                color: "#c75450",
                                cursor: "pointer"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.delItem(
                                    _vm.config.http.headers,
                                    index
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      })
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Header请求参数", prop: "text" } },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "10px" } },
                            [_vm._v("Header请求参数 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-right": "20px" },
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.addItem(_vm.config.http.params)
                                }
                              }
                            },
                            [_vm._v(" + 添加")]
                          ),
                          _c("span", [_vm._v("参数类型 - ")]),
                          _c(
                            "el-radio-group",
                            {
                              staticStyle: { margin: "0 5px" },
                              attrs: { size: "mini" },
                              model: {
                                value: _vm.config.http.contentType,
                                callback: function($$v) {
                                  _vm.$set(_vm.config.http, "contentType", $$v)
                                },
                                expression: "config.http.contentType"
                              }
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: "JSON" } },
                                [_vm._v("json")]
                              ),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "FORM" } },
                                [_vm._v("form")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._l(_vm.config.http.params, function(param, index) {
                        return _c(
                          "div",
                          { key: param.name },
                          [
                            _vm._v(" - "),
                            _c("el-input", {
                              staticStyle: { width: "100px" },
                              attrs: { placeholder: "参数名", size: "small" },
                              model: {
                                value: param.name,
                                callback: function($$v) {
                                  _vm.$set(param, "name", $$v)
                                },
                                expression: "param.name"
                              }
                            }),
                            _c(
                              "el-radio-group",
                              {
                                staticStyle: { margin: "0 5px" },
                                attrs: { size: "small" },
                                model: {
                                  value: param.isField,
                                  callback: function($$v) {
                                    _vm.$set(param, "isField", $$v)
                                  },
                                  expression: "param.isField"
                                }
                              },
                              [
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: true } },
                                  [_vm._v("表单")]
                                ),
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: false } },
                                  [_vm._v("固定")]
                                )
                              ],
                              1
                            ),
                            param.isField
                              ? _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "180px" },
                                    attrs: {
                                      size: "small",
                                      placeholder: "请选择表单字段"
                                    },
                                    model: {
                                      value: param.value,
                                      callback: function($$v) {
                                        _vm.$set(param, "value", $$v)
                                      },
                                      expression: "param.value"
                                    }
                                  },
                                  _vm._l(_vm.forms, function(form) {
                                    return _c("el-option", {
                                      key: form.id,
                                      attrs: {
                                        label: form.title,
                                        value: form.title
                                      }
                                    })
                                  }),
                                  1
                                )
                              : _c("el-input", {
                                  staticStyle: { width: "180px" },
                                  attrs: {
                                    placeholder: "请设置字段值",
                                    size: "small"
                                  },
                                  model: {
                                    value: param.value,
                                    callback: function($$v) {
                                      _vm.$set(param, "value", $$v)
                                    },
                                    expression: "param.value"
                                  }
                                }),
                            _c("el-icon", {
                              staticClass: "el-icon-delete",
                              staticStyle: {
                                "margin-left": "5px",
                                color: "#c75450",
                                cursor: "pointer"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.delItem(
                                    _vm.config.http.params,
                                    index
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      }),
                      _c("div")
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "请求结果处理", prop: "text" } },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "label" }, slot: "label" },
                        [
                          _c("span", [_vm._v("请求结果处理")]),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "20px" } },
                            [_vm._v("自定义脚本: ")]
                          ),
                          _c("el-switch", {
                            model: {
                              value: _vm.config.http.handlerByScript,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.config.http,
                                  "handlerByScript",
                                  $$v
                                )
                              },
                              expression: "config.http.handlerByScript"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.config.http.handlerByScript
                        ? _c("span", { staticClass: "item-desc" }, [
                            _vm._v(
                              " 👉 返回值为 ture 则流程通过，为 false 则流程将被驳回 "
                            ),
                            _c("div", [
                              _vm._v("支持函数 "),
                              _c(
                                "span",
                                { staticStyle: { color: "dodgerblue" } },
                                [
                                  _vm._v("setFormByName( "),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#939494" } },
                                    [_vm._v("'表单字段名', '表单字段值'")]
                                  ),
                                  _vm._v(" )")
                                ]
                              ),
                              _vm._v(" 可改表单数据 ")
                            ])
                          ])
                        : _c("span", { staticClass: "item-desc" }, [
                            _vm._v("👉 无论请求结果如何，均通过")
                          ]),
                      _vm.config.http.handlerByScript
                        ? _c("div", [
                            _c(
                              "div",
                              [
                                _c("span", [_vm._v("请求成功😀：")]),
                                _c("el-input", {
                                  attrs: { type: "textarea", rows: 3 },
                                  model: {
                                    value: _vm.config.http.success,
                                    callback: function($$v) {
                                      _vm.$set(_vm.config.http, "success", $$v)
                                    },
                                    expression: "config.http.success"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("span", [_vm._v("请求失败😥：")]),
                                _c("el-input", {
                                  attrs: { type: "textarea", rows: 3 },
                                  model: {
                                    value: _vm.config.http.fail,
                                    callback: function($$v) {
                                      _vm.$set(_vm.config.http, "fail", $$v)
                                    },
                                    expression: "config.http.fail"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            : _vm.config.type === "EMAIL"
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "邮件主题", prop: "text" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入邮件主题",
                          size: "medium"
                        },
                        model: {
                          value: _vm.config.email.subject,
                          callback: function($$v) {
                            _vm.$set(_vm.config.email, "subject", $$v)
                          },
                          expression: "config.email.subject"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "收件方", prop: "text" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            size: "small",
                            filterable: "",
                            multiple: "",
                            "allow-create": "",
                            "default-first-option": "",
                            placeholder: "请输入收件人"
                          },
                          model: {
                            value: _vm.config.email.to,
                            callback: function($$v) {
                              _vm.$set(_vm.config.email, "to", $$v)
                            },
                            expression: "config.email.to"
                          }
                        },
                        _vm._l(_vm.config.email.to, function(item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "邮件正文", prop: "text" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 4,
                          placeholder:
                            "邮件内容，支持变量提取表单数据 ${表单字段名} "
                        },
                        model: {
                          value: _vm.config.email.content,
                          callback: function($$v) {
                            _vm.$set(_vm.config.email, "content", $$v)
                          },
                          expression: "config.email.content"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }