<template>
  <div>
    <el-form inline label-width="100px">
      <el-form-item label="调整优先级" prop="level">
        <el-popover
          placement="right"
          title="拖拽条件调整优先级顺序"
          width="250"
          trigger="click"
        >
          <draggable
            style="width: 100%; min-height: 35px"
            :list="prioritySortList"
            group="from"
            :options="sortOption"
          >
            <div
              :class="{
                'drag-no-choose': true,
                'drag-hover': cd.id === selectedNode.id,
              }"
              v-for="(cd, index) in prioritySortList"
              :key="index"
            >
              <div>{{ cd.name }}</div>
              <div>优先级 {{ index + 1 }}</div>
            </div>
          </draggable>
          <el-button icon="el-icon-sort" size="small" slot="reference"
            >第{{ nowNodeLeave + 1 }}级</el-button
          >
        </el-popover>
      </el-form-item>
      <el-form-item label="条件组关系:" label-width="150px">
        <span>或</span>
      </el-form-item>
    </el-form>
    <group-item />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import GroupItem from "./ConditionGroupItemConfig.vue";

export default {
  name: "ConditionNodeConfig",
  components: { draggable, GroupItem },
  props: {
    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    selectedNode() {
      return this.$store.state.process.selectedNode;
    },
    select() {
      return this.config.assignedUser || [];
    },
    nowNodeLeave() {
      return this.prioritySortList.indexOf(this.selectedNode);
    },
    //条件节点
    prioritySortList() {
      let node = this.$store.state.process.nodeMap.get(
        this.selectedNode.parentId
      );
      console.log(this.selectedNode.id, node);
      if (node) {
        return node.branchs || [];
      }
      return [];
    },
  },
  data() {
    return {
      sortOption: {
        animation: 300,
        chosenClass: "choose",
        scroll: true,
        sort: true,
      },
    };
  },
  methods: {
    closeSelect() {},
    selectUser() {
      this.showOrgSelect = true;
    },
    selected(select) {
      console.log(select);
      this.showOrgSelect = false;
      select.forEach((val) => this.select.push(val));
    },
    removeOrgItem(index) {
      this.select.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.choose {
  border-radius: 5px;
  margin-top: 2px;
  background: #f4f4f4;
  border: 1px dashed #1890ff !important;
}

.drag-hover {
  background: #79bbff;
  color: #1890ff;
}

.drag-no-choose {
  cursor: move;
  background: #f4f4f4;
  border-radius: 5px;
  position: relative;
  margin-top: 2px;
  padding: 5px 10px;
  border: 1px solid #bcbcbc;
  height: 20px;
  box-sizing: content-box;

  div:nth-child(1) {
    font-size: x-small;
    position: absolute;
    width: 160px;
    left: 10px;
    height: 20px;
    overflow: hidden;
  }

  div:nth-child(2) {
    position: absolute;
    right: 10px;
  }
}
</style>
