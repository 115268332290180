var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        "destroy-on-close": true,
        "close-on-click-modal": false,
        modal: false,
        visible: _vm.show,
        "before-close": _vm.close,
        width: "700px",
        center: ""
      },
      on: {
        "update:visible": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c("div", { staticClass: "picker" }, [
        _c("div", { staticStyle: { float: "left" } }, [
          _c("p", [_vm._v("选择")]),
          _c("div", { staticClass: "box" }, [
            _c(
              "div",
              [
                _vm.type !== "dept"
                  ? _c("el-input", {
                      attrs: {
                        placeholder: "搜索人员",
                        "prefix-icon": "el-icon-search",
                        size: "medium",
                        maxlength: 50,
                        clearable: ""
                      },
                      on: { input: _vm.searchUser },
                      model: {
                        value: _vm.search,
                        callback: function($$v) {
                          _vm.search = $$v
                        },
                        expression: "search"
                      }
                    })
                  : _vm._e(),
                _c(
                  "el-breadcrumb",
                  {
                    staticStyle: { "overflow-x": "hidden" },
                    attrs: { "separator-class": "el-icon-arrow-right" }
                  },
                  [
                    _c(
                      "el-breadcrumb-item",
                      { staticStyle: { padding: "5px 0" } },
                      [_vm._v("通讯录")]
                    ),
                    _vm._l(_vm.navNodes, function(node, index) {
                      return _c(
                        "el-breadcrumb-item",
                        {
                          key: index,
                          staticStyle: { color: "#38adff", padding: "5px 0" }
                        },
                        [_vm._v(" " + _vm._s(node.name) + " ")]
                      )
                    })
                  ],
                  2
                ),
                _c(
                  "el-checkbox",
                  {
                    attrs: { disabled: _vm.single },
                    on: { change: _vm.handleCheckAllChange },
                    model: {
                      value: _vm.checkAll,
                      callback: function($$v) {
                        _vm.checkAll = $$v
                      },
                      expression: "checkAll"
                    }
                  },
                  [_vm._v("全选")]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.search,
                        expression: "!search"
                      }
                    ],
                    staticStyle: {
                      "margin-left": "20px",
                      cursor: "pointer",
                      color: "#38adff"
                    },
                    on: {
                      click: function($event) {
                        return _vm.beforeNode()
                      }
                    }
                  },
                  [_vm._v("上一级")]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.search,
                        expression: "search"
                      }
                    ],
                    staticStyle: {
                      "margin-left": "20px",
                      cursor: "pointer",
                      color: "#38adff"
                    },
                    on: {
                      click: function($event) {
                        return _vm.backTop()
                      }
                    }
                  },
                  [_vm._v("返回最上一级")]
                )
              ],
              1
            ),
            _c("div", { staticStyle: { "margin-top": "8px", width: "100%" } }, [
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-top": "8px",
                    height: "calc(100% - 90px)"
                  }
                },
                _vm._l(_vm.search ? _vm.searchUsers : _vm.nodes, function(
                  node,
                  index
                ) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "line",
                      style:
                        node.type === "user" && _vm.type === "dept"
                          ? "display: none"
                          : "",
                      on: {
                        change: function($event) {
                          return _vm.selectChange(node)
                        }
                      }
                    },
                    [
                      _c("el-checkbox", {
                        attrs: { disabled: _vm.disableDept(node) },
                        model: {
                          value: node.selected,
                          callback: function($$v) {
                            _vm.$set(node, "selected", $$v)
                          },
                          expression: "node.selected"
                        }
                      }),
                      _c("span", { staticStyle: { "margin-left": "10px" } }, [
                        node.type === "dept"
                          ? _c("i", { staticClass: "el-icon-folder-opened" })
                          : node.avatar === undefined || node.avatar === ""
                          ? _c(
                              "div",
                              {
                                staticClass: "avt",
                                style: "background: " + _vm.getAvatarColor()
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(node.name.substring(0, 1)) + " "
                                )
                              ]
                            )
                          : _c("img", {
                              staticStyle: {
                                "border-radius": "50%",
                                display: "inline-block"
                              },
                              attrs: {
                                src: node.avatar,
                                width: "35",
                                height: "35"
                              }
                            }),
                        _c("span", { staticStyle: { "margin-left": "10px" } }, [
                          _vm._v(_vm._s(node.name))
                        ]),
                        node.type === "dept" && _vm.type === "user"
                          ? _c(
                              "span",
                              {
                                class: {
                                  "next-dept-disable": node.selected,
                                  "next-dept": !node.selected
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.nextNode(node)
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "el-icon-coin" }),
                                _vm._v("下级 ")
                              ]
                            )
                          : node.type === "dept"
                          ? _c(
                              "span",
                              {
                                staticClass: "next-dept",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.nextNode(node)
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "el-icon-coin" }),
                                _vm._v("下级 ")
                              ]
                            )
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                }),
                0
              )
            ])
          ])
        ]),
        _c("div", { staticStyle: { float: "right" } }, [
          _c("p", [_vm._v("已选")]),
          _c("div", { staticClass: "box" }, [
            _c(
              "div",
              {
                staticStyle: {
                  "overflow-x": "hidden",
                  "overflow-y": "auto",
                  height: "100%"
                }
              },
              _vm._l(_vm.select, function(node, index) {
                return _c("div", { key: index, staticClass: "line" }, [
                  _c("span", { staticStyle: { "margin-left": "10px" } }, [
                    node.type === "dept"
                      ? _c("i", { staticClass: "el-icon-folder-opened" })
                      : node.avatar === undefined || node.avatar === ""
                      ? _c(
                          "div",
                          {
                            staticClass: "avt",
                            style: "background: " + _vm.getAvatarColor()
                          },
                          [
                            _vm._v(
                              " " + _vm._s(node.name.substring(0, 1)) + " "
                            )
                          ]
                        )
                      : _c("img", {
                          attrs: { src: node.avatar, width: "35", height: "35" }
                        }),
                    _c("span", { staticStyle: { "margin-left": "10px" } }, [
                      _vm._v(_vm._s(node.name))
                    ]),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          float: "right",
                          color: "#a9a9a9",
                          cursor: "pointer"
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-close",
                          on: {
                            click: function($event) {
                              return _vm.noSelected(index)
                            }
                          }
                        })
                      ]
                    )
                  ])
                ])
              }),
              0
            )
          ])
        ])
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.close } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.selectOk }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }