import request from "@/utils/request";

// 创建工作流的表单定义
export function createForm(data) {
    return request({
      url: "/bpm/form/create",
      method: "post",
      data: data,
    });
  }
  
  // 获得工作流的表单定义
  export function getForm(id) {
    return request({
      url: "/bpm/form/get",
      method: "post",
      data: {
        id,
      },
    });
  }
  
  // 更新工作流的表单定义
  export function updateForm(data) {
    return request({
      url: "/bpm/form/update",
      method: "put",
      data: data,
    });
  }