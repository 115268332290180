<template>
  <el-dialog
    :title="title"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :modal="false"
    :visible.sync="show"
    :before-close="cancel"
    width="700px"
  >
    <!--    <div class="container">-->
    <!--      <p class="field">默认信息字段</p>-->
    <!--      <el-checkbox-group v-model="checkedList">-->
    <!--        <el-checkbox label="发起人部门" value="发起人部门"></el-checkbox>-->
    <!--      </el-checkbox-group>-->
    <!--    </div>-->
    <div
      class="container"
      v-for="(item, index) in showData"
      :key="item.title + index"
    >
      <p v-if="item.fieldList.length > 0">
        <span class="field">{{ item.title }}</span>
        <el-tooltip
          class="item"
          effect="light"
          :content="item.title"
          placement="top-start"
        >
          <i class="el-icon-question info"></i>
        </el-tooltip>
      </p>
      <el-checkbox-group v-model="item.checkedList">
        <el-checkbox
          v-for="(citem, index) in item.fieldList"
          :key="citem.id"
          :label="citem.sattributecode"
          :value="citem.sattributename"
          :checked="citem.selected"
          @change="handleChange(citem, index)"
        >
          {{ citem.sattributename }}
        </el-checkbox>
      </el-checkbox-group>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="Ok">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "ConditionGroupDialog",
  props: {
    //标题
    title: {
      default: "选择条件",
      type: String,
    },
    dialogData: {
      default: [],
      type: Array,
    },
  },
  computed: {
    show() {
      return this.$store.state.condition.showCondition;
    },
  },
  data() {
    return {
      checkedList: [], //被选择的数据
      showData: [],
    };
  },
  watch: {
    dialogData(val) {
      this.showData = val;
      this.handleChange();
    },
  },
  mounted() {},
  methods: {
    //确定
    Ok() {
      this.$store.commit("CHANGE_SHOW", false);
      console.log(this.checkedList);
      let result = this.checkedList.map((item) => ({
        id: item.sattributename,
        valueType: item.valueType,
        title: item.sattributename,
        list: item.radioList,
        selectType: item.selectType,
        valueKey: item.sattributecode,
      }));
      console.log(result);
      this.$store.commit("UPDATE_FORM_ITEM", result);
      this.$emit("addConditionData", result);
    },
    //取消
    cancel() {
      this.$store.commit("CHANGE_SHOW", false);
      this.checkedList = [];
    },
    handleChange(val, index) {
      this.checkedList = [];
      let i = -1;
      this.showData.forEach((row) => {
        row.checkedList.forEach((item) => {
          // console.log(item);
          // console.log(row.fieldList);
          i = row.fieldList.findIndex((fitem) => fitem.sattributecode == item);
          if (i >= 0) {
            this.checkedList.push(row.fieldList[i]);
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  padding: 0;
}
.container {
  color: black;
  margin-bottom: 20px;
  .field {
    font-weight: 600;
  }
  .info {
    position: relative;
    top: -8px;
    left: 4px;
    color: #cccccc;
    font-size: 14px;
  }
}
::v-deep .el-dialog__body {
  padding: 5px 30px 20px 30px;
  .el-checkbox {
    padding: 5px;
  }
}
</style>