var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "node" }, [
    _c(
      "div",
      {
        staticClass: "node-body",
        on: {
          click: function($event) {
            return _vm.$emit("selected")
          }
        }
      },
      [
        _vm.level > 1
          ? _c(
              "div",
              {
                staticClass: "node-body-left",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.$emit("leftMove")
                  }
                }
              },
              [_c("i", { staticClass: "el-icon-arrow-left" })]
            )
          : _vm._e(),
        _c("div", { staticClass: "node-body-main" }, [
          _c("div", { staticClass: "node-body-main-header" }, [
            _c("span", { staticClass: "title" }, [
              _c("i", { staticClass: "el-icon-s-operation" }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.config.name ? _vm.config.name : "并行任务" + _vm.level
                  ) +
                  " "
              )
            ]),
            _c(
              "span",
              { staticClass: "option" },
              [
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      effect: "dark",
                      content: "复制分支",
                      placement: "top"
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-copy-document",
                      on: {
                        click: function($event) {
                          return _vm.$emit("copy")
                        }
                      }
                    })
                  ]
                ),
                _c("i", {
                  staticClass: "el-icon-close",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.$emit("delNode")
                    }
                  }
                })
              ],
              1
            )
          ]),
          _vm._m(0)
        ]),
        _vm.level < _vm.size
          ? _c(
              "div",
              {
                staticClass: "node-body-right",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.$emit("rightMove")
                  }
                }
              },
              [_c("i", { staticClass: "el-icon-arrow-right" })]
            )
          : _vm._e()
      ]
    ),
    _c("div", { staticClass: "node-footer" }, [
      _c(
        "div",
        { staticClass: "btn" },
        [
          _c("insert-button", {
            on: {
              insertNode: function(type) {
                return _vm.$emit("insertNode", type)
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "node-body-main-content" }, [
      _c("span", [_vm._v("并行任务（同时进行）")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }