<template>
  <el-main class="scroll">
    <div class="scale">
      <el-button
        icon="el-icon-plus"
        size="small"
        @click="scale += 10"
        :disabled="scale >= 150"
        circle
      ></el-button>
      <span>{{ scale }}%</span>
      <el-button
        icon="el-icon-minus"
        size="small"
        @click="scale -= 10"
        :disabled="scale <= 40"
        circle
      ></el-button>
      <!-- <el-button @click="validate">校验流程</el-button>-->
    </div>

    <div class="design" :style="'transform: scale(' + scale / 100 + ');'">
      <process-tree ref="process-tree" @selectedNode="nodeSelected" />
    </div>

    <el-drawer
      :title="selectedNode.name"
      :visible.sync="showConfig"
      :size="selectedNode.type === 'CONDITION' ? '650px' : '500px'"
      direction="rtl"
      :modal="false"
      destroy-on-close
      :closed="closed"
    >
      <div slot="title">
        <el-input
          v-model="selectedNode.name"
          size="medium"
          v-show="showInput"
          style="width: 300px"
          @blur="showInput = false"
        ></el-input>
        <el-link
          v-show="!showInput"
          @click="showInput = true"
          style="font-size: medium"
        >
          <i
            class="el-icon-edit"
            style="margin-right: 10px; color: #1890ff"
          ></i>
          {{ selectedNode.name }}
        </el-link>
      </div>

      <div class="node-config-content">
        <NodeConfig />
      </div>

      <div class="group-footer" v-if="selectedNode.type === 'CONDITION'">
        <div>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-plus"
            @click="addConditionGroup"
          >
            添加条件组
          </el-button>
        </div>
        <div>
          <el-button size="small" @click="cancelDrawer"> 取消 </el-button>
          <el-button size="small" type="primary" @click="confirmData">
            确定
          </el-button>
        </div>
      </div>
    </el-drawer>
  </el-main>
</template>

<script>
import ProcessTree from "./ProcessTree.vue";
import NodeConfig from "./comon/process/config/NodeConfig";
import { ValueType } from "@/components/process/comon/ComponetsConfigExport";

export default {
  name: "ProcessDesign",
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  components: { ProcessTree, NodeConfig },
  data() {
    return {
      scale: 100,
      selected: {},
      showInput: false,
      showConfig: false,
    };
  },
  created() {
    this.$store.commit("CHANGE_Readonly", this.readonly);
  },
  computed: {
    selectedNode() {
      return this.$store.state.process.selectedNode;
    },
  },
  mounted() {},
  methods: {
    validate() {
      this.$refs["process-tree"].validateProcess();
    },
    nodeSelected(node) {
      console.log("配置节点", node);
      if (this.readonly) return;
      this.showHideConfig(true);
    },
    showHideConfig(bol) {
      this.showConfig = bol;
    },
    closed() {
      console.log(1);
    },
    addConditionGroup() {
      this.selectedNode.props.groups.push({
        groupType: "OR",
        conditions: [],
        cids: [],
      });
    },
    cancelDrawer() {
      this.showHideConfig(false);
    },
    confirmData() {
      if (this.selectedNode && this.selectedNode.props) {
        const groups = this.selectedNode.props.groups || [];
        if (groups.length > 0) {
          let checkResult = true;
          checkResult = groups.every(
            (row) =>
              row.conditions.length == 0 ||
              (row.conditions.length > 0 &&
                row.conditions.every(
                  (item) =>
                    item.compare &&
                    (this.checkNumberEmtpy(item) || this.checkOtherEmtpy(item))
                ))
          );

          // console.log(checkResult);
          if (!checkResult) {
            this.$message({
              message: "请将添加的条件填写完整!",
              type: "warning",
            });
            return false;
          }
        }
      }

      this.showHideConfig(false);
      return true;
    },
    checkNumberEmtpy(item) {
      /* 为 number 类型  */
      // compare为介于时，两个输入框必输
      // compare为其它类型时，单个输入框必输
      console.log(
        item.valueType === ValueType.number &&
          ((item.compare.indexOf("B") >= 0 && item.value[0] && item.value[1]) ||
            (item.compare.indexOf("B") < 0 && item.value[0]))
      );
      return (
        item.valueType === ValueType.number &&
        ((item.compare.indexOf("B") >= 0 && item.value[0] && item.value[1]) ||
          (item.compare.indexOf("B") < 0 && item.value[0]))
      );
    },
    checkOtherEmtpy(item) {
      /* 不为 number 类型, 单个输入框必输 */
      console.log(item.valueType !== ValueType.number && item.value[0]);
      return item.valueType !== ValueType.number && item.value[0];
    },
  },
  watch: {
    selectedNode: {
      deep: true,
      handler(node) {
        // console.log(node, 89898989);
        // console.log("更新");
        console.log(this.$store.state.process.design.process, "process");
        this.$refs["process-tree"].nodeDomUpdate(node);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 79vh;
  overflow-x: auto;
  overflow-y: auto;
}
.design {
  margin-top: 100px;
  display: flex;
  transform-origin: 50% 0px 0px;
}

.scale {
  z-index: 999;
  position: absolute;
  top: 40px;
  right: 40px;

  span {
    margin: 0 10px;
    font-size: 15px;
    color: #7a7a7a;
    width: 50px;
  }
}

.node-config-content {
  padding: 0 20px 20px;
}

::v-deep .el-drawer__body {
  overflow-y: auto;
}

.group-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 20px 20px;
}
</style>
