var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { node: true, "node-error-state": _vm.showError } },
    [
      _c("div", { class: { "node-body": true, error: _vm.showError } }, [
        _vm.level > 1
          ? _c(
              "div",
              {
                staticClass: "node-body-left",
                on: {
                  click: function($event) {
                    return _vm.$emit("leftMove")
                  }
                }
              },
              [_c("i", { staticClass: "el-icon-arrow-left" })]
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "node-body-main",
            on: {
              click: function($event) {
                return _vm.$emit("selected")
              }
            }
          },
          [
            _c("div", { staticClass: "node-body-main-header" }, [
              _c("span", { staticClass: "title" }, [
                _vm._v(
                  _vm._s(_vm.config.name ? _vm.config.name : "条件" + _vm.level)
                )
              ]),
              _c("span", { staticClass: "level" }, [
                _vm._v("优先级" + _vm._s(_vm.level))
              ]),
              _c(
                "span",
                { staticClass: "option" },
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "复制条件",
                        placement: "top"
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-copy-document",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.$emit("copy")
                          }
                        }
                      })
                    ]
                  ),
                  _c("i", {
                    staticClass: "el-icon-close",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.$emit("delNode")
                      }
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "node-body-main-content" },
              [
                _vm.content.length
                  ? _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "bottom",
                          width: "300",
                          trigger: "hover"
                        }
                      },
                      [
                        _vm._l(_vm.content, function(row, i) {
                          return _c(
                            "div",
                            {
                              key: "content" + i,
                              staticClass: "popover-f-lay"
                            },
                            [
                              _vm._l(row.conditions, function(item, index) {
                                return _c(
                                  "div",
                                  { key: "conditions" + index },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(index + 1) +
                                        ". " +
                                        _vm._s(item) +
                                        " "
                                    )
                                  ]
                                )
                              }),
                              i != _vm.content.length - 1
                                ? _c("div", { staticClass: "group-type-lay" }, [
                                    _vm._v(" " + _vm._s(row.groupType) + " ")
                                  ])
                                : _vm._e()
                            ],
                            2
                          )
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "content",
                            attrs: { slot: "reference" },
                            slot: "reference"
                          },
                          _vm._l(_vm.content, function(row, i) {
                            return _c(
                              "span",
                              { key: "content" + i },
                              _vm._l(row.conditions, function(item, index) {
                                return _c(
                                  "span",
                                  { key: "conditions" + index },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(index + 1) +
                                        ". " +
                                        _vm._s(item) +
                                        " "
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          }),
                          0
                        )
                      ],
                      2
                    )
                  : _c("span", { staticClass: "placeholder" }, [
                      _vm._v(_vm._s(_vm.placeholder))
                    ])
              ],
              1
            )
          ]
        ),
        _vm.level < _vm.size
          ? _c(
              "div",
              {
                staticClass: "node-body-right",
                on: {
                  click: function($event) {
                    return _vm.$emit("rightMove")
                  }
                }
              },
              [_c("i", { staticClass: "el-icon-arrow-right" })]
            )
          : _vm._e(),
        _vm.showError
          ? _c(
              "div",
              { staticClass: "node-error" },
              [
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      effect: "dark",
                      content: _vm.errorInfo,
                      placement: "top-start"
                    }
                  },
                  [_c("i", { staticClass: "el-icon-warning-outline" })]
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _c("div", { staticClass: "node-footer" }, [
        _c(
          "div",
          { staticClass: "btn" },
          [
            _c("insert-button", {
              on: {
                insertNode: function(type) {
                  return _vm.$emit("insertNode", type)
                }
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }