<template>
  <div>
    <el-dialog v-bind="$attrs" :close-on-click-modal="false" :modal-append-to-body="false" v-on="$listeners"
      @open="onOpen" @close="onClose">
      <el-row :gutter="0">
        <el-form ref="elForm" :model="formData" :rules="rules" size="small" label-width="100px" :label-position="'top'">
          <el-col :span="24">
            <el-form-item label="选项名" prop="label">
              <el-input v-model="formData.label" placeholder="请输入选项名" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="选项值" prop="value">
              <el-input v-model="formData.value" placeholder="请输入选项值" clearable>
                <el-select slot="append" v-model="dataType" :style="{ width: '100px' }">
                  <el-option v-for="(item, index) in dataTypeOptions" :key="index" :label="item.label"
                    :value="item.value" :disabled="item.disabled" />
                </el-select>
              </el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div slot="footer" style="display: flex;justify-content: flex-end;">
        <div @click="close" class="cancelBtn">取消</div>
        <div @click="handelConfirm" class="determineBtn">确定</div>
        
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { isNumberStr, getTreeNodeId, saveTreeNodeId } from '@/utils'

const id = getTreeNodeId()

export default {
  components: {},
  inheritAttrs: false,
  props: [],
  data() {
    return {
      id,
      formData: {
        label: undefined,
        value: undefined
      },
      rules: {
        label: [
          {
            required: true,
            message: '请输入选项名',
            trigger: 'blur'
          }
        ],
        value: [
          {
            required: true,
            message: '请输入选项值',
            trigger: 'blur'
          }
        ]
      },
      dataType: 'string',
      dataTypeOptions: [
        {
          label: '字符串',
          value: 'string'
        },
        {
          label: '数字',
          value: 'number'
        }
      ]
    }
  },
  computed: {},
  watch: {
    // eslint-disable-next-line func-names
    'formData.value': function (val) {
      this.dataType = isNumberStr(val) ? 'number' : 'string'
    },
    id(val) {
      saveTreeNodeId(val)
    }
  },
  created() { },
  mounted() { },
  methods: {
    onOpen() {
      this.formData = {
        label: undefined,
        value: undefined
      }
    },
    onClose() { },
    close() {
      this.$emit('update:visible', false)
    },
    handelConfirm() {
      this.$refs.elForm.validate(valid => {
        if (!valid) return
        if (this.dataType === 'number') {
          this.formData.value = parseFloat(this.formData.value)
        }
        this.formData.id = this.id++
        this.$emit('commit', this.formData)
        this.close()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.cancelBtn {
  height: 28px;
  width: 68px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
  background-color: #f3f4f6;
  color: #848484;
  margin-right: 8px;

}

.determineBtn {
  height: 28px;
  width: 68px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
  background-color: #5669ec;
  color: #fff;

}
</style>