var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "left-board" },
        [
          _vm._m(0),
          _c("el-scrollbar", { staticClass: "left-scrollbar" }, [
            _c(
              "div",
              { staticClass: "components-list" },
              _vm._l(_vm.leftComponents, function(item, listIndex) {
                return _c(
                  "div",
                  { key: listIndex },
                  [
                    _c("div", { staticClass: "components-title" }, [
                      _vm._v(" " + _vm._s(item.title) + " ")
                    ]),
                    _c(
                      "draggable",
                      {
                        staticClass: "components-draggable",
                        attrs: {
                          list: item.list,
                          group: {
                            name: "componentsGroup",
                            pull: "clone",
                            put: false
                          },
                          clone: _vm.cloneComponent,
                          draggable: ".components-item",
                          sort: false,
                          move: _vm.onMove
                        },
                        on: { end: _vm.onEnd, start: _vm.onStart }
                      },
                      _vm._l(item.list, function(element, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "components-item",
                            on: {
                              click: function($event) {
                                return _vm.addComponent(element)
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "components-body" }, [
                              _vm._v(
                                " " + _vm._s(element.__config__.label) + " "
                              )
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  ],
                  1
                )
              }),
              0
            )
          ])
        ],
        1
      ),
      _c("div", { staticClass: "center-board" }, [
        _c("div", { staticClass: "action-bar" }),
        _c(
          "div",
          { staticClass: "center-scrollbar" },
          [
            _c(
              "el-row",
              { staticClass: "center-board-row" },
              [
                _c(
                  "el-form",
                  {
                    attrs: {
                      size: "small",
                      "label-position": "top",
                      disabled: _vm.formConf.disabled,
                      "label-width": _vm.formConf.labelWidth + "px"
                    }
                  },
                  [
                    _c(
                      "draggable",
                      {
                        staticClass: "drawing-board",
                        style: _vm.drawingList.length > 0 ? "" : "height: 100%",
                        attrs: {
                          list: _vm.drawingList,
                          animation: 340,
                          group: "componentsGroup"
                        },
                        on: { add: _vm.handleDragChange }
                      },
                      _vm._l(_vm.drawingList, function(item, index) {
                        return _c("draggable-item", {
                          key: item.renderKey,
                          attrs: {
                            "drawing-list": _vm.drawingList,
                            "current-item": item,
                            index: index,
                            "active-id": _vm.activeId,
                            "form-conf": _vm.formConf
                          },
                          on: {
                            activeItem: _vm.activeFormItem,
                            copyItem: _vm.drawingItemCopy,
                            deleteItem: _vm.drawingItemDelete
                          }
                        })
                      }),
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.drawingList.length,
                            expression: "!drawingList.length"
                          }
                        ],
                        staticClass: "empty-info"
                      },
                      [_vm._v(" 从左侧拖入或点选组件进行表单设计 ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("right-panel", {
        ref: "RightPanel",
        attrs: {
          "active-data": _vm.activeData,
          "form-conf": _vm.formConf,
          formData: _vm.drawingList,
          "show-field": !!_vm.drawingList.length,
          bpmnModeler: _vm.bpmnModeler,
          checkList: _vm.checkList
        },
        on: {
          "tag-change": _vm.tagChange,
          "fetch-data": _vm.fetchData,
          switchRadioChange: _vm.switchRadioChange,
          changeXml: _vm.changeXml
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo-wrapper" }, [
      _c("div", { staticClass: "logo" }, [_vm._v("流程表单")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }