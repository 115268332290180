<template>
  <el-dialog
    :title="title"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :modal="false"
    :visible.sync="show"
    :before-close="close"
    width="700px"
    center
  >
    <div class="picker">
      <div style="float: left">
        <p>选择</p>
        <div class="box">
          <div>
            <el-input
              v-if="type !== 'dept'"
              placeholder="搜索人员"
              prefix-icon="el-icon-search"
              size="medium"
              v-model="search"
              :maxlength="50"
              @input="searchUser"
              clearable
            >
            </el-input>
            <el-breadcrumb
              separator-class="el-icon-arrow-right"
              style="overflow-x: hidden"
            >
              <el-breadcrumb-item style="padding: 5px 0"
                >通讯录</el-breadcrumb-item
              >
              <el-breadcrumb-item
                style="color: #38adff; padding: 5px 0"
                v-for="(node, index) in navNodes"
                :key="index"
              >
                {{ node.name }}
              </el-breadcrumb-item>
            </el-breadcrumb>
            <el-checkbox
              v-model="checkAll"
              @change="handleCheckAllChange"
              :disabled="single"
              >全选</el-checkbox
            >
            <span
              v-show="!search"
              style="margin-left: 20px; cursor: pointer; color: #38adff"
              @click="beforeNode()"
              >上一级</span
            >
            <span
              v-show="search"
              style="margin-left: 20px; cursor: pointer; color: #38adff"
              @click="backTop()"
              >返回最上一级</span
            >
          </div>
          <div style="margin-top: 8px; width: 100%">
            <div style="margin-top: 8px; height: calc(100% - 90px)">
              <div
                v-for="(node, index) in search ? searchUsers : nodes"
                :key="index"
                class="line"
                :style="
                  node.type === 'user' && type === 'dept' ? 'display: none' : ''
                "
                @change="selectChange(node)"
              >
                <el-checkbox
                  v-model="node.selected"
                  :disabled="disableDept(node)"
                ></el-checkbox>
                <span style="margin-left: 10px">
                  <i
                    class="el-icon-folder-opened"
                    v-if="node.type === 'dept'"
                  ></i>
                  <div
                    class="avt"
                    :style="'background: ' + getAvatarColor()"
                    v-else-if="node.avatar === undefined || node.avatar === ''"
                  >
                    {{ node.name.substring(0, 1) }}
                  </div>
                  <img
                    :src="node.avatar"
                    style="border-radius: 50%; display: inline-block"
                    width="35"
                    height="35"
                    v-else
                  />
                  <span style="margin-left: 10px">{{ node.name }}</span>
                  <span
                    :class="{
                      'next-dept-disable': node.selected,
                      'next-dept': !node.selected,
                    }"
                    v-if="node.type === 'dept' && type === 'user'"
                    @click.stop="nextNode(node)"
                  >
                    <i class="el-icon-coin"></i>下级
                  </span>
                  <span
                    @click.stop="nextNode(node)"
                    v-else-if="node.type === 'dept'"
                    class="next-dept"
                  >
                    <i class="el-icon-coin"></i>下级
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="float: right">
        <p>已选</p>
        <div class="box">
          <div style="overflow-x: hidden; overflow-y: auto; height: 100%">
            <div v-for="(node, index) in select" :key="index" class="line">
              <span style="margin-left: 10px">
                <i
                  class="el-icon-folder-opened"
                  v-if="node.type === 'dept'"
                ></i>
                <div
                  class="avt"
                  :style="'background: ' + getAvatarColor()"
                  v-else-if="node.avatar === undefined || node.avatar === ''"
                >
                  {{ node.name.substring(0, 1) }}
                </div>
                <img :src="node.avatar" width="35" height="35" v-else />
                <span style="margin-left: 10px">{{ node.name }}</span>
                <span style="float: right; color: #a9a9a9; cursor: pointer">
                  <i class="el-icon-close" @click="noSelected(index)"></i>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="selectOk" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
// import {dynamicListData} from '@/api/management'
// import {getStructure,getUserList} from '@/api/process/process'
export default {
  name: "organizationPicker",
  props: {
    //是否展示
    show: {
      default: false,
      type: Boolean,
    },
    //标题
    title: {
      default: "请选择",
      type: String,
    },
    //选择哪种类型  user=人和部门  dept=只能选部门
    type: {
      default: "user",
      type: String,
    },
    //是否单选
    single: {
      default: false,
      type: Boolean,
    },
    //已经选中的
    selected: {
      default: () => {
        return [];
      },
      type: Array,
    },
    onlyUser: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      // showSelect:this.show,
      checkAll: false,
      nowDeptId: null,
      isIndeterminate: false,
      navNodes: [],
      navNodePointer: null,
      searchUsers: [],
      nodes: [],
      select: [],
      search: "",
      dept: [],
      avatarColor: [
        "#1e90ff",
        "#ff4500",
        "#ffc10a",
        "#d2b100",
        "#76c376",
        "#00ced1",
        "#c71585",
        "rgba(255, 69, 0, 0.68)",
        "rgb(255, 120, 0)",
        "hsl(181, 100%, 37%)",
      ],
      OrgList: [],
      flag: true,
      query: {
        // entityid: 2,
        idepartmentid: null,
        currentPage: 1,
        pageSize: 20,
        // quickSearch:'',
      },
      //父id
      pid: "",
    };
  },
  mounted() {},
  computed: {
    // showSelect:{
    //   get(){
    //     return this.show
    //   },
    //   set(val){
    //
    //   }
    // }
  },
  methods: {
    disableDept(node) {
      if (this.type === "user") {
        if (node.type == "dept") {
          return true;
        } else {
          return false;
        }
      } else {
        if (node.type === "dept") {
          return false;
        } else {
          return true;
        }
      }
      /*
      if (this.type === 'dept'){
        return this.onlyUser && 'dept' === node.type
      }else{
        if (node.type === 'dept'){
          return false
        }
        // console.log('@',node)
      }
       */
    },
    getOrgList() {
      getStructure()
        .then((res) => {
          const result = res.data;
          this.OrgList = result;
          this.dept = result;
          let arr = result.map((item) => {
            item.avatar = "";
            item.type = "dept";
            item.id = item.iid;
            item.select = false;
            item.name = item.sname;
            return item;
          });
          this.nodes = arr;
        })
        .catch((err) => this.$message.error("请稍后再试"));
    },
    searchUser() {
      let userName = this.search.trim() ? this.search.trim() : this.search;
      if (!userName) {
        this.nodes = this.OrgList;
        this.searchUsers = [];
        return;
      }
      this.searchUsers = [];
      const data = {
        entityid: 2,
        // condition: JSON.stringify(this.condition),
        currentPage: 1,
        pageSize: 20,
        quickSearch: userName,
      };
      dynamicListData(data).then((res) => {
        const result = res.data.resultList;
        let arr = result.map((item) => {
          item.avatar = "";
          item.type = "user";
          item.id = item.iid;
          item.select = false;
          item.name = item.sname;
          let index = this.select.find((value) => {
            return item.id == value.id;
          });
          if (index) {
            item.selected = true;
          }
          return item;
        });
        this.searchUsers = arr;
        this.nodes = arr;
        this.dept = arr;
        this.navNodes = [];
        this.checkAll();
      });
    },
    selectToLeft() {
      let nodes = this.search.trim() === "" ? this.nodes : this.searchUsers;
      nodes.forEach((node) => {
        for (let i = 0; i < this.select.length; i++) {
          if (this.select[i].id === node.id) {
            node.selected = true;
            break;
          } else {
            node.selected = false;
          }
        }
      });
    },
    selectChange(node) {
      if (this.single) {
        if (node.selected) {
          if (this.select.length === 0) {
            this.select.push(node);
          } else {
            this.$message.warning("只能选择一人");
            node.selected = false;
          }
        } else {
          let index = this.select.findIndex((item) => {
            return node.id == item.id;
          });
          if (index === -1) return;
          this.select.splice(index, 1);
        }
      } else {
        if (node.selected) {
          this.select.push(node);
        } else {
          let index = this.select.findIndex((item) => {
            return node.id == item.id;
          });
          this.select.splice(index, 1);
        }
        this.isCheckAll();
      }
    },
    noSelected(index) {
      let nodes = this.nodes;
      for (let f = 0; f < 2; f++) {
        for (let i = 0; i < nodes.length; i++) {
          if (nodes[i].id === this.select[index].id) {
            nodes[i].selected = false;
            this.checkAll = false;
            break;
          }
        }
        nodes = this.searchUsers;
      }
      this.select.splice(index, 1);
    },
    handleCheckAllChange() {
      // console.log(this.nodes)
      this.nodes.forEach((node) => {
        if (this.checkAll) {
          if (!node.selected && !this.disableDept(node)) {
            node.selected = true;
            this.select.push(node);
          }
        } else {
          node.selected = false;
          this.select.forEach((item, index) => {
            if (item.id == node.id) {
              this.select.splice(index, 1);
            }
          });
          // for (let i = 0; i < this.select.length; i++) {
          //   if (this.select[i].id === node.id) {
          //     this.select.splice(i, 1);
          //     break;
          //   }
          // }
        }
      });
    },
    getAvatarColor() {
      Math.floor(Math.random() * 10);
      return this.avatarColor[0];
    },
    nextNode(node) {
      if (this.type === "user") {
        if (node.selected) return;
        if (node.children) {
          let result = node.children;
          let arr = result.map((item) => {
            item.avatar = "";
            item.type = "dept";
            item.id = item.iid;
            item.select = false;
            item.name = item.sname;
            item.selected = false;
            return item;
          });
          arr.map((item) => {
            let index = this.select.find((value) => {
              return item.id === value.id && item.name == value.name;
            });
            if (index) {
              item.selected = true;
            }
          });
          this.nodes = arr;
          this.query.idepartmentid = node.id;
          this.navNodes.push(node);
          this.dept = arr;
          this.isCheckAll();
          getUserList(this.query)
            .then((res) => {
              const result = res.data.resultList;
              let arr = result.map((item) => {
                item.avatar = "";
                item.type = "user";
                item.id = item.iid;
                item.select = false;
                item.name = item.sname;
                item.selected = false;
                return item;
              });
              let newArr = arr.map((item) => {
                let index = this.select.find((value) => {
                  return item.id == value.id;
                });
                if (index) {
                  item.selected = true;
                }
                return item;
              });
              this.nodes = [...this.nodes, ...newArr];
            })
            .catch((err) => this.$message.error("请稍后再试"));
        } else {
          this.query.idepartmentid = node.id;
          getUserList(this.query)
            .then((res) => {
              const result = res.data.resultList;
              if (res.data.resultList.length > 0) {
                let arr = result.map((item) => {
                  item.avatar = "";
                  item.type = "user";
                  item.id = item.iid;
                  item.select = false;
                  item.name = item.sname;
                  item.pid = item.idepartmentid;
                  item.selected = false;
                  return item;
                });
                let newArr = arr.map((item) => {
                  let index = this.select.find((value) => {
                    return item.id == value.id;
                  });
                  if (index) {
                    item.selected = true;
                  }
                  return item;
                });
                this.nodes = newArr;
                this.navNodes.push(node);
                this.dept = arr;
                this.isCheckAll();
              } else {
                this.$message.warning("已经是最后一级");
              }
            })
            .catch((err) => this.$message.error("请稍后再试"));
        }
      } else {
        if (node.children) {
          let result = node.children;
          this.dept = node.children;
          let arr = result.map((item) => {
            item.avatar = "";
            item.type = "dept";
            item.id = item.iid;
            item.select = false;
            item.name = item.sname;
            item.selected = false;
            return item;
          });
          arr.map((item) => {
            let index = this.select.find((value) => {
              return item.id === value.id && item.name == value.name;
            });
            if (index) {
              item.selected = true;
            }
          });
          this.nodes = arr;
          this.navNodes.push(node);
          this.isCheckAll();
        } else {
          this.$message.warning("已经是最后一级");
        }
      }
    },
    beforeNode() {
      //为部门时 获取的数据
      // console.log(this.nodes)
      if (this.nodes.length > 0) {
        if (this.nodes[0].type !== "user") {
          this.navNodes.splice(this.navNodes.length - 1, 1);
          let pid = this.nodes[0].pid ? this.nodes[0].pid : this.pid;
          let data = this.deepFun(this.OrgList, pid);
        } else {
          this.navNodes.splice(this.navNodes.length - 1, 1);
          let pid = this.nodes[0].pid ? this.nodes[0].pid : this.pid;
          let data = this.deepFun(this.OrgList, pid);
        }
      } else {
        let pid = this.pid;
        let data = this.deepFun(this.OrgList, pid);
      }
      this.isCheckAll();
      // console.log(this.dept)
    },
    //部门数据
    deepFun(data, pid) {
      if (pid == "" || pid == 0) {
        this.$message.warning("已经是最上一级");
        return;
      }
      if (data) {
        let arr = data.map((item) => {
          // console.log('@',item)
          if (item.iid == pid) {
            this.query.idepartmentid = data[0].pid;
            if (this.query.idepartmentid != 0) {
              getUserList(this.query)
                .then((res) => {
                  const result = res.data.resultList;
                  if (res.data.resultList.length > 0) {
                    let arr = result.map((item) => {
                      item.avatar = "";
                      item.type = "user";
                      item.id = item.iid;
                      item.select = false;
                      item.name = item.sname;
                      item.pid = item.idepartmentid;
                      return item;
                    });
                    arr.map((item) => {
                      let index = this.select.find((value) => {
                        return item.id === value.id && item.name == value.name;
                      });
                      if (index) {
                        item.selected = true;
                      }
                    });
                    this.nodes = [...data, ...arr];
                  } else {
                    this.nodes = data;
                    this.dept = data;
                  }
                })
                .catch((err) => this.$message.error("请稍后再试"));
            } else {
              let Arr = data.map((val) => {
                let index = this.select.find((item) => {
                  return val.id == item.id;
                });
                if (index) {
                  val.selected = true;
                } else {
                  val.selected = false;
                }
                return val;
              });
              this.nodes = data;
              this.dept = data;
            }
            this.dept = data;
            // let Arr = data.map(val =>{
            //   let index = this.select.find(item =>{
            //    return  val.id == item.id
            //   })
            //   if(index){
            //     val.selected = true
            //   }
            //   return val
            // })
            // this.nodes = Arr
            return data;
          } else {
            this.deepFun(item.children, pid);
          }
        });
        return arr;
      }
    },
    //判断全选
    isCheckAll() {
      // console.log(this.dept)
      // console.log(this.select)
      // console.log(JSON.stringify(this.dept) === JSON.stringify(this.select))
      // this.checkAll = JSON.stringify(this.dept) === JSON.stringify(this.select)
      // console.log(this.isInclude(this.select,this.dept))
      this.checkAll = this.isInclude(this.select, this.dept);
    },
    //数组包含  arr1 里面是否包含 arr2
    isInclude(arr1, arr2) {
      let arr = [];
      arr2.forEach((item) => {
        let flag = arr1.find((val) => {
          return val.id == item.id;
        });
        if (flag) {
          arr.push(flag);
        }
      });
      // console.log(arr1,arr2)
      return arr2.length === arr.length && arr.length != 0;
    },
    backTop() {
      this.nodes = this.OrgList;
      this.dept = this.OrgList;
      this.search = "";
      this.isCheckAll();
    },
    recover() {
      this.select = [];
      this.search = "";
      this.nodes.forEach((nd) => (nd.selected = false));
    },
    selectOk() {
      console.log(this.select);
      this.$emit("selectOver", Object.assign([], this.select));
      this.recover();
    },
    close() {
      // this.showSelect = false
      this.$emit("close", this.show);
      this.recover();
    },
    init() {
      this.checkAll = false;
      this.nowDeptId = null;
      this.navNodes = [];
      this.navNodePointer = null;
      this.select = Object.assign([], this.selected);
      // console.log('select', this.select)
      this.selectToLeft();
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.init();
        // this.getOrgList()
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  z-index: 99999;
  border-radius: 13px;
  overflow: hidden;

  .el-dialog__header {
    background: #f7f7f7;
  }

  .el-dialog__body {
    padding-top: 0;
  }

  .el-dialog__footer {
    // margin-top: 450px;
  }
}

.line {
  width: 258px;
  height: 35px;
  line-height: 35px;

  &:hover {
    background: #e9e9ea;
  }

  .avt {
    width: 33px;
    height: 33px;
    line-height: 33px;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    border-radius: 50%;
    color: #ffffff;
  }

  i:first-child {
    font-size: large;
  }

  .next-dept {
    cursor: pointer;
    float: right;
    color: #38adff;
  }

  .next-dept-disable {
    //pointer-events: none;
    cursor: not-allowed;
    float: right;
    color: #cccccd;
  }
}

.picker {
  p {
    font-size: larger;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  ::v-deep .box {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    height: 400px;
    width: 290px;
    border-radius: 5px;
    border: 1px solid #d4d4d5;
    background: #f7f7f7;

    .el-breadcrumb {
      margin: 10px 0;
    }
  }
}

::-webkit-scrollbar {
  float: right;
  width: 4px;
  height: 4px;
  background-color: #f8f8f8;
}

::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background-color: #e8e8e8;
}
</style>
