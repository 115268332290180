var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticStyle: { "margin-bottom": "20px" } },
      [
        _c("p", { staticClass: "item-desc" }, [_vm._v("延时方式")]),
        _c(
          "el-radio-group",
          {
            attrs: { size: "small" },
            model: {
              value: _vm.config.type,
              callback: function($$v) {
                _vm.$set(_vm.config, "type", $$v)
              },
              expression: "config.type"
            }
          },
          [
            _c("el-radio-button", { attrs: { label: "FIXED" } }, [
              _vm._v("固定时长")
            ]),
            _c("el-radio-button", { attrs: { label: "AUTO" } }, [
              _vm._v("自动计算")
            ])
          ],
          1
        )
      ],
      1
    ),
    _vm.config.type === "FIXED"
      ? _c(
          "div",
          [
            _c(
              "el-input",
              {
                staticStyle: { width: "180px" },
                attrs: {
                  placeholder: "时间单位",
                  size: "small",
                  type: "number"
                },
                model: {
                  value: _vm.config.time,
                  callback: function($$v) {
                    _vm.$set(_vm.config, "time", $$v)
                  },
                  expression: "config.time"
                }
              },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "75px" },
                    attrs: { slot: "append", placeholder: "请选择" },
                    slot: "append",
                    model: {
                      value: _vm.config.unit,
                      callback: function($$v) {
                        _vm.$set(_vm.config, "unit", $$v)
                      },
                      expression: "config.unit"
                    }
                  },
                  [
                    _c("el-option", { attrs: { label: "天", value: "D" } }),
                    _c("el-option", { attrs: { label: "小时", value: "H" } }),
                    _c("el-option", { attrs: { label: "分钟", value: "M" } })
                  ],
                  1
                )
              ],
              1
            ),
            _c("span", { staticClass: "item-desc" }, [_vm._v(" 后进入下一步")])
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "item-desc" },
          [
            _c("el-time-picker", {
              staticStyle: { width: "150px" },
              attrs: {
                "value-format": "HH:mm:ss",
                size: "small",
                placeholder: "任意时间点"
              },
              model: {
                value: _vm.config.dateTime,
                callback: function($$v) {
                  _vm.$set(_vm.config, "dateTime", $$v)
                },
                expression: "config.dateTime"
              }
            }),
            _c("span", { staticClass: "item-desc" }, [_vm._v(" 后进入下一步")])
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }