var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        node: true,
        root: _vm.isRoot || !_vm.show,
        "node-error-state": _vm.showError
      }
    },
    [
      _vm.show
        ? _c(
            "div",
            {
              class: { "node-body": true, error: _vm.showError },
              on: {
                click: function($event) {
                  return _vm.$emit("selected")
                }
              }
            },
            [
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "node-body-header",
                    style: { "background-color": _vm.headerBgc }
                  },
                  [
                    (_vm.headerIcon || "") !== ""
                      ? _c("i", {
                          class: _vm.headerIcon,
                          staticStyle: { "margin-right": "5px" }
                        })
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(_vm.title))]),
                    !_vm.isRoot && !_vm.readonly
                      ? _c("i", {
                          staticClass: "el-icon-close",
                          staticStyle: { float: "right" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.$emit("delNode")
                            }
                          }
                        })
                      : _vm._e()
                  ]
                ),
                _c("div", { staticClass: "node-body-content" }, [
                  _vm.leftIcon ? _c("i", { class: _vm.leftIcon }) : _vm._e(),
                  _c("span", [_vm._v(_vm._s(_vm.text))]),
                  !_vm.isRoot
                    ? _c("i", { staticClass: "el-icon-arrow-right" })
                    : _vm._e()
                ]),
                _vm.showError
                  ? _c(
                      "div",
                      { staticClass: "node-error" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "dark",
                              content: _vm.errorInfo,
                              placement: "top-start"
                            }
                          },
                          [_c("i", { staticClass: "el-icon-warning-outline" })]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "node-footer" }, [
        _c(
          "div",
          { staticClass: "btn" },
          [
            _c("insert-button", {
              on: {
                insertNode: function(type) {
                  return _vm.$emit("insertNode", type)
                }
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }