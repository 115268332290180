export default (key, name, type) => {
  if (!type) type = "camunda";
  const TYPE_TARGET = {
    activiti: "http://activiti.org/bpmn",
    camunda: "http://bpmn.io/schema/bpmn",
    flowable: "http://flowable.org/bpmn",
  };

  // return `<?xml version="1.0" encoding="UTF-8"?>
  // <bpmn2:definitions
  //   xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
  //   xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL"
  //   xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI"
  //   xmlns:dc="http://www.omg.org/spec/DD/20100524/DC"
  //   xmlns:di="http://www.omg.org/spec/DD/20100524/DI"
  //   id="diagram_${key}"
  //   targetNamespace="${TYPE_TARGET[type]}">
  //   <bpmn2:process id="${key}" name="${name}" isExecutable="true">
  //   </bpmn2:process>
  //   <bpmndi:BPMNDiagram id="BPMNDiagram_1">
  //     <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="${key}">
  //     </bpmndi:BPMNPlane>
  //   </bpmndi:BPMNDiagram>
  // </bpmn2:definitions>`;

  return `<?xml version="1.0" encoding="UTF-8"?>
  <bpmn2:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" id="diagram_${key}" targetNamespace="${TYPE_TARGET[type]}"><bpmn2:process id="${key}" name="${name}" isExecutable="true"><bpmn2:startEvent id="Event_05nhypk"><bpmn2:outgoing>Flow_0gyvpqv</bpmn2:outgoing></bpmn2:startEvent><bpmn2:userTask id="Activity_1718jvk" name="发起人" options="" type="60"><bpmn2:incoming>Flow_0gyvpqv</bpmn2:incoming></bpmn2:userTask><bpmn2:sequenceFlow id="Flow_0gyvpqv" sourceRef="Event_05nhypk" targetRef="Activity_1718jvk" /></bpmn2:process><bpmndi:BPMNDiagram id="BPMNDiagram_1"><bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="${key}"><bpmndi:BPMNEdge id="Flow_0gyvpqv_di" bpmnElement="Flow_0gyvpqv"><di:waypoint x="278" y="210" /><di:waypoint x="330" y="210" /></bpmndi:BPMNEdge><bpmndi:BPMNShape id="Event_05nhypk_di" bpmnElement="Event_05nhypk"><dc:Bounds x="242" y="192" width="36" height="36" /></bpmndi:BPMNShape><bpmndi:BPMNShape id="Activity_1718jvk_di" bpmnElement="Activity_1718jvk"><dc:Bounds x="330" y="170" width="100" height="80" /></bpmndi:BPMNShape></bpmndi:BPMNPlane></bpmndi:BPMNDiagram></bpmn2:definitions>`;
};
