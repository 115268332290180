<!--  -->
<template>
    <div class='Drawer'>
        <el-drawer :title="title" :visible.sync="drawerShow" :with-header="false" :modal="modal" :size="drawerWidth"
            :before-close="closeDrawer">
            <div class="header">
                <div class="title">{{ title }}</div>
                <div class="close" @click="closeDrawer"><img src="@/assets/close.png" alt="" width="16px" height="16px">
                </div>
            </div>
            <div class="main" style="height:calc(100vh - 128px);overflow-y: auto;">
                <slot name="drawerMain"></slot>
            </div>
            <div class="footer">
                <div class="cancelBtn" @click="closeDrawer">取消</div>
                <div class="cancelBtn" @click="deleteDrawer" v-if="type == 4 || type == 5">删除</div>
                <div class="determineBtn" @click="submitDrawer">确定</div>
            </div>
        </el-drawer>
    </div>
</template>

<script>

export default {
    name: 'Drawer',
    components: {},
    data() {
        return {
            drawerShow: false,
            type: 1
        };
    },
    //监听属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    filters: {},
    props: {
        title: {
            type: String,
            default: ''
        },
        drawerWidth: {
            type: Number || String,
            default: 340
        },
        modal: {
            type: Boolean,
            default: false
        },

    },

    methods: {
        closeDrawer() {
            this.$emit('closeDrawer')
        },
        submitDrawer() {
            this.$emit('submitDrawer', this.type)
        },
        deleteDrawer() {
            this.$emit('deleteDrawer', this.type)
        }

    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeCreate() { },
    beforeMount() { },
    beforeUpdate() { },
    updated() { },
    beforeDestroy() { },
    destroyed() { },
    //如果页面有keep-alive缓存功能，这个函数会触发
    activated() { },
}
</script>
<style lang='scss' scoped>
.header {
    height: 36px;
    width: 100%;
    border-bottom: 1px dashed #e4e5e7;
    display: flex;

    justify-content: space-between;
    font-weight: 550;

    .title {
        font-size: 14px;
    }

    .close {
        cursor: pointer;
    }
}

.footer {
    height: 36px;
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;

    .cancelBtn {
        height: 36px;
        width: 84px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        cursor: pointer;
        color: #848484;
        margin-right: 8px;
        border: 1px solid #f3f4f6;

    }

    .determineBtn {
        height: 36px;
        width: 84px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        cursor: pointer;
        background-color: #5669ec;
        color: #fff;

    }
}

.main {}
</style>