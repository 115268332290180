<template>
  <div :class="{ node: true, 'node-error-state': showError }">
    <div :class="{ 'node-body': true, error: showError }">
      <div class="node-body-left" @click="$emit('leftMove')" v-if="level > 1">
        <i class="el-icon-arrow-left"></i>
      </div>
      <div class="node-body-main" @click="$emit('selected')">
        <div class="node-body-main-header">
          <span class="title">{{
            config.name ? config.name : "条件" + level
          }}</span>
          <span class="level">优先级{{ level }}</span>
          <span class="option">
            <el-tooltip effect="dark" content="复制条件" placement="top">
              <i class="el-icon-copy-document" @click.stop="$emit('copy')"></i>
            </el-tooltip>
            <i class="el-icon-close" @click.stop="$emit('delNode')"></i>
          </span>
        </div>
        <div class="node-body-main-content">
          <el-popover
            v-if="content.length"
            placement="bottom"
            width="300"
            trigger="hover"
          >
            <div
              class="popover-f-lay"
              v-for="(row, i) in content"
              :key="'content' + i"
            >
              <div
                v-for="(item, index) in row.conditions"
                :key="'conditions' + index"
              >
                {{ index + 1 }}. {{ item }}
              </div>
              <div v-if="i != content.length - 1" class="group-type-lay">
                {{ row.groupType }}
              </div>
            </div>
            <span slot="reference" class="content">
              <span v-for="(row, i) in content" :key="'content' + i">
                <span
                  v-for="(item, index) in row.conditions"
                  :key="'conditions' + index"
                >
                  {{ index + 1 }}. {{ item }}
                </span>
              </span>
            </span>
          </el-popover>
          <span class="placeholder" v-else>{{ placeholder }}</span>
        </div>
      </div>
      <div
        class="node-body-right"
        @click="$emit('rightMove')"
        v-if="level < size"
      >
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="node-error" v-if="showError">
        <el-tooltip effect="dark" :content="errorInfo" placement="top-start">
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>
      </div>
    </div>
    <div class="node-footer">
      <div class="btn">
        <insert-button
          @insertNode="(type) => $emit('insertNode', type)"
        ></insert-button>
      </div>
    </div>
  </div>
</template>

<script>
import InsertButton from "../../InsertButton";
import { ValueType } from "@/components/process/comon/ComponetsConfigExport";

export default {
  name: "ConditionNode",
  components: { InsertButton },
  props: {
    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
    //索引位置
    level: {
      type: Number,
      default: 1,
    },
    //条件数
    size: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      placeholder: "无条件",
      errorInfo: "",
      showError: false,
    };
  },
  computed: {
    content() {
      console.log(this.config);
      let content = [],
        itemContent = {},
        strFilter = "",
        indexFilter;

      let groups = this.config.props.groups;
      if (groups.length > 0) {
        groups.forEach((row) => {
          if (row.conditions.length > 0) {
            itemContent = {
              groupType: "或",
              conditions: [],
            };
            row.conditions.forEach((item) => {
              if (item.valueType === ValueType.string) {
                strFilter =
                  item.title +
                  this.getCompare(item.compare) +
                  "【" +
                  (item.value[0] || " ") +
                  "】";
                itemContent.conditions.push(strFilter);
              } else if (item.valueType === ValueType.number) {
                strFilter =
                  item.title + this.getCompareNumber(item.compare, item.value);
                itemContent.conditions.push(strFilter);
              } else if (item.valueType === ValueType.user) {
                strFilter = item.title + this.getCompare(item.compare);
                item.value.forEach((val) => {
                  strFilter += "【" + (val.name || " ") + "】";
                });
                itemContent.conditions.push(strFilter);
              } else if (item.valueType === ValueType.radio) {
                strFilter = item.title + this.getCompare(item.compare);
                item.value.map((val) => {
                  indexFilter = item.list.findIndex(
                    (litem) => litem.ivalue == val
                  );
                  if (indexFilter >= 0) {
                    strFilter +=
                      "【" + (item.list[indexFilter].stext || " ") + "】";
                  }
                });
                itemContent.conditions.push(strFilter);
              }
            });
            content.push(itemContent);
          }
        });
      }
      return content;
    },
  },
  methods: {
    //校验数据配置的合法性
    validate() {
      if (this.config.assignedUser && this.config.assignedUser.length > 0) {
        this.showError = false;
      } else {
        this.showError = true;
        this.errorInfo = "请选择需要抄送的人员";
      }
      return !this.showError;
    },

    getCompare(compare) {
      switch (compare) {
        case "IN":
          return " 包含";

        case "NOIN":
          return " 不包含";

        default:
          return "";
      }
    },

    getCompareNumber(compare, val) {
      switch (compare) {
        case "=":
          return " 等于【" + val[0] + "】";

        case ">":
          return " 大于【" + val[0] + "】";

        case ">=":
          return " 大于等于【" + val[0] + "】";

        case "<":
          return " 小于【" + val[0] + "】";

        case "<=":
          return " 小于等于【" + val[0] + "】";

        case "B":
          return " 大于【" + val[0] + "】且小于【" + val[1] + "】";

        case "AB":
          return " 大于等于【" + val[0] + "】且小于【" + val[1] + "】";

        case "BA":
          return " 大于【" + val[0] + "】且小于等于【" + val[1] + "】";

        case "ABA":
          return " 大于等于【" + val[0] + "】且小于等于【" + val[1] + "】";

        default:
          return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/theme.scss";

.content {
  display: inline-block;
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
}
.node-error-state {
  .node-body {
    box-shadow: 0px 0px 5px 0px #f56c6c !important;
  }
}
.node {
  padding: 30px 55px 0;
  width: 220px;
  .node-body {
    cursor: pointer;
    min-height: 80px;
    max-height: 120px;
    position: relative;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0px 0px 5px 0px #d8d8d8;
    &:hover {
      .node-body-left,
      .node-body-right {
        i {
          display: block !important;
        }
      }
      .node-body-main {
        .level {
          display: none !important;
        }
        .option {
          display: inline-block !important;
        }
      }
      box-shadow: 0px 0px 3px 0px $primary;
    }
    .node-body-left,
    .node-body-right {
      display: flex;
      align-items: center;
      position: absolute;
      height: 100%;
      i {
        display: none;
      }
      &:hover {
        background-color: #ececec;
      }
    }
    .node-body-left {
      left: 0;
    }
    .node-body-right {
      right: 0;
    }
    .node-body-main {
      position: absolute;
      width: 188px;
      left: 17px;
      display: inline-block;

      .node-body-main-header {
        max-height: 40px;
        padding: 10px 0px 5px;
        font-size: xx-small;
        position: relative;
        box-sizing: border-box;
        .title {
          display: inline-block;
          max-width: 130px;
          color: #15bca3;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .level {
          position: absolute;
          right: 15px;
          color: #888888;
        }
        .option {
          position: absolute;
          right: 0;
          display: none;
          font-size: medium;
          i {
            color: #888888;
            padding: 0 3px;
          }
        }
      }
      .node-body-main-content {
        padding: 6px;
        color: #656363;
        font-size: 14px;

        i {
          position: absolute;
          top: 55%;
          right: 10px;
          font-size: medium;
        }

        .placeholder {
          color: #8c8c8c;
        }
      }
    }

    .node-error {
      position: absolute;
      right: -40px;
      top: 20px;
      font-size: 25px;
      color: #f56c6c;
    }
  }

  .node-footer {
    position: relative;
    .btn {
      width: 100%;
      display: flex;
      height: 70px;
      padding: 20px 0 32px;
      justify-content: center;
    }
    ::v-deep .el-button {
      height: 32px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      margin: auto;
      width: 2px;
      height: 100%;
      background-color: #cacaca;
    }
  }
}

.popover-f-lay {
  font-size: 13px;
  padding: 1px 0;
}

.group-type-lay {
  font-weight: bold;
  font-size: 14px;
  padding: 3px 0;
}
</style>
