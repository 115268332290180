var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          attrs: {
            "label-position": "top",
            "label-width": "90px",
            rules: _vm.rules
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "指派类型", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { clearable: "" },
                  on: { change: _vm.changeType },
                  model: {
                    value: _vm.nodeProps.type,
                    callback: function($$v) {
                      _vm.$set(_vm.nodeProps, "type", $$v)
                    },
                    expression: "nodeProps.type"
                  }
                },
                _vm._l(_vm.taskAssignRuleTypeDictDatas, function(dict) {
                  return _c("el-option", {
                    key: parseInt(dict.value),
                    attrs: { label: dict.label, value: parseInt(dict.value) }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm.nodeProps.type === 10
            ? _c(
                "el-form-item",
                { attrs: { label: "指派人员", prop: "options" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { multiple: "", clearable: "" },
                      model: {
                        value: _vm.nodeProps.options,
                        callback: function($$v) {
                          _vm.$set(_vm.nodeProps, "options", $$v)
                        },
                        expression: "nodeProps.options"
                      }
                    },
                    _vm._l(_vm.$store.state.bpm.roleOptions, function(item) {
                      return _c("el-option", {
                        key: parseInt(item.roleId),
                        attrs: { label: item.roleName, value: item.roleId }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.nodeProps.type === 20 || _vm.nodeProps.type === 21
            ? _c(
                "el-form-item",
                { attrs: { label: "指派人员", prop: "options" } },
                [
                  _c("treeselect", {
                    attrs: {
                      options: _vm.$store.state.bpm.deptTreeOptions,
                      multiple: "",
                      flat: "",
                      defaultExpandLevel: 3,
                      placeholder: "请选择",
                      normalizer: _vm.normalizer
                    },
                    model: {
                      value: _vm.nodeProps.options,
                      callback: function($$v) {
                        _vm.$set(_vm.nodeProps, "options", $$v)
                      },
                      expression: "nodeProps.options"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.nodeProps.type === 30 ||
          _vm.nodeProps.type === 31 ||
          _vm.nodeProps.type === 32
            ? _c(
                "el-form-item",
                { attrs: { label: "指派人员", prop: "options" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { multiple: "", clearable: "", filterable: "" },
                      model: {
                        value: _vm.nodeProps.options,
                        callback: function($$v) {
                          _vm.$set(_vm.nodeProps, "options", $$v)
                        },
                        expression: "nodeProps.options"
                      }
                    },
                    _vm._l(_vm.$store.state.bpm.userOptions, function(item) {
                      return _c("el-option", {
                        key: item.userId,
                        attrs: { label: item.userName, value: item.userId }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.nodeProps.type === 40
            ? _c(
                "el-form-item",
                { attrs: { label: "指派人员", prop: "options" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { multiple: "", clearable: "" },
                      model: {
                        value: _vm.nodeProps.options,
                        callback: function($$v) {
                          _vm.$set(_vm.nodeProps, "options", $$v)
                        },
                        expression: "nodeProps.options"
                      }
                    },
                    _vm._l(_vm.$store.state.bpm.userGroupOptions, function(
                      item
                    ) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("org-picker", {
        attrs: { single: true, show: _vm.showOrgSelect, selected: _vm.select },
        on: { close: _vm.closeSelect, selectOver: _vm.selected }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }