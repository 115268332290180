var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "el-fade-in" } },
    [
      _vm.dialogVisible
        ? _c(
            "Customdialog",
            {
              attrs: {
                dialogWidth: "620px",
                title: _vm.title,
                dialogHeight: "70%",
                isFooter: true
              },
              on: { closeDialog: _vm.close, submitDialog: _vm.confirm }
            },
            [
              _c("template", { slot: "dialogMain" }, [
                _c("div", { staticClass: "initiatingRange" }, [
                  _c("div", { staticClass: "main" }, [
                    _c("div", { staticClass: "left" }, [
                      _c(
                        "div",
                        {
                          staticClass: "head",
                          staticStyle: { padding: "0 20px" }
                        },
                        [
                          _vm.addType == 3
                            ? _c(
                                "div",
                                { staticClass: "type_list" },
                                _vm._l(_vm.typeList, function(item, index) {
                                  return _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.ifRole && item.name == "按角色"
                                              ? false
                                              : true,
                                          expression:
                                            "(ifRole && item.name == '按角色') ? false : true"
                                        }
                                      ],
                                      key: index,
                                      staticClass: "type_item"
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "img",
                                        attrs: {
                                          src:
                                            _vm.typeActive == index
                                              ? item.u_img
                                              : item.img
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.chooseType(index)
                                          }
                                        }
                                      }),
                                      _c("span", [_vm._v(_vm._s(item.name))])
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _vm.typeActive == 0 && !_vm.isGroup
                            ? _c("el-input", {
                                staticStyle: { "margin-bottom": "8px" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "请输入关键词"
                                },
                                on: { input: _vm.findUserDeptByName },
                                model: {
                                  value: _vm.findUserName,
                                  callback: function($$v) {
                                    _vm.findUserName = $$v
                                  },
                                  expression: "findUserName"
                                }
                              })
                            : _vm._e(),
                          _vm.typeActive == 1
                            ? _c("el-input", {
                                staticStyle: { "margin-bottom": "8px" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "请输入关键词"
                                },
                                model: {
                                  value: _vm.filterText,
                                  callback: function($$v) {
                                    _vm.filterText = $$v
                                  },
                                  expression: "filterText"
                                }
                              })
                            : _vm._e(),
                          _vm.typeActive == 2
                            ? _c("el-input", {
                                staticStyle: { "margin-bottom": "8px" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "请输入关键词"
                                },
                                on: { input: _vm.findRoleName },
                                model: {
                                  value: _vm.roleName,
                                  callback: function($$v) {
                                    _vm.roleName = $$v
                                  },
                                  expression: "roleName"
                                }
                              })
                            : _vm._e(),
                          _vm.typeActive == 0 && !_vm.findUserName
                            ? _c(
                                "div",
                                { staticClass: "breadcrumb" },
                                _vm._l(_vm.breadcrumb, function(item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "breadcrumb_item",
                                      class:
                                        index != _vm.breadcrumb.length - 1
                                          ? "pointer"
                                          : "",
                                      on: {
                                        click: function($event) {
                                          return _vm.chooseBreadcrumb(
                                            item,
                                            index
                                          )
                                        }
                                      }
                                    },
                                    [
                                      index == 0
                                        ? _c("img", {
                                            attrs: {
                                              src: require("@/assets/20240119home.png"),
                                              alt: ""
                                            }
                                          })
                                        : _c("i", {
                                            staticClass:
                                              "el-icon-d-arrow-right color"
                                          }),
                                      _c(
                                        "p",
                                        { class: index == 0 ? "color" : "" },
                                        [_vm._v(_vm._s(item.label))]
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("div", { staticClass: "tree" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.typeActive == 0 && _vm.findUserName,
                                expression: "typeActive == 0 && findUserName"
                              }
                            ]
                          },
                          _vm._l(_vm.findUserList, function(item, index) {
                            return _c(
                              "div",
                              {
                                key: item.userId,
                                staticClass: "tree_framework"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "two",
                                    class: item.flag ? "bg_avtive" : ""
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "check_box",
                                        staticStyle: { cursor: "pointer" },
                                        on: {
                                          click: function($event) {
                                            return _vm.checkfinduser(
                                              item,
                                              index
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: item.flag,
                                              expression: "item.flag"
                                            }
                                          ],
                                          staticClass: "el-icon-check"
                                        })
                                      ]
                                    ),
                                    _c("div", { staticClass: "userinfo" }, [
                                      item.avatarUrl
                                        ? _c("img", {
                                            attrs: {
                                              src: item.avatarUrl,
                                              alt: ""
                                            }
                                          })
                                        : _c("img", {
                                            attrs: {
                                              src:
                                                "https://cdn.fengwork.com/workbench/20240118touxiang.png",
                                              alt: ""
                                            }
                                          }),
                                      _c("span", [
                                        _vm._v(_vm._s(item.userName))
                                      ])
                                    ])
                                  ]
                                )
                              ]
                            )
                          }),
                          0
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.typeActive == 0 && !_vm.findUserName,
                                expression: "typeActive == 0 && !findUserName"
                              }
                            ]
                          },
                          [
                            _c("div", { staticClass: "tree_framework" }, [
                              !_vm.radio
                                ? _c("div", { staticClass: "one" }, [
                                    _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "check_box",
                                          staticStyle: { cursor: "pointer" },
                                          on: {
                                            click: function($event) {
                                              return _vm.checkBoxAll()
                                            }
                                          }
                                        },
                                        [
                                          _vm.depAll
                                            ? _c("i", {
                                                staticClass: "el-icon-check"
                                              })
                                            : _vm._e()
                                        ]
                                      ),
                                      _c("span", [_vm._v("全部")])
                                    ])
                                  ])
                                : _vm._e()
                            ]),
                            _vm._l(_vm.presentDep.children, function(
                              item,
                              index
                            ) {
                              return _c(
                                "div",
                                { key: index, staticClass: "tree_framework" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "one",
                                      class: item.flag ? "bg_avtive" : ""
                                    },
                                    [
                                      _c("div", {}, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "check_box",
                                            class: _vm.isUser
                                              ? "disabled_box"
                                              : "",
                                            staticStyle: { cursor: "pointer" },
                                            on: {
                                              click: function($event) {
                                                return _vm.checkBox(1, item)
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: item.flag,
                                                  expression: "item.flag"
                                                }
                                              ],
                                              staticClass: "el-icon-check"
                                            })
                                          ]
                                        ),
                                        _c("span", [_vm._v(_vm._s(item.label))])
                                      ]),
                                      _c(
                                        "p",
                                        {
                                          class: item.flag ? "level" : "",
                                          on: {
                                            click: function($event) {
                                              return _vm.choosePresentDep(item)
                                            }
                                          }
                                        },
                                        [_vm._v(" 下级 ")]
                                      )
                                    ]
                                  )
                                ]
                              )
                            }),
                            !_vm.isGroup
                              ? _vm._l(_vm.presentDep.userList, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.id,
                                      staticClass: "tree_framework"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "two",
                                          class: item.flag ? "bg_avtive" : ""
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "check_box",
                                              staticStyle: {
                                                cursor: "pointer"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.checkBox(2, item)
                                                }
                                              }
                                            },
                                            [
                                              _c("i", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: item.flag,
                                                    expression: "item.flag"
                                                  }
                                                ],
                                                staticClass: "el-icon-check"
                                              })
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "userinfo" },
                                            [
                                              item.avatarUrl
                                                ? _c("img", {
                                                    attrs: {
                                                      src: item.avatarUrl,
                                                      alt: ""
                                                    }
                                                  })
                                                : _c("img", {
                                                    attrs: {
                                                      src:
                                                        "https://cdn.fengwork.com/workbench/20240118touxiang.png",
                                                      alt: ""
                                                    }
                                                  }),
                                              _c("span", [
                                                _vm._v(_vm._s(item.userName))
                                              ])
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                })
                              : _vm._e()
                          ],
                          2
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.typeActive == 1,
                                expression: "typeActive == 1"
                              }
                            ]
                          },
                          [
                            _c("el-tree", {
                              ref: "tree",
                              attrs: {
                                data: _vm.userGroup,
                                "node-key": "id",
                                props: _vm.defaultProps,
                                "default-expand-all": "",
                                "filter-node-method": _vm.filterNode
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var node = ref.node
                                      var data = ref.data
                                      return _c(
                                        "span",
                                        {
                                          staticClass: "custom-tree-node",
                                          on: {
                                            click: function($event) {
                                              return _vm.chooseGroup(data)
                                            }
                                          }
                                        },
                                        [
                                          _c("div", [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-user-solid mr10"
                                            }),
                                            _c("span", [
                                              _vm._v(_vm._s(node.label))
                                            ])
                                          ]),
                                          data.parentId > 0
                                            ? _c("span", [
                                                data.flag
                                                  ? _c("i", {
                                                      staticClass:
                                                        "el-icon-circle-check"
                                                    })
                                                  : _c("div", {
                                                      staticClass: "circle"
                                                    })
                                              ])
                                            : _vm._e()
                                        ]
                                      )
                                    }
                                  }
                                ],
                                null,
                                false,
                                2390443850
                              )
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.typeActive == 2,
                                expression: "typeActive == 2"
                              }
                            ]
                          },
                          _vm._l(_vm.role, function(item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "tree_item",
                                on: {
                                  click: function($event) {
                                    return _vm.chooserole(index, item)
                                  }
                                }
                              },
                              [
                                _c("span", [_vm._v(_vm._s(item.roleName))]),
                                item.flag
                                  ? _c("i", {
                                      staticClass: "el-icon-circle-check"
                                    })
                                  : _c("div", { staticClass: "circle" })
                              ]
                            )
                          }),
                          0
                        ),
                        _vm.addType == 4
                          ? _c(
                              "div",
                              _vm._l(_vm.deptMangerList, function(item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "tree_item",
                                    on: {
                                      click: function($event) {
                                        return _vm.chooseDept(index, item)
                                      }
                                    }
                                  },
                                  [
                                    _c("span", [_vm._v(_vm._s(item.name))]),
                                    item.flag
                                      ? _c("i", {
                                          staticClass: "el-icon-circle-check"
                                        })
                                      : _c("div", { staticClass: "circle" })
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ])
                    ]),
                    _c("div", { staticClass: "right" }, [
                      _c("p", { staticStyle: { "margin-bottom": "16px" } }, [
                        _vm._v("已选择")
                      ]),
                      _c(
                        "div",
                        { staticClass: "list" },
                        _vm._l(_vm.chooseList, function(item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "list_item" },
                            [
                              _c("div", [
                                item.avatarUrl
                                  ? _c("img", {
                                      attrs: { src: item.avatarUrl, alt: "" }
                                    })
                                  : _vm._e(),
                                _c("span", [_vm._v(_vm._s(item.name))])
                              ]),
                              _c("i", {
                                staticClass: "el-icon-error",
                                staticStyle: {
                                  color: "#ff424c",
                                  cursor: "pointer"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteList(
                                      item,
                                      index,
                                      item.type
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ])
                ])
              ])
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }