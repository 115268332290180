import request from "@/utils/request";

// 模糊搜索用户
export function findUserDeptByName(params) {
  return request({
    url: "/bpm/flowManagement/findUserDeptByName",
    method: "get",
    params
  });
}

// 获取用户组精简信息列表
export function listSimpleUserGroups() {
  return request({
    url: "/bpm/user-group/findGroupsList",
    method: "get"
  });
}
// 获取用户组精简信息列表
export function findGroupsList() {
  return request({
    url: "/bpm/user-group/findGroupsList",
    method: "get"
  });
}

// 查询角色（精简)列表
export function listSimpleRoles(params) {
  return request({
    url: "/bpm/sys/role/list-all-simple",
    method: "get",
    params
  });
}

// 查询用户部门树
export function getFindDeptUser() {
  return request({
    url: "/bpm/flowManagement/findDeptUser",
    method: "get"
  });
}

// 查询审批表单分组
export function createForm(params) {
  return request({
    url: "/bpm/flowManagement/findGroup",
    method: "get",
    params
  });
}

// 自动生产流程标识key
export function getFlowKey(params) {
  return request({
    url: "/bpm/flowManagement/getFlowKey",
    method: "get",
    params,
  });
}

// 新增流程
export function addFlowManagement(data) {
  return request({
    url: "/bpm/flowManagement/add",
    method: "post",
    data,
  });
}

// 查询部门下人员
export function getDepUserList(params) {
  return request({
    url: "/bpm/flowManagement/list",
    method: "get",
    params,
  });
}

// 查询流程详情
export function flowManagement(id) {
  return request({
    url: `/bpm/flowManagement/${id}`,
    method: "get",
  });
}
// 修改流程
export function editFlowManagement(data) {
  return request({
    url: "/bpm/flowManagement/edit",
    method: "put",
    data,
  });
}

// 简单流程新增
export function simpleSave(data) {
  return request({
    url: "/bpm/simple/save",
    method: "post",
    data,
  });
}

// 简单流程编辑
export function simpleEdit(data) {
  return request({
    url: "/bpm/simple/edit",
    method: "PUT",
    data,
  });
}


// 获取用户精简信息列表
export function listSimpleUsers() {
  return request({
    url: '/bpm/sys/user/list-all-simple',
    method: 'get'
  })
}

// 获取部门精简信息列表
export function listSimpleDepts() {
  return request({
    url: '/bpm/sys/dept/list-all-simple',
    method: 'get'
  })
}

// 生产条件表达式
export function createExpression(data) {
  return request({
    url: "/bpm/flowManagement/expression/create",
    method: "post",
    data,
  });
}

