import Vue from "vue";
import Vuex from "vuex";
// import app from "./modules/app";
// import user from "./modules/user";
import bpm from "./modules/bpm";
// import tagsView from "./modules/tagsView";
// import permission from "./modules/permission";
// import settings from "./modules/settings";
import dict from "./modules/dict";
import getters from "./getters";
import process from "./modules/process";
// import condition from "./modules/condition";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    bpm,
    process,
    dict
  },
  getters
});

export default store;
