import Vue from "vue";
import VueRouter from "vue-router";
// 页面组件
import ManagementBackend from "../views/ManagementBackend.vue";
import usergroup from "../views/usergroup.vue";
import createFlow from "../views/createFlow/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/main/workbench/ProcessSetting",  
    name: "Backstage",  
    component: ManagementBackend,
    redirect: '/main/workbench/ProcessSetting/usergroup',
  },
  {
    path: "/main/workbench/ProcessSetting/backstage",  
    name: "Backstage",  
    component: ManagementBackend,
  },
  {
    path: "/main/workbench/ProcessSetting/usergroup",
    name: "Usergroup",
    component: usergroup
  },
  {
    path: "/main/workbench/ProcessSetting/createFlow",
    name: "CreateFlow",
    component: createFlow
  }
];

export default routes;
