var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form_container" },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.elementBaseInfo.regulator,
            size: "mini",
            "max-height": "240",
            fit: ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "表单字段",
              prop: "name",
              "min-width": "80px",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [_vm._v(" " + _vm._s(row.label.split("_")[1]))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "可编辑",
              prop: "value",
              width: "60px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-radio", {
                      staticClass: "radioLabel",
                      attrs: {
                        disabled:
                          _vm.elementBaseInfo.$type == "bpmn:ServiceTask" ||
                          row.label.includes("litigant")
                            ? true
                            : row.conditionDisabled,
                        label: 1
                      },
                      model: {
                        value: row.value,
                        callback: function($$v) {
                          _vm.$set(row, "value", $$v)
                        },
                        expression: "row.value"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "只读",
              prop: "value",
              width: "60px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-radio", {
                      staticClass: "radioLabel",
                      attrs: { disabled: row.conditionDisabled, label: 0 },
                      model: {
                        value: row.value,
                        callback: function($$v) {
                          _vm.$set(row, "value", $$v)
                        },
                        expression: "row.value"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "隐藏",
              prop: "value",
              width: "60px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-radio", {
                      staticClass: "radioLabel",
                      attrs: { disabled: row.conditionDisabled, label: 2 },
                      model: {
                        value: row.value,
                        callback: function($$v) {
                          _vm.$set(row, "value", $$v)
                        },
                        expression: "row.value"
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("div", { staticClass: "element-drawer__button" }, [
        _vm.loading
          ? _c("div", { staticClass: "saveBtning" }, [_vm._v("保存中")])
          : _c(
              "div",
              { staticClass: "saveBtn", on: { click: _vm.setFormPermission } },
              [_vm._v("保存")]
            )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }