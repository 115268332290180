<template>
  <div class="form_container">
    <el-table :data="elementBaseInfo.regulator" size="mini" max-height="240" fit>
      <el-table-column label="表单字段" prop="name" min-width="80px" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <span> {{ row.label.split("_")[1] }}</span>
          <!-- {{ row.conditionDisabled }} -->
        </template>
      </el-table-column>
      <el-table-column label="可编辑" prop="value" width="60px" align="center">
        <template slot-scope="{ row }">
          <el-radio :disabled="elementBaseInfo.$type == 'bpmn:ServiceTask' ||
            row.label.includes('litigant')
            ? true
            : row.conditionDisabled
            " v-model="row.value" :label="1" class="radioLabel"></el-radio>
        </template>
      </el-table-column>
      <el-table-column label="只读" prop="value" width="60px" align="center">
        <template slot-scope="{ row }">
          <el-radio :disabled="row.conditionDisabled" v-model="row.value" :label="0" class="radioLabel" ></el-radio>
        </template>
      </el-table-column>
      <el-table-column label="隐藏" prop="value" width="60px" align="center">
        <template slot-scope="{ row }">
          <el-radio :disabled="row.conditionDisabled" v-model="row.value" :label="2" class="radioLabel"></el-radio>
        </template>
      </el-table-column>
    </el-table>
    <div class="element-drawer__button" >
      <div v-if="loading"  class="saveBtning">保存中</div>
      <div v-else @click="setFormPermission" class="saveBtn">保存</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { uniqWith } from "lodash-es";
export default {
  props: {
    bpmnModeler: Object,
    businessObject: {
      type: Object,
      default: {},
    },
    model: Object,
  },
  data() {
    return {
      elementBaseInfo: {
        regulator: [],
      },
      loading: false,
      formList: [],
    };
  },
  inject: {
    prefix: "prefix",
    width: "width",
  },
  watch: {
    businessObject: {
      immediate: false,
      handler: function (val) {
        if (val) {
          this.$nextTick(() => this.resetBaseInfo());
        }
      },
    },
  },
  computed: {
    ...mapState({
      formInfo: (state) => state.process.design.formInfo,
      checkList: (state) => state.bpm.checkList,
    }),
  },
  created() {
    this.$nextTick(() => this.resetBaseInfo());
  },
  methods: {
    resetBaseInfo() {
      this.bpmnElement = window?.bpmnInstances?.bpmnElement;
      this.elementBaseInfo = JSON.parse(
        JSON.stringify(this.bpmnElement.businessObject)
      );
      let { regulator } = this.bpmnElement.businessObject.$attrs;
      if (!regulator) {
        regulator = [];
      } else {
        regulator = JSON.parse(regulator);
      }
      // this.resetAttributesList();
      this.elementBaseInfo = {
        ...this.elementBaseInfo,
        regulator,
      };
      this.init();
      this.checkform();
    },
    init() {
      if (this.elementBaseInfo.regulator.length) return;
      if (!this.formInfo.length) return;


      let formInfo = JSON.parse(JSON.stringify(this.formInfo));
      formInfo = formInfo.map((e) => {
        return {
          label: e.fieldVal + "_" + e.fieldName,
          value: 0,
        };
      });
      this.elementBaseInfo.regulator = formInfo;
      this.saveForm();
    },
    checkform() {

      this.elementBaseInfo.regulator.forEach((i) => {
        // 初始化为 false
        this.$set(i, "conditionDisabled", false);
        console.log(this.checkList, this, '11111')
        // 遍历 checkList，如果有匹配，则将 conditionDisabled 设置为 true
        this.checkList.forEach((e) => {
          if (i.label.split("_")[0] == e) {
            this.$set(i, "conditionDisabled", true);
          }
        });
        // 组合容器特殊控制
        if (i.label.split("_")[0] == "leave") {
          const leaveList = this.formInfo.filter((e) => e.fieldVal == "leave")
          leaveList[0].children[0].forEach((err) => {
            this.checkList.forEach((e) => {
              if (err.__vModel__ == e) {
                this.$set(i, "conditionDisabled", true);
              }
            });
          });
        }

      });
    },
    // 保存节点
    async setFormPermission() {
      this.loading = true;
      setTimeout(() => {
        this.saveForm();
        this.loading = false;
        this.$message.success("保存成功");
      }, 500);
    },
    saveForm() {
      const attrObj = Object.create(null);
      attrObj["regulator"] = JSON.stringify(this.elementBaseInfo["regulator"]);
      window.bpmnInstances.modeling.updateProperties(this.bpmnElement, attrObj);
    },
    // 获取已选条件的表单
    conditionlabel() {
      let e = this.bpmnModeler.getDefinitions();
      let arr = [];
      //   console.log(e.rootElements[0].flowElements, "oooooooo");
      let sequenceFlowrrList = e.rootElements[0].flowElements.filter(
        (e) => e.$type == "bpmn:SequenceFlow"
      );
      sequenceFlowrrList.map((e) => {
        if (e.$attrs.conditionList) {
          arr.push(...JSON.parse(e.$attrs.conditionList));
          //   console.log(JSON.parse(e.$attrs.conditionList), 22222);
        }
      });
      let arr2 = [];
      arr.map((e) => {
        arr2.push(...e.checkList);
      });
      console.log(`output->arr2`, arr2)
      return uniqWith(arr2, _.isEqual);
      //   console.log(arr2, "oooooo");
    },
  },
};
</script>

<style lang="scss" scoped>
.form_container {
  padding: 8px 16px;
}

::v-deep .el-radio__label {
  display: none;
}
::v-deep .radioLabel{
    display: flex!important;
    align-items: center;
    justify-content: center;
}

.saveBtn{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 28px;
  background-color: #5669ec;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}
.saveBtning{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 28px;
  background-color: #8695f1;
  color: #fff;
  border-radius: 4px;
}
</style>
