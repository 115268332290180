import { render, staticRenderFns } from "./ProcessDesigner.vue?vue&type=template&id=4ac450f3&scoped=true&"
import script from "./ProcessDesigner.vue?vue&type=script&lang=js&"
export * from "./ProcessDesigner.vue?vue&type=script&lang=js&"
import style0 from "./ProcessDesigner.vue?vue&type=style&index=0&id=4ac450f3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ac450f3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("G:\\sub\\design\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4ac450f3')) {
      api.createRecord('4ac450f3', component.options)
    } else {
      api.reload('4ac450f3', component.options)
    }
    module.hot.accept("./ProcessDesigner.vue?vue&type=template&id=4ac450f3&scoped=true&", function () {
      api.rerender('4ac450f3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/bpmnProcessDesigner/package/designer/ProcessDesigner.vue"
export default component.exports