<template>
  <node
    :title="config.name"
    :type="config.props.type"
    :show-error="showError"
    :error-info="errorInfo"
    @selected="$emit('selected')"
    @delNode="$emit('delNode')"
    @insertNode="(type) => $emit('insertNode', type)"
    placeholder="请设置审批人"
    header-bgc="#ff943e"
    header-icon="el-icon-s-check"
  />
</template>

<script>
import Node from "./Node";

export default {
  name: "ApprovalNode",
  props: {
    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: { Node },
  data() {
    return {
      showError: false,
      errorInfo: "",
    };
  },
  mounted() {
    // console.log('m',this.config)
  },
  watch: {
    "config.props.type"(val) {
      console.log(val, "watch");
    },
  },

  methods: {
    //校验数据配置的合法性
    validate() {
      try {
        return (this.showError = !this[`validate_${this.config.type}`]());
      } catch (e) {
        return true;
      }
    },
    // 转换
    convert(val) {
      let str = "";
      switch (val) {
        case "LEADER_TOP":
          str = "连续多级主管";
          break;
        case "LEADER":
          str = "发起人部门主管";
          break;
        case "SELF":
          str = "发起人自己";
          break;
        case "BACK_LEADER":
          str = "上一步骤部门主管";
      }
      return str;
    },
    validate_ASSIGN_USER() {
      return true;
      if (this.config.assignedUser && this.config.assignedUser.length > 0) {
        return true;
      } else {
        this.$message.warning("请设置审批人");
      }
    },
    validate_SELF_SELECT() {
      return true;
    },
    validate_LEADER_TOP() {
      return true;
    },
    validate_LEADER() {
      return true;
    },
    validate_ROLE() {
      return true;
    },
    validate_SELF() {
      return true;
    },
    validate_REFUSE() {
      return true;
    },
  },
};
</script>

<style scoped></style>
