import request from "@/utils/request";
// import { getRefreshToken } from '@/utils/auth'
import service from "@/utils/request";

// 查询审批表单分组
export function findGroup(params) {
  return request({
    url: "/bpm/flowManagement/findGroup",
    method: "get",
    params
  });
}
// 查询审批表单
export function findGroupList(params) {
  return request({
    url: "/bpm/flowManagement/list",
    method: "get",
    params
  });
}
// 流程启用-关闭
export function flowManagementVisible(id, state) {
  return request({
    url: `/bpm/flowManagement/visible/${id}/${state}`,
    method: "post"
  });
}
// 删除审批表单分组
export function deleteGroup(id) {
  return request({
    url: "/bpm/flowManagement/deleteGroup/" + id,
    method: "post"
  });
}
// 编辑审批表单分组
export function editGroup(data) {
  return request({
    url: "/bpm/flowManagement/editGroup",
    method: "put",
    data
  });
}

// 新增审批表单分组
export function addGroup(data) {
  return request({
    url: "/bpm/flowManagement/addGroup",
    method: "post",
    data,
  });
}

// 查询流程草稿列表
export function getDraftList() {
  return request({
    url: "/bpm/flowManagement/draftList",
    method: "get",
  });
}
// 移动流程
export function moveFlowManagement(id, groupId) {
  return request({
    url: `/bpm/flowManagement/move/${id}/${groupId}`,
    method: "post",
  });
} 

// 删除流程
export function deleteFlow(id) {
  return request({
    url: `/bpm/flowManagement/deleteFlow/${id}`,
    method: "post",
  });
}

// 分组排序
export function sortGroupFlow(arr) {
  return request({
    url: `/bpm/flowManagement/sortGroup`,
    method: "post",
    data: arr
  });
}

// 流程排序
export function sortFlow(arr) {
  return request({
    url: `/bpm/flowManagement/sortFlow`,
    method: "post",
    data: arr
  });
}