var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.selectedNode.props.groups, function(group, index) {
        return _c("div", { key: index + "_g", staticClass: "group" }, [
          _c("div", { staticClass: "group-header" }, [
            _c("span", { staticClass: "group-name" }, [
              _vm._v("条件组 " + _vm._s(_vm.groupNames[index]))
            ]),
            _c("div", { staticClass: "group-operation" }, [
              _c(
                "span",
                {
                  staticStyle: {
                    color: "#1890ff",
                    "margin-right": "15px",
                    cursor: "pointer",
                    "font-size": "small"
                  },
                  on: {
                    click: function($event) {
                      return _vm.choose(group, index)
                    }
                  }
                },
                [_vm._v("添加条件字段")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    color: "red",
                    cursor: "pointer",
                    "font-size": "small"
                  },
                  on: {
                    click: function($event) {
                      return _vm.delGroup(index)
                    }
                  }
                },
                [_vm._v("删除")]
              )
            ])
          ]),
          _c("div", { staticClass: "group-content" }, [
            group.conditions.length === 0
              ? _c("p", [_vm._v(" 点击右上角为本条件组添加条件 ☝ ")])
              : _c(
                  "div",
                  [
                    _c(
                      "el-form",
                      {
                        ref: "condition-form",
                        refInFor: true,
                        attrs: { "label-width": "100px" }
                      },
                      _vm._l(group.conditions, function(condition, cindex) {
                        return _c(
                          "el-form-item",
                          {
                            key: condition.id + "_" + cindex,
                            attrs: { label: condition.title, required: "" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "space-between"
                                }
                              },
                              [
                                condition.valueType === _vm.ValueType.string
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "90px" },
                                            attrs: {
                                              size: "mini",
                                              placeholder: "判断符"
                                            },
                                            model: {
                                              value: condition.compare,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  condition,
                                                  "compare",
                                                  $$v
                                                )
                                              },
                                              expression: "condition.compare"
                                            }
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: {
                                                label: "不包含",
                                                value: "NOIN"
                                              }
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                label: "包含",
                                                value: "IN"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "10px"
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              [
                                                _c("el-input", {
                                                  staticStyle: {
                                                    width: "280px"
                                                  },
                                                  attrs: {
                                                    placeholder: "请输入值",
                                                    size: "mini"
                                                  },
                                                  model: {
                                                    value: condition.value[0],
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        condition.value,
                                                        0,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "condition.value[0]"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : condition.valueType === _vm.ValueType.number
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "180px" },
                                            attrs: {
                                              size: "mini",
                                              placeholder: "判断符"
                                            },
                                            model: {
                                              value: condition.compare,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  condition,
                                                  "compare",
                                                  $$v
                                                )
                                              },
                                              expression: "condition.compare"
                                            }
                                          },
                                          _vm._l(_vm.explains, function(exp) {
                                            return _c("el-option", {
                                              key: exp.value,
                                              attrs: {
                                                label: exp.label,
                                                value: exp.value
                                              }
                                            })
                                          }),
                                          1
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "10px"
                                            }
                                          },
                                          [
                                            _vm.conditionValType(
                                              condition.compare
                                            ) === 0
                                              ? _c("el-input", {
                                                  staticStyle: {
                                                    width: "240px"
                                                  },
                                                  attrs: {
                                                    size: "mini",
                                                    placeholder: "输入比较值",
                                                    type: "number"
                                                  },
                                                  model: {
                                                    value: condition.value[0],
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        condition.value,
                                                        0,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "condition.value[0]"
                                                  }
                                                })
                                              : _vm.conditionValType(
                                                  condition.compare
                                                ) === 1
                                              ? _c("el-select", {
                                                  staticStyle: {
                                                    width: "240px"
                                                  },
                                                  attrs: {
                                                    multiple: "",
                                                    filterable: "",
                                                    "allow-create": "",
                                                    size: "mini",
                                                    placeholder:
                                                      "输入可能包含的值"
                                                  },
                                                  model: {
                                                    value: condition.value,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        condition,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "condition.value"
                                                  }
                                                })
                                              : _c(
                                                  "span",
                                                  [
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        width: "120px"
                                                      },
                                                      attrs: {
                                                        size: "mini",
                                                        type: "number",
                                                        placeholder:
                                                          "输入比较值"
                                                      },
                                                      model: {
                                                        value:
                                                          condition.value[0],
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            condition.value,
                                                            0,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "condition.value[0]"
                                                      }
                                                    }),
                                                    _c(
                                                      "span",
                                                      [
                                                        _vm._v(" ~ "),
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "120px"
                                                          },
                                                          attrs: {
                                                            size: "mini",
                                                            type: "number",
                                                            placeholder:
                                                              "输入比较值"
                                                          },
                                                          model: {
                                                            value:
                                                              condition
                                                                .value[1],
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                condition.value,
                                                                1,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "condition.value[1]"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : condition.valueType === _vm.ValueType.user
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "90px" },
                                            attrs: { size: "mini" },
                                            model: {
                                              value: condition.compare,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  condition,
                                                  "compare",
                                                  $$v
                                                )
                                              },
                                              expression: "condition.compare"
                                            }
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: {
                                                label: "不包含",
                                                value: "NOIN"
                                              }
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                label: "包含",
                                                value: "IN"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "margin-left": "10px"
                                            },
                                            attrs: {
                                              size: "mini",
                                              icon: "el-icon-plus",
                                              type: "primary",
                                              round: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.selectUser(condition)
                                              }
                                            }
                                          },
                                          [
                                            condition.selectType == "selectDept"
                                              ? _c("span", [_vm._v(" 部门 ")])
                                              : condition.selectType ==
                                                "selectUser"
                                              ? _c("span", [_vm._v(" 人员 ")])
                                              : _vm._e()
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticStyle: { margin: "10px" } },
                                          _vm._l(condition.value, function(
                                            org,
                                            index
                                          ) {
                                            return _c(
                                              "el-tag",
                                              {
                                                key: index + "_org",
                                                staticClass: "org-item",
                                                staticStyle: { margin: "5px" },
                                                attrs: {
                                                  type:
                                                    org.type === "dept"
                                                      ? ""
                                                      : "info",
                                                  closable: "",
                                                  size: "mini"
                                                },
                                                on: {
                                                  close: function($event) {
                                                    return _vm.removeOrgItem(
                                                      condition.value,
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(org.name) + " "
                                                )
                                              ]
                                            )
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : condition.valueType === _vm.ValueType.dept
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "item-desc",
                                            staticStyle: {
                                              "margin-right": "20px"
                                            }
                                          },
                                          [_vm._v("为某部门 / 某部门下的部门")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              icon: "el-icon-plus",
                                              type: "primary",
                                              round: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.selectUser(condition)
                                              }
                                            }
                                          },
                                          [_vm._v("选择部门")]
                                        ),
                                        _c(
                                          "div",
                                          { staticStyle: { margin: "10px" } },
                                          _vm._l(condition.value, function(
                                            org,
                                            index
                                          ) {
                                            return _c(
                                              "el-tag",
                                              {
                                                key: index + "_org",
                                                staticClass: "org-item",
                                                staticStyle: { margin: "5px" },
                                                attrs: {
                                                  type:
                                                    org.type === "dept"
                                                      ? ""
                                                      : "info",
                                                  closable: "",
                                                  size: "mini"
                                                },
                                                on: {
                                                  close: function($event) {
                                                    return _vm.removeOrgItem(
                                                      condition.value,
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(org.name) + " "
                                                )
                                              ]
                                            )
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : condition.valueType === _vm.ValueType.select
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              size: "mini",
                                              placeholder: "请选择"
                                            },
                                            model: {
                                              value: condition.value,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  condition,
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression: "condition.value"
                                            }
                                          },
                                          _vm._l(_vm.radioList, function(item) {
                                            return _c("el-option", {
                                              key: index + item.label,
                                              attrs: {
                                                label: item.label,
                                                value: item.label,
                                                type: item.type
                                              }
                                            })
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : condition.valueType === _vm.ValueType.radio
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: {
                                              "min-width": "90px"
                                            },
                                            attrs: {
                                              size: "mini",
                                              placeholder: "判断符"
                                            },
                                            model: {
                                              value: condition.compare,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  condition,
                                                  "compare",
                                                  $$v
                                                )
                                              },
                                              expression: "condition.compare"
                                            }
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: {
                                                label: "不包含",
                                                value: "NOIN"
                                              }
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                label: "包含",
                                                value: "IN"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-checkbox-group",
                                          {
                                            staticStyle: {
                                              "margin-left": "10px"
                                            },
                                            model: {
                                              value: condition.value,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  condition,
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression: "condition.value"
                                            }
                                          },
                                          _vm._l(condition.list, function(
                                            citem
                                          ) {
                                            return _c(
                                              "el-checkbox",
                                              {
                                                key: citem.iid,
                                                attrs: {
                                                  label: citem.ivalue,
                                                  value: citem.idictionaryid
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(citem.stext) +
                                                    " "
                                                )
                                              ]
                                            )
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("i", {
                                  staticClass: "el-icon-delete",
                                  on: {
                                    click: function($event) {
                                      return _vm.deleted(cindex, group)
                                    }
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
          ])
        ])
      }),
      _c("org-picker", {
        attrs: {
          type: _vm.orgType,
          show: _vm.showOrgSelect,
          selected: _vm.users
        },
        on: { close: _vm.closeSelect, selectOver: _vm.selected }
      }),
      _c("ConditionGroupDialog", {
        attrs: {
          isShow: _vm.isShowGroupDialog,
          dialogData: _vm.dialogDataList
        },
        on: { addConditionData: _vm.addCondition }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }