var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bpm-container" },
    [
      _c("header", [
        _c(
          "div",
          { staticClass: "step" },
          _vm._l(_vm.stepList, function(item) {
            return _c(
              "div",
              {
                key: item.code,
                staticClass: "step_item",
                class: _vm.stepActive == item.code ? "active" : "",
                on: {
                  click: function($event) {
                    return _vm.chooseStep(item.code)
                  }
                }
              },
              [_vm._v(" " + _vm._s(item.name) + " ")]
            )
          }),
          0
        ),
        _c("div", { staticClass: "btns" }, [
          _c("div", { on: { click: _vm.goBack } }, [_vm._v("取消")]),
          _vm.saveBtn
            ? _c(
                "div",
                {
                  staticStyle: { color: "#fff", "background-color": "#5669ec" },
                  on: {
                    click: function($event) {
                      return _vm.publish("ruleForm", 1)
                    }
                  }
                },
                [_vm._v("保存 ")]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticStyle: {
                "margin-right": "0",
                color: "#fff",
                "background-color": "#5669ec"
              },
              on: {
                click: function($event) {
                  return _vm.publish("ruleForm", 0)
                }
              }
            },
            [_vm._v("发布 ")]
          )
        ])
      ]),
      _c("main", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.stepActive == 1,
                expression: "stepActive == 1"
              }
            ],
            staticClass: "info_form"
          },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-ruleForm",
                staticStyle: { width: "492px" },
                attrs: {
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-width": "100px",
                  "label-position": "top"
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "流程名称", prop: "name" } },
                  [
                    _c("el-input", {
                      on: { input: _vm.inputName },
                      model: {
                        value: _vm.ruleForm.name,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "name", $$v)
                        },
                        expression: "ruleForm.name"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false"
                      }
                    ],
                    attrs: { label: "流程标识", prop: "tag" }
                  },
                  [
                    _c("el-input", {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.ruleForm.tag,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "tag", $$v)
                        },
                        expression: "ruleForm.tag"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticStyle: { "margin-bottom": "0!important" },
                    attrs: { label: "图标" }
                  },
                  [
                    _c("upload", {
                      attrs: { icon: _vm.ruleForm.icon },
                      on: { geturl: _vm.geturl }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticStyle: { display: "block" },
                    attrs: { label: "发起范围", prop: "scope" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "saveBtn", on: { click: _vm.openRange } },
                      [_vm._v("请选择")]
                    )
                  ]
                ),
                _vm.chooseList.length
                  ? _c(
                      "div",
                      { staticClass: "choose_list" },
                      _vm._l(_vm.chooseList, function(item, index) {
                        return _c(
                          "el-tag",
                          {
                            key: index,
                            staticClass: "choose_item",
                            staticStyle: {
                              "margin-right": "4px",
                              "margin-bottom": "4px"
                            }
                          },
                          [_vm._v(" " + _vm._s(item.name) + " ")]
                        )
                      }),
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  { attrs: { label: "流程描述" } },
                  [
                    _c("el-input", {
                      attrs: { type: "textarea" },
                      model: {
                        value: _vm.ruleForm.description,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "description", $$v)
                        },
                        expression: "ruleForm.description"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "表单说明" } },
                  [
                    _c("el-input", {
                      attrs: { type: "textarea", rows: 4 },
                      model: {
                        value: _vm.remark,
                        callback: function($$v) {
                          _vm.remark = $$v
                        },
                        expression: "remark"
                      }
                    }),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          color: "rgb(96, 98, 102)",
                          "font-size": "12px"
                        }
                      },
                      [_vm._v("编辑内容后，表单说明会展示于发起表单顶部")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("initiatingRange", {
              ref: "initiatingRange",
              attrs: { selectedList: _vm.chooseList },
              on: { confirm: _vm.getChooseList }
            })
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.stepActive == 2,
                expression: "stepActive == 2"
              }
            ],
            staticClass: "bpm"
          },
          [
            _c(
              "div",
              { staticClass: "form_box" },
              [
                _c("formEditor", {
                  ref: "formEditor",
                  attrs: {
                    checkList: _vm.checkList,
                    formInfo: this.ruleForm.form,
                    "bpmn-modeler": _vm.modeler
                  },
                  on: { changeForm: _vm.changeForm, changeXml: _vm.changeXml }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.stepActive == 3,
                expression: "stepActive == 3"
              }
            ],
            staticClass: "bpm"
          },
          [
            _c(
              "div",
              { staticClass: "bpm_box" },
              [
                _vm.xmlString !== undefined && _vm.model.key
                  ? _c(
                      "my-process-designer",
                      _vm._b(
                        {
                          key: "designer-" + _vm.reloadIndex,
                          ref: "processDesigner",
                          attrs: {
                            formId: 11,
                            processId: _vm.model.key,
                            processName: _vm.model.name,
                            keyboard: ""
                          },
                          on: {
                            "init-finished": _vm.initModeler,
                            change: _vm.changeBpm,
                            save: _vm.getbpm
                          },
                          model: {
                            value: _vm.xmlString,
                            callback: function($$v) {
                              _vm.xmlString = $$v
                            },
                            expression: "xmlString"
                          }
                        },
                        "my-process-designer",
                        _vm.controlForm,
                        false
                      )
                    )
                  : _vm._e(),
                _vm.panelFlag
                  ? _c("my-properties-panel", {
                      key: "penal-" + _vm.reloadIndex,
                      staticClass: "process-panel",
                      attrs: {
                        "bpmn-modeler": _vm.modeler,
                        prefix: _vm.controlForm.prefix,
                        model: _vm.model
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.stepActive == 4,
                expression: "stepActive == 4"
              }
            ]
          },
          [_c("advancedSetup", { ref: "advancedSetup" })],
          1
        )
      ]),
      _vm.PublishDialog
        ? _c(
            "Customdialog",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              attrs: {
                dialogWidth: "300px",
                title: "提示",
                dialogHeight: "140px",
                isFooter: true,
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "#ffffffcf",
                "element-loading-text":
                  _vm.tetype == 0 ? "正在发布" : "正在保存"
              },
              on: {
                closeDialog: _vm.clickPublish,
                submitDialog: _vm.submitPublish
              }
            },
            [
              _c("template", { slot: "dialogMain" }, [
                !_vm.loading
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.tetype == 0 ? "确认发布吗？" : "确认保存吗？"
                          ) +
                          " "
                      )
                    ])
                  : _vm._e()
              ])
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }