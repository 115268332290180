var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Backstage" },
    [
      _c("div", { staticClass: "leftcard" }, [
        _vm._m(0),
        _c(
          "div",
          { staticStyle: { "margin-top": "16px", "margin-left": "12px" } },
          [
            _c(
              "div",
              {
                staticClass: "leftBtn",
                on: {
                  click: function($event) {
                    return _vm.switchRouter(1)
                  }
                }
              },
              [_vm._v("用户组管理")]
            ),
            _c(
              "div",
              {
                staticClass: "leftBtn activeLeftBtn",
                on: {
                  click: function($event) {
                    return _vm.switchRouter(2)
                  }
                }
              },
              [_vm._v("流程管理")]
            )
          ]
        )
      ]),
      _c("div", { staticClass: "middlecard" }, [
        _c("div", { staticClass: "midTop" }, [
          _c("div", { staticStyle: { "margin-right": "auto" } }, [
            _vm._v("流程分组")
          ]),
          _vm.isAdmin
            ? _c(
                "div",
                {
                  staticStyle: {
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                    "margin-right": "8px",
                    border: "1px solid #f3f4f6",
                    "border-radius": "50%",
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "center"
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/Group_109.png"),
                      alt: "",
                      width: "12px",
                      height: "12px"
                    },
                    on: { click: _vm.sortGroup }
                  })
                ]
              )
            : _vm._e(),
          _c("img", {
            staticStyle: { cursor: "pointer" },
            attrs: {
              src: require("@/assets/Group_102.png"),
              alt: "",
              width: "20px",
              height: "20px"
            },
            on: { click: _vm.addGroupInput }
          })
        ]),
        _vm.draggableBtn
          ? _c(
              "div",
              { staticClass: "midList" },
              [
                _vm._l(_vm.groupList, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      class:
                        !_vm.visible && _vm.midId == item.id
                          ? "activeVisibleItem"
                          : _vm.midId == item.id
                          ? "midListItem activeMidItem"
                          : "midListItem",
                      on: {
                        click: function($event) {
                          return _vm.handleClick(item)
                        }
                      }
                    },
                    [
                      _vm.visible || _vm.midId !== item.id
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                width: "98px",
                                "white-space": "nowrap",
                                overflow: "hidden",
                                "text-overflow": "ellipsis",
                                "text-align": "left"
                              }
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        : _vm._e(),
                      _vm.midId == item.id && !_vm.visible
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                width: "98px",
                                "white-space": "nowrap",
                                overflow: "hidden",
                                "text-overflow": "ellipsis",
                                "text-align": "left"
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.name,
                                    expression: "item.name"
                                  }
                                ],
                                ref: "myInput",
                                refInFor: true,
                                attrs: { type: "text", autofocus: "" },
                                domProps: { value: item.name },
                                on: {
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return (function(e) {
                                      return _vm.inputKeyEnter(e, item)
                                    })($event)
                                  },
                                  blur: function($event) {
                                    return _vm.inputKeyEnter("", item)
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(item, "name", $event.target.value)
                                  }
                                }
                              })
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "btns",
                          staticStyle: { height: "12px" }
                        },
                        [
                          _vm.visible
                            ? _c("img", {
                                staticStyle: { "margin-right": "6px" },
                                attrs: {
                                  src: require("@/assets/pen.png"),
                                  alt: "",
                                  width: "12px",
                                  height: "12px"
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.editFlowGroup(item)
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm.visible
                            ? _c("img", {
                                attrs: {
                                  src: require("@/assets/times.png"),
                                  alt: "",
                                  width: "12px",
                                  height: "12px"
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.deleteFlowDialog(item.id, 0)
                                  }
                                }
                              })
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                }),
                _vm.createdGroup
                  ? _c("div", { staticClass: "activeVisibleItem" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "98px",
                            "white-space": "nowrap",
                            overflow: "hidden",
                            "text-overflow": "ellipsis",
                            "text-align": "left"
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.addname,
                                expression: "addname"
                              }
                            ],
                            ref: "myAddInput",
                            attrs: { type: "text", autofocus: "" },
                            domProps: { value: _vm.addname },
                            on: {
                              keydown: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return (function(e) {
                                  return _vm.createKeyEnter(e)
                                })($event)
                              },
                              blur: function($event) {
                                return _vm.createKeyEnter(2)
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.addname = $event.target.value
                              }
                            }
                          })
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "btns",
                          staticStyle: { height: "12px" }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/times.png"),
                              alt: "",
                              width: "12px",
                              height: "12px"
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.deleteAddInput()
                              }
                            }
                          })
                        ]
                      )
                    ])
                  : _vm._e()
              ],
              2
            )
          : _c(
              "div",
              { staticClass: "midList" },
              [
                _c(
                  "draggable",
                  {
                    attrs: {
                      list: _vm.groupList,
                      animation: 340,
                      "item-key": "id",
                      handle: ".midListItem1"
                    }
                  },
                  _vm._l(_vm.groupList, function(item, index) {
                    return _c("div", { staticClass: "midListItem1" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "98px",
                            "white-space": "nowrap",
                            overflow: "hidden",
                            "text-overflow": "ellipsis",
                            "text-align": "left"
                          }
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    ])
                  }),
                  0
                )
              ],
              1
            )
      ]),
      _c("div", { staticClass: "maincard" }, [
        _c("div", { staticClass: "topL" }, [
          _c("div", { staticClass: "inputDiv" }, [
            _c("i", { staticClass: "el-icon-search" }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchValue,
                  expression: "searchValue"
                }
              ],
              attrs: { type: "text", placeholder: "搜索流程名称" },
              domProps: { value: _vm.searchValue },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchValue = $event.target.value
                }
              }
            })
          ]),
          _c(
            "div",
            {
              staticClass: "caogaoBtn",
              on: {
                click: function($event) {
                  return _vm.showDraft()
                }
              }
            },
            [
              _c("img", {
                staticStyle: {
                  width: "14px",
                  height: "14px",
                  "margin-left": "8px",
                  "margin-right": "2px"
                },
                attrs: { src: require("@/assets/copy.png"), alt: "" }
              }),
              _vm._v("草稿箱 ")
            ]
          ),
          _c("div", { staticClass: "addBtn", on: { click: _vm.openCreate } }, [
            _c("i", {
              staticClass: "el-icon-plus ml-2",
              staticStyle: { "margin-right": "2px" }
            }),
            _vm._v("创建流程")
          ])
        ]),
        _c("div", { staticClass: "bootn" }, [
          _c(
            "div",
            { staticClass: "culm" },
            [
              _c(
                "el-row",
                { staticStyle: { flex: "1" } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "cumItem",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "space-around"
                      },
                      attrs: { span: 10 }
                    },
                    [
                      _vm.isAdmin
                        ? _c("img", {
                            staticStyle: {
                              "margin-top": "-2px",
                              cursor: "pointer"
                            },
                            attrs: {
                              src: require("@/assets/Group_1091.png"),
                              alt: "",
                              width: "13px",
                              height: "13px"
                            },
                            on: { click: _vm.dragFlowFn }
                          })
                        : _vm._e(),
                      _vm._v(" 流程名称 "),
                      _c("img", {
                        staticStyle: { opacity: "0" },
                        attrs: {
                          src: require("@/assets/Group_1091.png"),
                          alt: "",
                          width: "13px",
                          height: "13px"
                        }
                      })
                    ]
                  ),
                  _vm.dragFlow
                    ? _c(
                        "el-col",
                        { staticClass: "cumItem", attrs: { span: 11 } },
                        [_vm._v("发起范围")]
                      )
                    : _vm._e(),
                  _vm.dragFlow
                    ? _c(
                        "el-col",
                        { staticClass: "cumItem", attrs: { span: 3 } },
                        [_vm._v("状态")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "cumItem",
                  staticStyle: { "flex-shrink": "0", width: "90px" }
                },
                [_vm._v("操作")]
              )
            ],
            1
          ),
          _vm.dragFlow
            ? _c(
                "div",
                { staticClass: "clist" },
                _vm._l(_vm.groupFlowList, function(item, index) {
                  return _c("div", { attrs: { index: item.id } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          position: "relative"
                        }
                      },
                      [
                        _c(
                          "el-row",
                          { staticClass: "cl" },
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "cumItem flex items-center",
                                attrs: { span: 10 }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "border-radius": "50%",
                                      width: "28px",
                                      height: "28px",
                                      margin: "0 8px 0 12px",
                                      "flex-shrink": "0",
                                      overflow: "hidden"
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%"
                                      },
                                      attrs: {
                                        src:
                                          item.icon ==
                                          "https://cdn.fengwork.com/sosee_oa/20240119icon.png"
                                            ? "https://cdn.fengwork.com/workbench/Group_1000005531.png"
                                            : item.icon,
                                        alt: ""
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "clText",
                                    staticStyle: {
                                      width: "calc(100% - 48px)",
                                      "word-break": "break-all",
                                      "text-align": "left"
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(item.name))]
                                )
                              ]
                            ),
                            _c("el-col", { attrs: { span: 11 } }, [
                              _c(
                                "span",
                                {
                                  staticClass: "clTextspan",
                                  staticStyle: {
                                    width: "100%",
                                    "word-break": "break-all"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      !item.num
                                        ? item.scopeList || ""
                                        : item.scopeList + "..."
                                    )
                                  )
                                ]
                              )
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 3 } },
                              [
                                _c("el-switch", {
                                  attrs: {
                                    "active-value": 0,
                                    "inactive-value": 1,
                                    "active-color": "#5669ec",
                                    "inactive-color": "#5669ec1a"
                                  },
                                  on: {
                                    change: function(e) {
                                      return _vm.changeSwitch(e, item.id)
                                    }
                                  },
                                  model: {
                                    value: item.isVisible,
                                    callback: function($$v) {
                                      _vm.$set(item, "isVisible", $$v)
                                    },
                                    expression: "item.isVisible"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "flex items-center",
                            staticStyle: {
                              "flex-shrink": "0",
                              width: "90px",
                              "font-size": "13px",
                              "justify-content": "space-around"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "imgIcon",
                                staticStyle: {
                                  cursor: "pointer",
                                  width: "16px",
                                  height: "16px"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.editGrouping(item.id)
                                  }
                                }
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%"
                                  },
                                  attrs: {
                                    src: require("@/assets/pen-line.png"),
                                    alt: "",
                                    title: "编辑"
                                  }
                                })
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "imgIcon",
                                staticStyle: {
                                  cursor: "pointer",
                                  width: "16px",
                                  height: "16px"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleRemove(item)
                                  }
                                }
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%"
                                  },
                                  attrs: {
                                    src: require("@/assets/folder-export.png"),
                                    alt: "",
                                    title: "移动至"
                                  }
                                })
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "imgIcon",
                                staticStyle: {
                                  cursor: "pointer",
                                  width: "16px",
                                  height: "16px"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteFlowDialog(item.id, 1)
                                  }
                                }
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%"
                                  },
                                  attrs: {
                                    src: require("@/assets/trash.png"),
                                    alt: "",
                                    title: "删除"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "line" })
                  ])
                }),
                0
              )
            : _c(
                "div",
                { staticClass: "clist" },
                [
                  _c(
                    "draggable",
                    {
                      attrs: {
                        list: _vm.groupFlowList,
                        animation: 340,
                        "item-key": "id",
                        handle: ".clistItem"
                      }
                    },
                    _vm._l(_vm.groupFlowList, function(item, index) {
                      return _c(
                        "div",
                        { staticClass: "clistItem", attrs: { index: item.id } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                position: "relative"
                              }
                            },
                            [
                              _c(
                                "el-row",
                                { staticClass: "cl" },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "cumItem flex items-center",
                                      attrs: { span: 24 }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "border-radius": "50%",
                                            width: "28px",
                                            height: "28px",
                                            margin: "0 8px 0 12px",
                                            "flex-shrink": "0",
                                            overflow: "hidden"
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              width: "100%",
                                              height: "100%"
                                            },
                                            attrs: {
                                              src:
                                                item.icon ==
                                                "https://cdn.fengwork.com/sosee_oa/20240119icon.png"
                                                  ? "https://cdn.fengwork.com/workbench/Group_1000005531.png"
                                                  : item.icon,
                                              alt: ""
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "clText",
                                          staticStyle: {
                                            width: "calc(100% - 48px)",
                                            "word-break": "break-all",
                                            "text-align": "left"
                                          }
                                        },
                                        [_vm._v(" " + _vm._s(item.name))]
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "flex items-center",
                                  staticStyle: {
                                    "flex-shrink": "0",
                                    width: "90px",
                                    "font-size": "13px",
                                    "justify-content": "space-around"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "imgIcon",
                                      staticStyle: {
                                        width: "16px",
                                        height: "16px"
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%"
                                        },
                                        attrs: {
                                          src: require("@/assets/Group_11011.png"),
                                          alt: "",
                                          title: "拖拽"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "line" })
                        ]
                      )
                    }),
                    0
                  )
                ],
                1
              )
        ])
      ]),
      _c(
        "transition",
        { attrs: { name: "el-fade-in" } },
        [
          _vm.draftDialog
            ? _c(
                "Customdialog",
                {
                  attrs: {
                    dialogWidth: "60%",
                    title: "草稿箱",
                    dialogHeight: "60%"
                  },
                  on: { closeDialog: _vm.clickDraft }
                },
                [
                  _c(
                    "template",
                    { slot: "dialogMain" },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: {
                            "text-align": "left",
                            "font-size": "14px",
                            color: "#848484",
                            "border-bottom": "1px solid #f3f4f6"
                          }
                        },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                "padding-left": "24px",
                                "padding-bottom": "11px"
                              },
                              attrs: { span: 10 }
                            },
                            [_vm._v("流程名")]
                          ),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _vm._v("更新时间")
                          ]),
                          _c(
                            "el-col",
                            {
                              staticStyle: { "text-align": "center" },
                              attrs: { span: 6 }
                            },
                            [_vm._v("操作")]
                          )
                        ],
                        1
                      ),
                      _vm._l(_vm.draftFlowList, function(item, index) {
                        return _c(
                          "div",
                          { key: item.id },
                          [
                            _c(
                              "el-row",
                              {
                                staticStyle: {
                                  "text-align": "left",
                                  "font-size": "13px",
                                  color: "#1b1b1b",
                                  height: "46px",
                                  "line-height": "46px",
                                  "border-bottom": "1px solid #f3f4f6"
                                }
                              },
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { "padding-left": "24px" },
                                    attrs: { span: 10 }
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                ),
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm
                                        .$moment(item.updateTime)
                                        .format("YYYY-MM-DD hh:mm:ss")
                                    )
                                  )
                                ]),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "space-around",
                                      "align-items": "center"
                                    },
                                    attrs: { span: 6 }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                          "justify-content": "center",
                                          cursor: "pointer"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.editGrouping(item.id)
                                          }
                                        }
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/pen2.png"),
                                            alt: "",
                                            width: "14px",
                                            height: "14px"
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-left": "4px",
                                              color: "#5669ec"
                                            }
                                          },
                                          [_vm._v("编辑")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                          "justify-content": "center",
                                          cursor: "pointer"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteFlow(item.id)
                                          }
                                        }
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/trash.png"),
                                            alt: "",
                                            width: "14px",
                                            height: "14px"
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-left": "4px",
                                              color: "#f83e45"
                                            }
                                          },
                                          [_vm._v("删除")]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      _vm.draftFlowList.length == 0
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  color: "#848484",
                                  padding: "24px 0",
                                  "font-size": "13px"
                                }
                              },
                              [_vm._v("暂无数据")]
                            )
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                ],
                2
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "el-fade-in" } },
        [
          _vm.smallDialog
            ? _c(
                "Customdialog",
                {
                  attrs: {
                    dialogWidth: "320px",
                    title: "提示",
                    dialogHeight: "140px",
                    isFooter: true
                  },
                  on: {
                    closeDialog: function($event) {
                      _vm.smallDialog = false
                    },
                    submitDialog: _vm.deleteFlowIndex
                  }
                },
                [
                  _c("template", { slot: "dialogMain" }, [
                    _vm._v(" 是否确认删除? ")
                  ])
                ],
                2
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "el-fade-in" } },
        [
          _vm.removeDialog
            ? _c(
                "Customdialog",
                {
                  attrs: {
                    dialogWidth: "30%",
                    title: "移动至",
                    dialogHeight: "60%",
                    isFooter: true
                  },
                  on: {
                    closeDialog: _vm.clickRemove,
                    submitDialog: _vm.submitRemove
                  }
                },
                [
                  _c("template", { slot: "dialogMain" }, [
                    _c(
                      "div",
                      { staticClass: "gropList" },
                      _vm._l(_vm.removeGroupList, function(item, index) {
                        return _c(
                          "div",
                          {
                            class:
                              _vm.acItemId == item.id
                                ? "acGroupItem"
                                : "groupItem",
                            on: {
                              click: function($event) {
                                return _vm.checkGroup(item)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(item.name) + " ")]
                        )
                      }),
                      0
                    )
                  ])
                ],
                2
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "el-fade-in" } },
        [
          _vm.createFlowVisible
            ? _c(
                "Customdialog",
                {
                  attrs: {
                    dialogWidth: "300px",
                    title: "创建流程",
                    dialogHeight: "200px",
                    isFooter: false
                  },
                  on: { closeDialog: _vm.clickCreateVisible }
                },
                [
                  _c("template", { slot: "dialogMain" }, [
                    _c("div", { staticStyle: { padding: "0 16px" } }, [
                      _c(
                        "div",
                        {
                          staticClass: "createBtn",
                          staticStyle: { "margin-bottom": "8px" },
                          on: { click: _vm.createNewFlow }
                        },
                        [_vm._v(" 创建新流程 ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "createBtn",
                          on: { click: _vm.createOldFlow }
                        },
                        [_vm._v(" 选择已有流程 ")]
                      )
                    ])
                  ])
                ],
                2
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "el-fade-in" } },
        [
          _vm.oldVisible
            ? _c(
                "Customdialog",
                {
                  attrs: {
                    dialogWidth: "300px",
                    title: "创建流程",
                    dialogHeight: "500px",
                    isFooter: false
                  },
                  on: {
                    closeDialog: _vm.clickCreateVisible,
                    submitDialog: _vm.submitRemove
                  }
                },
                [
                  _c("template", { slot: "dialogMain" }, [
                    _c("div", {}, [
                      _c(
                        "div",
                        {
                          staticClass: "mb16",
                          staticStyle: { padding: "0 20px" }
                        },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                placeholder: "请输入流程名称",
                                clearable: ""
                              },
                              on: { input: _vm.getDepUserList },
                              model: {
                                value: _vm.flowName,
                                callback: function($$v) {
                                  _vm.flowName = $$v
                                },
                                expression: "flowName"
                              }
                            },
                            [_vm._v(" >")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "forms_list" },
                        _vm._l(_vm.flowList, function(item) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              staticClass: "forms_item",
                              on: {
                                click: function($event) {
                                  return _vm.selectFlow(item)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        0
                      )
                    ])
                  ])
                ],
                2
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex items-center",
        staticStyle: { "margin-left": "20px", "margin-top": "17px" }
      },
      [
        _c("img", {
          attrs: {
            src: "https://cdn.fengwork.com/workbench/Group_101.png",
            alt: "",
            width: "22px",
            height: "24px"
          }
        }),
        _c(
          "span",
          {
            staticStyle: {
              "margin-left": "5px",
              "font-size": "13px",
              color: "#1b1b1b",
              "font-weight": "550"
            }
          },
          [_vm._v("管理后台")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }