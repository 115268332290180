var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "boxbox", attrs: { id: "app" } },
    [
      _c(
        "keep-alive",
        [
          _vm.refresh
            ? _c("router-view", { key: _vm.$route.fullPath + _vm.key })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }