<template>
  <div>
    <el-upload
      class="avatar-uploader"
      :action="uploadAction"
      :show-file-list="false"
      :data="uploadData"
      :on-success="handleSuccess"
      :before-upload="beforeAvatarUpload"
      accept="image/*"
    >
      <img v-if="imageUrl.url" :src="imageUrl.url" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </div>
</template>

<script>
import axios from "axios";
import { uploadURLFromRegionCode, init, config } from "@/utils/qiniuUploader";
export default {
  props: ["icon"],
  data() {
    return {
      maxSize: 100 * 1024 * 1024, // 最大文件大小（10MB）
      uptoken: "", //七牛云token
      uploadAction: "", //图片上传
      uploadData: {}, //上传参数
      fileList: [], //图片上传列表
      uploadUrl: "", // 上传地址
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      imageUrl: "",
    };
  },
  watch: {
    icon(newVal) {
      this.imageUrl = {
        url: newVal,
      };
    },
  },
  created() {
    this.getuptoken();
  },
  methods: {
    beforeAvatarUpload(file) {
      var img = file.name.substring(file.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!suffix && !suffix2 && !suffix3) {
        this.$message.error("只能上传图片！");
        return false;
      }

      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
        return false;
      }

      // this.uploadData.key = file.name;
    },
    handleDownload(file) {
      //   console.log(file);
    },
    // 上传
    handleSuccess(res, file, fileList) {
      let dataObject = res;
      this.fileList = fileList;
      let fileURL = config.qiniuBucketURLPrefix + "/" + dataObject.key;
      const data = {};
      data.url = fileURL;
      data.name = this.fileList[0].name;
      this.imageUrl = data;
      this.$emit("geturl", data);
    },
    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        if (file.size <= this.maxSize) {
          resolve(file);
        } else {
          this.$message.error("文件大小超过限制100M");
          reject();
        }
      });
    },
    // 获取七牛云token
    getuptoken() {
      // console.log(123);
      axios
        .get("https://api.heytopo.com/appapi/?service=Upload.getCosInfo")
        .then((res) => {
          this.uptoken = res.data.data.info[0].qiniuInfo.qiniuToken;
          this.initQiniu(this.uptoken);
        });
    },

    initQiniu(uptoken) {
      var options = {
        // bucket所在区域，这里是华北区。ECN, SCN, NCN, NA, ASG，分别对应七牛云的：华东，华南，华北，北美，新加坡 5 个区域
        region: "SCN",
        uptoken: this.uptoken,
        uptokenURL: "https://[yourserver.com]/api/uptoken",
        uptokenFunc: function () {},
        domain: "https://source.fungsong.com",
        shouldUseQiniuFileName: false,
      };
      // 将七牛云相关配置初始化进本sdk
      init(options);
      this.uploadAction = uploadURLFromRegionCode(config.qiniuRegion);
      this.uploadUrl = uploadURLFromRegionCode(config.qiniuRegion);
      this.uploadData = {
        token: uptoken,
      };
    },
  },
};
</script>

<style lang="scss">
.avatar-uploader .el-upload {
    border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-color: #f5f6f9;
}
.avatar-uploader .el-upload:hover {
  border-color: #5669ec;
}
.avatar-uploader-icon {
  // font-size: 28px;
  color: #5669ec;
  width: 88px;
  height: 88px;
  line-height: 88px!important;
  text-align: center;
}
.avatar {
  width: 88px;
  height: 88px;
  display: block;
}
</style>
