<template>
  <div>
    <div
      v-for="(group, index) in selectedNode.props.groups"
      :key="index + '_g'"
      class="group"
    >
      <div class="group-header">
        <span class="group-name">条件组 {{ groupNames[index] }}</span>

        <div class="group-operation">
          <span
            style="
              color: #1890ff;
              margin-right: 15px;
              cursor: pointer;
              font-size: small;
            "
            @click="choose(group, index)"
            >添加条件字段</span
          >
          <span
            style="color: red; cursor: pointer; font-size: small"
            @click="delGroup(index)"
            >删除</span
          >
        </div>
      </div>
      <div class="group-content">
        <p v-if="group.conditions.length === 0">
          点击右上角为本条件组添加条件 ☝
        </p>
        <div v-else>
          <el-form ref="condition-form" label-width="100px">
            <!--构建表达式-->
            <el-form-item
              :label="condition.title"
              v-for="(condition, cindex) in group.conditions"
              :key="condition.id + '_' + cindex"
              required
            >
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <span v-if="condition.valueType === ValueType.string">
                  <el-select
                    size="mini"
                    placeholder="判断符"
                    style="width: 90px"
                    v-model="condition.compare"
                  >
                    <el-option label="不包含" value="NOIN"></el-option>
                    <el-option label="包含" value="IN"></el-option>
                  </el-select>
                  <span style="margin-left: 10px">
                    <span>
                      <el-input
                        style="width: 280px"
                        placeholder="请输入值"
                        size="mini"
                        v-model="condition.value[0]"
                      />
                    </span>
                  </span>
                </span>
                <span v-else-if="condition.valueType === ValueType.number">
                  <el-select
                    size="mini"
                    placeholder="判断符"
                    style="width: 180px"
                    v-model="condition.compare"
                  >
                    <el-option
                      :label="exp.label"
                      :value="exp.value"
                      :key="exp.value"
                      v-for="exp in explains"
                    ></el-option>
                  </el-select>
                  <span style="margin-left: 10px">
                    <el-input
                      style="width: 240px"
                      v-if="conditionValType(condition.compare) === 0"
                      size="mini"
                      placeholder="输入比较值"
                      type="number"
                      v-model="condition.value[0]"
                    />
                    <el-select
                      style="width: 240px"
                      multiple
                      filterable
                      allow-create
                      v-else-if="conditionValType(condition.compare) === 1"
                      size="mini"
                      v-model="condition.value"
                      placeholder="输入可能包含的值"
                    ></el-select>
                    <span v-else>
                      <el-input
                        style="width: 120px"
                        size="mini"
                        type="number"
                        placeholder="输入比较值"
                        v-model="condition.value[0]"
                      />
                      <span>
                        ~
                        <el-input
                          size="mini"
                          style="width: 120px"
                          type="number"
                          placeholder="输入比较值"
                          v-model="condition.value[1]"
                        />
                      </span>
                    </span>
                  </span>
                </span>
                <span v-else-if="condition.valueType === ValueType.user">
                  <el-select
                    v-model="condition.compare"
                    size="mini"
                    style="width: 90px"
                  >
                    <el-option label="不包含" value="NOIN"></el-option>
                    <el-option label="包含" value="IN"></el-option>
                  </el-select>
                  <el-button
                    style="margin-left: 10px"
                    size="mini"
                    icon="el-icon-plus"
                    type="primary"
                    @click="selectUser(condition)"
                    round
                  >
                    <span v-if="condition.selectType == 'selectDept'">
                      部门
                    </span>
                    <span v-else-if="condition.selectType == 'selectUser'">
                      人员
                    </span>
                  </el-button>
                  <div style="margin: 10px">
                    <el-tag
                      class="org-item"
                      style="margin: 5px"
                      :type="org.type === 'dept' ? '' : 'info'"
                      v-for="(org, index) in condition.value"
                      :key="index + '_org'"
                      closable
                      size="mini"
                      @close="removeOrgItem(condition.value, index)"
                    >
                      {{ org.name }}
                    </el-tag>
                  </div>
                </span>
                <span v-else-if="condition.valueType === ValueType.dept">
                  <span class="item-desc" style="margin-right: 20px"
                    >为某部门 / 某部门下的部门</span
                  >
                  <el-button
                    size="mini"
                    icon="el-icon-plus"
                    type="primary"
                    @click="selectUser(condition)"
                    round
                    >选择部门</el-button
                  >
                  <div style="margin: 10px">
                    <el-tag
                      class="org-item"
                      style="margin: 5px"
                      :type="org.type === 'dept' ? '' : 'info'"
                      v-for="(org, index) in condition.value"
                      :key="index + '_org'"
                      closable
                      size="mini"
                      @close="removeOrgItem(condition.value, index)"
                    >
                      {{ org.name }}
                    </el-tag>
                  </div>
                </span>
                <span v-else-if="condition.valueType === ValueType.select">
                  <el-select
                    size="mini"
                    v-model="condition.value"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in radioList"
                      :key="index + item.label"
                      :label="item.label"
                      :value="item.label"
                      :type="item.type"
                    >
                    </el-option>
                  </el-select>
                </span>
                <span
                  v-else-if="condition.valueType === ValueType.radio"
                  style="display: flex; align-items: center"
                >
                  <el-select
                    size="mini"
                    placeholder="判断符"
                    style="min-width: 90px"
                    v-model="condition.compare"
                  >
                    <el-option label="不包含" value="NOIN"></el-option>
                    <el-option label="包含" value="IN"></el-option>
                  </el-select>
                  <el-checkbox-group
                    style="margin-left: 10px"
                    v-model="condition.value"
                  >
                    <el-checkbox
                      v-for="citem in condition.list"
                      :key="citem.iid"
                      :label="citem.ivalue"
                      :value="citem.idictionaryid"
                    >
                      {{ citem.stext }}
                    </el-checkbox>
                  </el-checkbox-group>
                </span>
                <i class="el-icon-delete" @click="deleted(cindex, group)"></i>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>

    <org-picker
      :type="orgType"
      :show="showOrgSelect"
      @close="closeSelect"
      :selected="users"
      @selectOver="selected"
    ></org-picker>

    <ConditionGroupDialog
      :isShow="isShowGroupDialog"
      :dialogData="dialogDataList"
      @addConditionData="addCondition"
    ></ConditionGroupDialog>
  </div>
</template>

<script>
import orgPicker from "@/components/organizationPicker/organizationPicker";
import { ValueType } from "@/components/process/comon/ComponetsConfigExport";
import ConditionGroupDialog from "@/components/organizationPicker/ConditionGroupDialog";
// import { getEntityVariflowList } from "@/api/process/process";
export default {
  name: "ConditionGroupItemConfig",
  components: { orgPicker, ConditionGroupDialog },
  data() {
    return {
      ValueType,
      users: [],
      orgType: "dept",
      showOrgSelect: false,
      checkList: [],
      //groupConditions: [],
      groupNames: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"],
      supportTypes: [
        ValueType.number,
        ValueType.string,
        ValueType.date,
        ValueType.dept,
        ValueType.user,
        ValueType.select,
      ],
      explains: [
        { label: "等于", value: "==" },
        { label: "大于", value: ">" },
        { label: "大于等于", value: ">=" },
        { label: "小于", value: "<" },
        { label: "小于等于", value: "<=" },
        // {label: '包含在', value: 'IN'},
        { label: "介于(最小 < 值 < 最大)", value: "B" },
        { label: "介于(最小 ≤  值 < 最大)", value: "AB" },
        { label: "介于(最小 <  值 ≤ 最大)", value: "BA" },
        { label: "介于(最小 ≤  值 ≤ 最大)", value: "ABA" },
      ],
      radioList: [
        {
          type: "deposit",
          label: "定金",
        },
        {
          type: "design",
          label: "设计费",
        },
        {
          type: "engineering",
          label: "工程费",
        },
        {
          type: "fullPayment",
          label: "全款",
        },
        {
          type: "mainMaterialCost",
          label: "主材费",
        },
      ],
      isShowGroupDialog: false,
      dialogDataList: [],
      groupIndex: 0,
    };
  },
  computed: {
    selectedNode() {
      return this.$store.state.process.selectedNode;
    },
    select() {
      return this.selectedNode.props.assignedUser || [];
    },
    groups() {
      return this.$store.state.process.selectedNode.props.groups;
    },
  },
  mounted() {
    console.log(this.selectedNode.props.groups);
    console.log(this.selectedNode);
    console.log(this.ValueType);
  },
  methods: {
    conditionValType(type) {
      switch (type) {
        case "==":
        case ">":
        case ">=":
        case "<":
        case "<=":
          return 0;
        case "IN":
          return 1;
        default:
          return 2;
      }
    },
    closeSelect() {
      this.showOrgSelect = false;
    },
    selectUser(condition) {
      // console.log(value);
      if (condition.selectType == "selectUser") {
        this.orgType = "user";
      } else if (condition.selectType == "selectDept") {
        this.orgType = "dept";
      }
      this.users = condition.value;
      this.showOrgSelect = true;
    },
    selected(select) {
      this.showOrgSelect = false;
      select.forEach((val) => {
        if (this.users.length > 0) {
          let value = this.users.find((item) => {
            return item.id === val.id;
          });
          if (!value) {
            this.users.push(val);
          }
        } else {
          console.log(1);
          this.users.push(val);
        }
      });
    },
    removeOrgItem(values, index) {
      values.splice(index, 1);
    },
    delGroup(index) {
      this.$confirm("您确定删除该条件组?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.selectedNode.props.groups.splice(index, 1);
      });
    },
    addCondition(data) {
      console.log(data);
      // this.groups[this.groupIndex].conditions.length = 0;
      let conditions = this.groups[this.groupIndex].conditions,
        conditionList = [],
        condition = {},
        clIndex;
      data.forEach((row, index) => {
        clIndex = conditions.findIndex((item) => item.valueKey == row.valueKey);
        if (clIndex < 0) {
          condition = { ...row };
          condition.compare = "";
          condition.value = [];
        } else {
          condition = { ...conditions[clIndex] };
        }
        conditionList.push(condition);
      });
      this.groups[this.groupIndex].conditions = conditionList;
      console.log(conditionList);
    },
    deleted(cindex, group) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          group.cids.splice(cindex, 1);
          group.conditions.splice(cindex, 1);
        })
        .catch(() => {});
    },
    //弹出选择框
    choose(group, index) {
      this.groupIndex = index;
      this.$store.commit("CHANGE_SHOW", true);
      this.getData();
    },
    getData() {
      let scode = window.sessionStorage.getItem("GROUP_CODE");
      getEntityVariflowList({
        flowCode: scode,
      }).then((res) => {
        console.log(res);
        this.dialogDataList = res.data.map((item) =>
          Object.assign(item, {
            checkedList: this.getCheckData(item),
          })
        );
        console.log(this.dialogDataList);
      });
    },
    getCheckData(data) {
      let result = [];
      const conditions = this.groups[this.groupIndex].conditions;
      if (conditions.length > 0) {
        conditions.forEach((item) => {
          data.fieldList.findIndex((fitem) => {
            if (fitem.sattributecode == item.valueKey) {
              result.push(fitem.sattributecode);
            }
          });
        });
      }

      const sattributecode = "idepartmentid";
      if (!result.some((item) => item == sattributecode)) {
        result.push(sattributecode);
      }

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.group {
  margin-bottom: 20px;
  color: #5e5e5e;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid #e3e3e3;

  .group-header {
    display: flex;
    padding: 5px 10px;
    background: #e3e3e3;
    position: relative;

    div {
      display: inline-block;
    }

    .group-name {
      font-size: small;
    }

    .group-cp {
      font-size: small;
      position: absolute;
      left: 100px;
      display: flex;
      top: 5px;
      justify-content: center;
      align-items: center;
    }

    .group-operation {
      display: flex;
      align-items: center;
      position: absolute;
      right: 10px;

      i {
        padding: 0 10px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .group-content {
    padding: 10px 10px;

    p {
      text-align: center;
      font-size: small;
    }
  }

  .condition-title {
    display: block;
    width: 100px;
  }
}
</style>
