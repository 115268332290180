var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "bottom-start",
        title: "添加流程节点",
        width: "350",
        trigger: "click"
      }
    },
    [
      _c("div", { staticClass: "node-select" }, [
        _c("div", { on: { click: _vm.addApprovalNode } }, [
          _c("i", {
            staticClass: "el-icon-s-check",
            staticStyle: { color: "rgb(255, 148, 62)" }
          }),
          _c("span", [_vm._v("审批人")])
        ])
      ]),
      !_vm.readonly
        ? _c("el-button", {
            attrs: {
              slot: "reference",
              icon: "el-icon-plus",
              type: "primary",
              size: "small",
              circle: ""
            },
            slot: "reference"
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }