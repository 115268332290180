var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("node", {
    attrs: { show: false },
    on: {
      insertNode: function(type) {
        return _vm.$emit("insertNode", type)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }