var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-top": "16px" } },
    [
      _c(
        "el-form-item",
        { attrs: { label: "到期时间" } },
        [
          _c("el-input", {
            attrs: { clearable: "" },
            on: {
              change: function($event) {
                return _vm.updateElementTask("dueDate")
              }
            },
            model: {
              value: _vm.userTaskForm.dueDate,
              callback: function($$v) {
                _vm.$set(_vm.userTaskForm, "dueDate", $$v)
              },
              expression: "userTaskForm.dueDate"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "跟踪时间" } },
        [
          _c("el-input", {
            attrs: { clearable: "" },
            on: {
              change: function($event) {
                return _vm.updateElementTask("followUpDate")
              }
            },
            model: {
              value: _vm.userTaskForm.followUpDate,
              callback: function($$v) {
                _vm.$set(_vm.userTaskForm, "followUpDate", $$v)
              },
              expression: "userTaskForm.followUpDate"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "优先级" } },
        [
          _c("el-input", {
            attrs: { clearable: "" },
            on: {
              change: function($event) {
                return _vm.updateElementTask("priority")
              }
            },
            model: {
              value: _vm.userTaskForm.priority,
              callback: function($$v) {
                _vm.$set(_vm.userTaskForm, "priority", $$v)
              },
              expression: "userTaskForm.priority"
            }
          })
        ],
        1
      ),
      _vm._v(" 友情提示：任务的分配规则，使用 "),
      _c(
        "router-link",
        { attrs: { target: "_blank", to: { path: "/bpm/manager/model" } } },
        [_c("el-link", { attrs: { type: "danger" } }, [_vm._v("流程模型")])],
        1
      ),
      _vm._v(
        " 下的【分配规则】替代，提供指定角色、部门负责人、部门成员、岗位、工作组、自定义脚本等 7 种维护的任务分配维度，更加灵活！ "
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }