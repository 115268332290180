<template>
  <div class="my-process-designer">
    <div class="my-process-designer__container">
      <div class="my-process-designer__canvas" ref="bpmn-canvas"></div>
    </div>
    <!-- <el-dialog title="预览" width="80%" :visible.sync="previewModelVisible" append-to-body destroy-on-close>
      <pre><code class="hljs" v-html="highlightedCode(previewType, previewResult)"></code></pre>
    </el-dialog> -->
  </div>
</template>

<script>
import { debounce } from 'lodash';
import BpmnModeler from "bpmn-js/lib/Modeler";
import DefaultEmptyXML from "./plugins/defaultEmpty";
// 翻译方法
import customTranslate from "./plugins/translate/customTranslate";
import translationsCN from "./plugins/translate/zh";
// 模拟流转流程
import tokenSimulation from "bpmn-js-token-simulation";
// 标签解析构建器
// import bpmnPropertiesProvider from "bpmn-js-properties-panel/lib/provider/bpmn";
// 标签解析 Moddle
import camundaModdleDescriptor from "./plugins/descriptor/camundaDescriptor.json";
import activitiModdleDescriptor from "./plugins/descriptor/activitiDescriptor.json";
import flowableModdleDescriptor from "./plugins/descriptor/flowableDescriptor.json";
// 标签解析 Extension
import camundaModdleExtension from "./plugins/extension-moddle/camunda";
import activitiModdleExtension from "./plugins/extension-moddle/activiti";
import flowableModdleExtension from "./plugins/extension-moddle/flowable";
// 引入json转换与高亮
import convert from "xml-js";

// 代码高亮插件
import hljs from "highlight.js/lib/highlight";
import "highlight.js/styles/github-gist.css";
hljs.registerLanguage("xml", require("highlight.js/lib/languages/xml"));
hljs.registerLanguage("json", require("highlight.js/lib/languages/json"));

export default {
  name: "MyProcessDesigner",
  componentName: "MyProcessDesigner",
  props: {
    value: String, // xml 字符串
    valueWatch: true, // xml 字符串的 watch 状态
    processId: String, // 流程 key 标识
    processName: String, // 流程 name 名字
    formId: Number, // 流程 form 表单编号
    translations: Object, // 自定义的翻译文件
    additionalModel: [Object, Array], // 自定义model
    moddleExtension: Object, // 自定义moddle
    onlyCustomizeAddi: {
      type: Boolean,
      default: false,
    },
    onlyCustomizeModdle: {
      type: Boolean,
      default: false,
    },
    simulation: {
      type: Boolean,
      default: true,
    },
    keyboard: {
      type: Boolean,
      default: true,
    },
    prefix: {
      type: String,
      default: "camunda",
    },
    events: {
      type: Array,
      default: () => ["element.click"],
    },
    headerButtonSize: {
      type: String,
      default: "small",
      validator: (value) =>
        ["default", "medium", "small", "mini"].indexOf(value) !== -1,
    },
    headerButtonType: {
      type: String,
      default: "primary",
      validator: (value) =>
        ["default", "primary", "success", "warning", "danger", "info"].indexOf(
          value
        ) !== -1,
    },
  },
  data() {
    return {
      defaultZoom: 1,
      previewModelVisible: false,
      simulationStatus: false,
      previewResult: "",
      previewType: "xml",
      recoverable: false,
      revocable: false,

    };
  },
  watch: {
    processName: {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          // 这里执行你需要节流的代码
          console.log(`output->3`,3)
          this.createNewDiagram(this.changedXml(this.value),2)
        }, 2000); // 设置节流延迟时间为500毫秒
      }
    }
  },
  computed: {
    additionalModules() {
      const Modules = [];
      // 仅保留用户自定义扩展模块
      if (this.onlyCustomizeAddi) {
        if (
          Object.prototype.toString.call(this.additionalModel) ===
          "[object Array]"
        ) {
          return this.additionalModel || [];
        }
        return [this.additionalModel];
      }

      // 插入用户自定义扩展模块
      if (
        Object.prototype.toString.call(this.additionalModel) ===
        "[object Array]"
      ) {
        Modules.push(...this.additionalModel);
      } else {
        this.additionalModel && Modules.push(this.additionalModel);
      }

      // 翻译模块
      const TranslateModule = {
        translate: [
          "value",
          customTranslate(this.translations || translationsCN),
        ],
      };
      Modules.push(TranslateModule);

      // 模拟流转模块
      if (this.simulation) {
        Modules.push(tokenSimulation);
      }

      // 根据需要的流程类型设置扩展元素构建模块
      // if (this.prefix === "bpmn") {
      //   Modules.push(bpmnModdleExtension);
      // }
      if (this.prefix === "camunda") {
        Modules.push(camundaModdleExtension);
      }
      if (this.prefix === "flowable") {
        Modules.push(flowableModdleExtension);
      }
      if (this.prefix === "activiti") {
        Modules.push(activitiModdleExtension);
      }

      return Modules;
    },
    moddleExtensions() {
      const Extensions = {};
      // 仅使用用户自定义模块
      if (this.onlyCustomizeModdle) {
        return this.moddleExtension || null;
      }

      // 插入用户自定义模块
      if (this.moddleExtension) {
        for (let key in this.moddleExtension) {
          Extensions[key] = this.moddleExtension[key];
        }
      }

      // 根据需要的 "流程类型" 设置 对应的解析文件
      if (this.prefix === "activiti") {
        Extensions.activiti = activitiModdleDescriptor;
      }
      if (this.prefix === "flowable") {
        Extensions.flowable = flowableModdleDescriptor;
      }
      if (this.prefix === "camunda") {
        Extensions.camunda = camundaModdleDescriptor;
      }

      return Extensions;
    },
  },
  mounted() {
    this.initBpmnModeler();
    this.createNewDiagram(this.value,1);
    this.$once("hook:beforeDestroy", () => {
      if (this.bpmnModeler) this.bpmnModeler.destroy();
      this.$emit("destroy", this.bpmnModeler);
      this.bpmnModeler = null;
    });
  },
  methods: {
    async changeXMLName(val) {
      let { xml } = await this.bpmnModeler.saveXML({ format: true });
      // const parser = new DOMParser();
      // const xmlDoc = parser.parseFromString(xml, 'text/xml');


    },
    initBpmnModeler() {
      console.log(`output->this.bpmnModeler`, this.bpmnModeler)
      if (this.bpmnModeler) return;
      this.bpmnModeler = new BpmnModeler({
        container: this.$refs["bpmn-canvas"],
        keyboard: this.keyboard ? { bindTo: document } : null,
        additionalModules: this.additionalModules,
        moddleExtensions: this.moddleExtensions,
      });
      this.$emit("init-finished", this.bpmnModeler);
      this.initModelListeners();
    },
    initModelListeners() {
      const EventBus = this.bpmnModeler.get("eventBus");
      const that = this;
      // 注册需要的监听事件, 将. 替换为 - , 避免解析异常
      this.events.forEach((event) => {
        EventBus.on(event, function (eventObj) {
          let eventName = event.replace(/\./g, "-");
          let element = eventObj ? eventObj.element : null;
          that.$emit(eventName, element, eventObj);
        });
      });
      // 监听图形改变返回xml
      EventBus.on("commandStack.changed", async (event) => {
        try {
          this.recoverable = this.bpmnModeler.get("commandStack").canRedo();
          this.revocable = this.bpmnModeler.get("commandStack").canUndo();
          let { xml } = await this.bpmnModeler.saveXML({ format: true });
          this.$emit("commandStack-changed", event);
          this.$emit("input", xml);
          this.$emit("change", xml, this.bpmnModeler.getDefinitions());
        } catch (e) {
          console.error(`[Process Designer Warn]: ${e.message || e}`);
        }
      });
      // 监听视图缩放变化
      this.bpmnModeler.on("canvas.viewbox.changed", ({ viewbox }) => {
        this.$emit("canvas-viewbox-changed", { viewbox });
        const { scale } = viewbox;
        this.defaultZoom = Math.floor(scale * 100) / 100;
      });
    },
    /* 创建新的流程图 */
    async createNewDiagram(xml,val) {
      // console.log(`output->val`,val)
      // 将字符串转换成图显示出来
      let newId = this.processId || `Process_${new Date().getTime()}`;
      let newName = this.processName || `业务流程_${new Date().getTime()}`;
      let xmlString = xml || DefaultEmptyXML(newId, newName, this.prefix);
      // console.log(`output->xmlString`, xmlString)
      // localStorage.setItem('xml', xmlString)
      try {
        
        let { warnings } = await this.bpmnModeler.importXML(xmlString);
        this.$emit("init-finished", this.bpmnModeler);
        if (warnings && warnings.length) {
          warnings.forEach((warn) => console.warn(warn));
        }
      } catch (e) {
        console.error(`[Process Designer Warn]: ${e?.message || e}`);
      }
    },

    // 下载流程图到本地
    async downloadProcess(type, name) {
      try {
        const _this = this;
        // 按需要类型创建文件并下载
        if (type === "xml" || type === "bpmn") {
          const { err, xml } = await this.bpmnModeler.saveXML();
          // 读取异常时抛出异常
          if (err) {
            console.error(`[Process Designer Warn ]: ${err.message || err}`);
          }
          let { href, filename } = _this.setEncoded(
            type.toUpperCase(),
            name,
            xml
          );
          downloadFunc(href, filename);
        } else {
          const { err, svg } = await this.bpmnModeler.saveSVG();
          // 读取异常时抛出异常
          if (err) {
            return console.error(err);
          }
          let { href, filename } = _this.setEncoded("SVG", name, svg);
          downloadFunc(href, filename);
        }
      } catch (e) {
        console.error(`[Process Designer Warn ]: ${e.message || e}`);
      }
      // 文件下载方法
      function downloadFunc(href, filename) {
        if (href && filename) {
          let a = document.createElement("a");
          a.download = filename; //指定下载的文件名
          a.href = href; //  URL对象
          a.click(); // 模拟点击
          URL.revokeObjectURL(a.href); // 释放URL 对象
        }
      }
    },

    // 根据所需类型进行转码并返回下载地址
    setEncoded(type, filename = "diagram", data) {
      const encodedData = encodeURIComponent(data);
      return {
        filename: `${filename}.${type}`,
        href: `data:application/${type === "svg" ? "text/xml" : "bpmn20-xml"
          };charset=UTF-8,${encodedData}`,
        data: data,
      };
    },

    // 加载本地文件
    async importLocalFile() {
      const that = this;
      const file = this.$refs.refFile.files[0];
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = function () {
        let xmlStr = this.result;
        that.createNewDiagram(that.changedXml(xmlStr),3);
      };
    },

    // 编辑xml
    changedXml(xmlString) {
      // 假设有如下XML字符串
      // 解析XML字符串
      var parser = new DOMParser();
      var xmlDoc = parser.parseFromString(xmlString, "text/xml");
      // 编辑XML
      // 例如，修改element元素的text内容
      var process = xmlDoc.getElementsByTagName("bpmn2:process")[0];
      let definitions = xmlDoc.getElementsByTagName("bpmn2:definitions")[0];
      definitions.setAttribute("id", `diagram_${this.processId}`);
      process.setAttribute("id", this.processId);
      process.setAttribute("name", this.processName);
      // 将修改后的DOM对象转换回XML字符串
      var serializer = new XMLSerializer();
      var newXmlString = serializer.serializeToString(xmlDoc);

      return newXmlString;
    },
    /* ------------------------------------------------ refs methods ------------------------------------------------------ */
    downloadProcessAsXml() {
      this.downloadProcess("xml");
    },
    downloadProcessAsBpmn() {
      this.downloadProcess("bpmn");
    },
    downloadProcessAsSvg() {
      this.downloadProcess("svg");
    },
    processSimulation() {
      this.simulationStatus = !this.simulationStatus;
      this.simulation && this.bpmnModeler.get("toggleMode").toggleMode();
    },
    processRedo() {
      this.bpmnModeler.get("commandStack").redo();
    },
    processUndo() {
      this.bpmnModeler.get("commandStack").undo();
    },
    processZoomIn(zoomStep = 0.1) {
      let newZoom = Math.floor(this.defaultZoom * 100 + zoomStep * 100) / 100;
      if (newZoom > 4) {
        throw new Error(
          "[Process Designer Warn ]: The zoom ratio cannot be greater than 4"
        );
      }
      this.defaultZoom = newZoom;
      this.bpmnModeler.get("canvas").zoom(this.defaultZoom);
    },
    processZoomOut(zoomStep = 0.1) {
      let newZoom = Math.floor(this.defaultZoom * 100 - zoomStep * 100) / 100;
      if (newZoom < 0.2) {
        throw new Error(
          "[Process Designer Warn ]: The zoom ratio cannot be less than 0.2"
        );
      }
      this.defaultZoom = newZoom;
      this.bpmnModeler.get("canvas").zoom(this.defaultZoom);
    },
    processZoomTo(newZoom = 1) {
      if (newZoom < 0.2) {
        throw new Error(
          "[Process Designer Warn ]: The zoom ratio cannot be less than 0.2"
        );
      }
      if (newZoom > 4) {
        throw new Error(
          "[Process Designer Warn ]: The zoom ratio cannot be greater than 4"
        );
      }
      this.defaultZoom = newZoom;
      this.bpmnModeler.get("canvas").zoom(newZoom);
    },
    processReZoom() {
      this.defaultZoom = 1;
      this.bpmnModeler.get("canvas").zoom("fit-viewport", "auto");
    },
    processRestart() {
      // console.log(111111);
      this.recoverable = false;
      this.revocable = false;
      this.createNewDiagram(null,4);
    },
    elementsAlign(align) {
      const Align = this.bpmnModeler.get("alignElements");
      const Selection = this.bpmnModeler.get("selection");
      const SelectedElements = Selection.get();
      if (!SelectedElements || SelectedElements.length <= 1) {
        this.$message.warning("请按住 Ctrl 键选择多个元素对齐");
        return;
      }
      this.$confirm("自动对齐可能造成图形变形，是否继续？", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => Align.trigger(SelectedElements, align));
    },
    /*-----------------------------    方法结束     ---------------------------------*/
    previewProcessXML() {
      this.bpmnModeler.saveXML({ format: true }).then(({ xml }) => {
        this.previewResult = xml;
        this.previewType = "xml";
        this.previewModelVisible = true;
      });
    },
    previewProcessJson() {
      this.bpmnModeler.saveXML({ format: true }).then(({ xml }) => {
        this.previewResult = convert.xml2json(xml, { spaces: 2 });
        this.previewType = "json";
        this.previewModelVisible = true;
      });
    },
    /* ------------------------------------------------ 火凤源码 methods ------------------------------------------------------ */
    async processSave(type) {
      const { err, xml } = await this.bpmnModeler.saveXML();
      // 读取异常时抛出异常
      if (err) {
        this.$message.error("保存模型失败，请重试!");
        return false;
      }

      let e = this.bpmnModeler.getDefinitions();
      // if (e.rootElements[0].name != this.processName) {
      //   const parser = new DOMParser();
      //   const xmlDoc = parser.parseFromString(xml, 'text/xml');
      //   // 获取process元素
      //   const processElement = xmlDoc.getElementsByTagName('bpmn2:process')[0];
      //   // 替换name值为'流程名称'
      //   processElement.setAttribute('name', this.processName);

      //   // 将修改后的XML转换回字符串
      //   const serializer = new XMLSerializer();
      //   xml = serializer.serializeToString(xmlDoc);
      // }
      // console.log(`output->e123123`, e)
      // console.log(e.rootElements[0].flowElements);
      if (!e.rootElements[0].flowElements) {
        this.$message.error("请创建流程!!");
        return false;
      }
      if (e.rootElements[0].flowElements.length) {
        let flagStartEvent = e.rootElements[0].flowElements.findIndex(
          (e) => e.$type == "bpmn:StartEvent"
        );
        // console.log(flagStartEvent);
        if (flagStartEvent < 0) {
          this.$message.error("请创建开始事件!!");
          return false;
        }
        let flagUserTask = e.rootElements[0].flowElements.filter(
          (e) => e.$type == "bpmn:UserTask"
        );
        if (flagUserTask.length == 0) {
          this.$message.error("请创建用户任务!!");
          return false;
        }
        for (let index = 0; index < flagUserTask.length; index++) {
          const item = flagUserTask[index];
          // console.log(item, "item");
          if (!item.name) {
            this.$message.error("请输入审批节点名称!!");
            return false;
          }
          let { options, type } = item.$attrs;
          if (!type) {
            this.$message.error(`"${item.name}"请选择指派类型`);
            return false;
          }
          options = options ? JSON.parse(options) : "";
          // 指派人员
          if (type == 30) {
            if (!options.userList.length) {
              this.$message.error(`"${item.name}"请选择指派人员`);
              return false;
            }
          }
          // 发起人自选
          if (type == 70) {
            if (options.scope == 1 && !options.candidates.length) {
              this.$message.error(`"${item.name}"请选择指派人员`);
              return false;
            }
            if (options.scope == 2 && !options.candidates.length) {
              this.$message.error(`"${item.name}"请选择用户组`);
              return false;
            }
          }
          // 用户组
          if (type == 40) {
            // console.log(options);
            if (!options.roles.length) {
              this.$message.error(`"${item.name}"请选择用户组`);
              return false;
            }
            if (
              options.reservedType == "assign" &&
              !options.reservedUser.length
            ) {
              this.$message.error(`"${item.name}"请选择指定人员审批`);
              return false;
            }
          }
          // 直属主管
          if (type == 21 || type == 22 || type == 23) {
            if (
              options.reservedType == "assign" &&
              !options.reservedUser.length
            ) {
              this.$message.error(`"${item.name}"请选择指定人员审批`);
              return false;
            }
          }
          //表单内联系人
          if (type == 80) {
            if (!options.contact) {
              this.$message.error(`"${item.name}"请选择表单内联系人`);
              return false;
            }
          }
        }
        let flagEndEvent = e.rootElements[0].flowElements.findIndex(
          (e) => e.$type == "bpmn:EndEvent"
        );
        if (flagEndEvent < 0) {
          this.$message.error("请创建结束事件!!");
          return false;
        }
      }
      // 触发 save 事件
      this.$emit("save", xml);
      return true;
    },
    /** 高亮显示 */
    highlightedCode(previewType, previewResult) {
      const result = hljs.highlight(previewType, previewResult || "", true);
      return result.value || "&nbsp;";
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .djs-popup-header {
  display: none;
}

::v-deep .djs-popup-body {
  .bpmn-icon-subprocess-collapsed {
    display: none;
  }

  .bpmn-icon-call-activity {
    display: none;
  }

  .bpmn-icon-subprocess-expanded {
    display: none;
  }

  .bpmn-icon-script {
    display: none;
  }

  .bpmn-icon-send {
    display: none;
  }

  // .bpmn-icon-service {
  //   display: none;
  // }
  .bpmn-icon-business-rule {
    display: none;
  }

  .bpmn-icon-manual {
    display: none;
  }

  .bpmn-icon-receive {
    display: none;
  }

  .bpmn-icon-task {
    display: none;
  }

  .bpmn-icon-gateway-complex {
    display: none;
  }

  .bpmn-icon-gateway-eventbased {
    display: none;
  }
}
</style>
