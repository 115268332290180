var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-process-palette" }, [
    _c(
      "div",
      {
        staticClass: "test-button",
        on: { click: _vm.addTask, mousedown: _vm.addTask }
      },
      [_vm._v("测试任务")]
    ),
    _c(
      "div",
      { staticClass: "test-container", attrs: { id: "palette-container" } },
      [_vm._v("1")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }