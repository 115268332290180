var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "customdialog" }, [
    _c(
      "div",
      { ref: "dialog", staticClass: "dialog", style: _vm.dialogStyle },
      [
        _c("div", { staticClass: "nav" }, [
          _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
          _c(
            "div",
            {
              staticClass: "close mr-6",
              staticStyle: { width: "16px", height: "16px", cursor: "pointer" },
              on: { click: _vm.closeDialog }
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/close.png"), alt: "" }
              })
            ]
          )
        ]),
        _c(
          "div",
          { staticClass: "dialogMain", style: _vm.dialogMainStyle },
          [_vm._t("dialogMain")],
          2
        ),
        _vm.isFooter
          ? _c("div", { staticClass: "footer" }, [
              _c(
                "div",
                { staticClass: "cancelBtn", on: { click: _vm.closeDialog } },
                [_vm._v("取消")]
              ),
              _c(
                "div",
                {
                  staticClass: "determineBtn",
                  on: { click: _vm.submitDialog }
                },
                [_vm._v("确定")]
              )
            ])
          : _c("div", { staticClass: "Bottom", staticStyle: { height: "8px" } })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }