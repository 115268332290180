var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", { staticClass: "desc" }, [
        _vm._v("选择能发起该审批的部门，不选则默认开放给所有人")
      ]),
      _c(
        "el-button",
        {
          attrs: {
            size: "mini",
            icon: "el-icon-plus",
            type: "primary",
            round: ""
          },
          on: { click: _vm.selectOrg }
        },
        [_vm._v("请选择")]
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "20px" } },
        _vm._l(_vm.select, function(org, index) {
          return _c(
            "el-tag",
            {
              key: index + "_org",
              staticClass: "org-item",
              attrs: {
                type: org.type === "dept" ? "" : "info",
                closable: "",
                size: "mini"
              },
              on: {
                close: function($event) {
                  return _vm.removeOrgItem(index)
                }
              }
            },
            [_vm._v(" " + _vm._s(org.name) + " ")]
          )
        }),
        1
      ),
      _c("org-picker", {
        attrs: { type: "dept", show: _vm.showOrgSelect, selected: _vm.select },
        on: { close: _vm.closeSelect, selectOver: _vm.selected }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }