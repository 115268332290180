<template>
  <div>
    <el-form label-position="top" label-width="90px" :rules="rules">
      <el-form-item label="指派类型" prop="type">
        <el-select
          v-model="nodeProps.type"
          clearable
          style="width: 100%"
          @change="changeType"
        >
          <el-option
            v-for="dict in taskAssignRuleTypeDictDatas"
            :key="parseInt(dict.value)"
            :label="dict.label"
            :value="parseInt(dict.value)"
          />
        </el-select>
      </el-form-item>
      <!-- 指派人员 -->
      <el-form-item
        v-if="nodeProps.type === 10"
        label="指派人员"
        prop="options"
      >
        <el-select
          v-model="nodeProps.options"
          multiple
          clearable
          style="width: 100%"
        >
          <el-option
            v-for="item in $store.state.bpm.roleOptions"
            :key="parseInt(item.roleId)"
            :label="item.roleName"
            :value="item.roleId"
          />
        </el-select>
      </el-form-item>
      <!-- 指定部门 -->
      <el-form-item
        v-if="nodeProps.type === 20 || nodeProps.type === 21"
        label="指派人员"
        prop="options"
      >
        <treeselect
          v-model="nodeProps.options"
          :options="$store.state.bpm.deptTreeOptions"
          multiple
          flat
          :defaultExpandLevel="3"
          placeholder="请选择"
          :normalizer="normalizer"
        />
      </el-form-item>
      <!-- 指定用户 -->
      <el-form-item
        v-if="
          nodeProps.type === 30 ||
          nodeProps.type === 31 ||
          nodeProps.type === 32
        "
        label="指派人员"
        prop="options"
      >
        <el-select
          v-model="nodeProps.options"
          multiple
          clearable
          style="width: 100%"
          filterable
        >
          <el-option
            v-for="item in $store.state.bpm.userOptions"
            :key="item.userId"
            :label="item.userName"
            :value="item.userId"
          />
        </el-select>
      </el-form-item>
      <!-- 指定用户组 -->
      <el-form-item
        v-if="nodeProps.type === 40"
        label="指派人员"
        prop="options"
      >
        <el-select
          v-model="nodeProps.options"
          multiple
          clearable
          style="width: 100%"
        >
          <el-option
            v-for="item in $store.state.bpm.userGroupOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <org-picker
      :single="true"
      :show="showOrgSelect"
      @close="closeSelect"
      :selected="select"
      @selectOver="selected"
    >
    </org-picker>
    <!--    <span slot="footer" class="dialog-footer">-->
    <!--        <el-button size="small">取 消</el-button>-->
    <!--        <el-button type="primary" size="small">确 定</el-button>-->
    <!--    </span>-->
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import orgPicker from "@/components/organizationPicker/organizationPicker.vue";
import { DICT_TYPE, getDictDatas } from "@/utils/dict";
export default {
  name: "ApprovalNodeConfig",
  components: { orgPicker, Treeselect },
  props: {
    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      // 数据字典 指派类型
      taskAssignRuleTypeDictDatas: getDictDatas(
        DICT_TYPE.BPM_TASK_ASSIGN_RULE_TYPE
      ),
      elementBaseInfo: {},
      showOrgSelect: false,
      rules: {
        type: [{ required: true, message: "请选择规则类型", trigger: "blur" }],
        options: [{ required: true, message: "请选择", trigger: "blur" }],
      },
    };
  },

  computed: {
    nodeProps() {
      return this.$store.state.process.selectedNode.props;
    },
    select: {
      get() {
        return this.config.assignedUser || [];
      },
      set(val) {
        // console.log(val)
        this.config.assignedUser = val;
      },
    },
    forms() {
      return this.$store.state.process.design.formItems.map((f) => {
        if (f.type === "USER") {
          return f;
        }
      });
    },
    showMode() {
      switch (this.nodeProps.assignedType) {
        case "ASSIGN_USER":
          return this.nodeProps.assignedUser.length > 0;
        case "SELF_SELECT":
          return this.nodeProps.selfSelect.multiple;
        case "LEADER_TOP":
          return true;
        case "ROLE":
          return true;
        default:
          return false;
      }
    },
  },
  methods: {
    changeType() {
      this.nodeProps.options = [];
    },
    closeSelect() {
      this.showOrgSelect = false;
    },
    selectUser() {
      this.showOrgSelect = true;
    },
    selected(select) {
      // console.log('select',select)
      this.showOrgSelect = false;
      this.select = select;
    },
    removeOrgItem(index) {
      this.select.splice(index, 1);
    },
    //单选框 选择 清空 已选
    handleChange() {
      if (this.select != undefined && this.select.length > 0) {
        if (this.select[0].sname == undefined) {
          this.select = [];
        }
      }
      // this.select = []
      // console.log(this.nodeProps.assignedType)
    },
    normalizer(node) {
      return {
        id: node.deptId,
        label: node.deptName,
        children: node.children,
      };
    },
  },
  beforeDestroy() {
    // if (this.nodeProps.assignedType !== "ASSIGN_USER") {
    //   this.select = [];
    // }
  },
};
</script>

<style lang="scss" scoped>
.radioGroup {
  display: flex;
  flex-wrap: wrap;
}
.user-type {
  ::v-deep .el-radio {
    width: 110px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

::v-deep .line-mode {
  .el-radio {
    width: 150px;
    margin: 5px;
  }
}

::v-deep .el-form-item__label {
  line-height: 25px;
}

::v-deep .approve-mode {
  .el-radio {
    float: left;
    width: 100%;
    display: block;
    margin-top: 15px;
  }
}

::v-deep .approve-end {
  position: relative;

  .el-radio-group {
    width: 160px;
  }

  .el-radio {
    margin-bottom: 5px;
    width: 100%;
  }

  .approve-end-leave {
    position: absolute;
    bottom: -5px;
    left: 150px;
  }
}

::v-deep .el-divider--horizontal {
  margin: 10px 0;
}

.org-item {
  margin: 5px;
}
.item {
  position: relative;
  top: -8px;
  left: -2px;
  font-size: 12px;
  margin: 4px;
}
</style>
