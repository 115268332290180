const getters = {
    // sidebar: state => state.app.sidebar,
    // size: state => state.app.size,
    // device: state => state.app.device,
    // visitedViews: state => state.tagsView.visitedViews,
    // cachedViews: state => state.tagsView.cachedViews,
    // userId: state => state.user.id,
    // token: state => state.user.token,
    // avatar: state => state.user.avatar,
    // name: state => state.user.name,
    // userName: state => state.user.userName,
    // introduction: state => state.user.introduction,
    // roles: state => state.user.roles,
    // permissions: state => state.user.permissions,
    // permission_routes: state => state.permission.routes,
    // // 工具栏
    // topbarRouters:state => state.permission.topbarRouters,
    // defaultRoutes:state => state.permission.defaultRoutes,
    // sidebarRouters:state => state.permission.sidebarRouters,
    // // 数据字典
    dict_datas: state => state.dict.dictDatas
  }
  export default getters
  