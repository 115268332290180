var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "icon-dialog" },
    [
      _c(
        "el-dialog",
        _vm._g(
          _vm._b(
            {
              attrs: { width: "980px", "modal-append-to-body": false },
              on: { open: _vm.onOpen, close: _vm.onClose }
            },
            "el-dialog",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _vm._v(" 选择图标 "),
              _c("el-input", {
                style: { width: "260px" },
                attrs: {
                  size: "mini",
                  placeholder: "请输入图标名称",
                  "prefix-icon": "el-icon-search",
                  clearable: ""
                },
                model: {
                  value: _vm.key,
                  callback: function($$v) {
                    _vm.key = $$v
                  },
                  expression: "key"
                }
              })
            ],
            1
          ),
          _c(
            "ul",
            { staticClass: "icon-ul" },
            _vm._l(_vm.iconList, function(icon) {
              return _c(
                "li",
                {
                  key: icon,
                  class: _vm.active === icon ? "active-item" : "",
                  on: {
                    click: function($event) {
                      return _vm.onSelect(icon)
                    }
                  }
                },
                [_c("i", { class: icon }), _c("div", [_vm._v(_vm._s(icon))])]
              )
            }),
            0
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }