const process = {
    state: {
      nodeMap: new Map(),
      selectedNode: {},
      readonly: false,
      design: {
        formId: null,
        formInfo: [],
        formName: "未命名表单",
        logo: {
          icon: "el-icon-eleme",
          background: "#1e90ff",
        },
        settings: {
          commiter: [],
          admin: [],
          sign: false,
          notify: {
            types: ["APP"],
            title: "消息通知标题",
          },
        },
        group: 0,
        formItems: [],
        process: {},
        remark: "备注说明",
      },
    },
    mutations: {
      selectedNode(state, val) {
        state.selectedNode = val;
      },
      CHANGE_DESIGN(state, val) {
        state.design.process = val;
      },
      UPDATE_FORM_ITEM(state, val) {
        state.design.formItems = val;
      },
      CHANGE_Readonly(state, val) {
        state.readonly = val;
      },
      CHANGE_formId(state, val) {
        state.design.formInfo = val;
      },
    },
    actions: {},
  };
  export default process;
  