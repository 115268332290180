<template>
    <div class="container">
        <div class="left-board">
            <div class="logo-wrapper">
                <div class="logo">流程表单</div>
            </div>
            <el-scrollbar class="left-scrollbar">
                <!-- 左边：表单项 -->
                <div class="components-list">
                    <div v-for="(item, listIndex) in leftComponents" :key="listIndex">
                        <div class="components-title">
                            <!-- <svg-icon icon-class="component" /> -->
                            {{ item.title }}
                        </div>
                        <draggable class="components-draggable" :list="item.list"
                            :group="{ name: 'componentsGroup', pull: 'clone', put: false }" :clone="cloneComponent"
                            draggable=".components-item" :sort="false" @end="onEnd" @start="onStart" :move="onMove">
                            <div v-for="(element, index) in item.list" :key="index" class="components-item"
                                @click="addComponent(element)">
                                <div class="components-body">
                                    <!-- <svg-icon :icon-class="element.__config__.tagIcon" /> -->
                                    {{ element.__config__.label }}
                                </div>
                            </div>
                        </draggable>
                    </div>
                    <!-- <div style="font-size: 14px;margin-bottom: 8px;">表单说明</div> -->
                    <!-- 左边：动态表单 -->
                    <!-- <el-form ref="form" :model="form">
                        <el-input type="textarea" v-model="form.remark" placeholder="表单说明" :row="3" />
                    </el-form> -->
                </div>

            </el-scrollbar>
        </div>

        <div class="center-board">
            <div class="action-bar">

            </div>

            <!-- 中间，表单项 -->
            <div class="center-scrollbar">

                <el-row class="center-board-row">
                    <el-form :size="'small'" :label-position="'top'" :disabled="formConf.disabled"
                        :label-width="formConf.labelWidth + 'px'">
                        <draggable class="drawing-board" :list="drawingList" :animation="340" group="componentsGroup"
                            :style="drawingList.length > 0 ? '' : 'height: 100%'" @add="handleDragChange">
                            <draggable-item v-for="(item, index) in drawingList" :key="item.renderKey"
                                :drawing-list="drawingList" :current-item="item" :index="index" :active-id="activeId"
                                :form-conf="formConf" @activeItem="activeFormItem" @copyItem="drawingItemCopy"
                                @deleteItem="drawingItemDelete" />
                        </draggable>
                        <div v-show="!drawingList.length" class="empty-info">
                            从左侧拖入或点选组件进行表单设计
                        </div>
                    </el-form>
                </el-row>
            </div>
        </div>

        <!-- 右边：组件属性/表单属性 -->
        <right-panel :active-data="activeData" :form-conf="formConf" :formData="drawingList"
            :show-field="!!drawingList.length" @tag-change="tagChange" @fetch-data="fetchData" ref="RightPanel"
            @switchRadioChange="switchRadioChange" :bpmnModeler="bpmnModeler" :checkList="checkList"
            @changeXml="changeXml" />

    </div>
</template>

<script>
import { mapState } from "vuex";
import request from "@/utils/request";
import draggable from "vuedraggable";
import { debounce } from "throttle-debounce";
import { saveAs } from "file-saver";
import ClipboardJS from "clipboard";
import render from "@/components/render/render";
import FormDrawer from "../build/FormDrawer.vue";
//   import JsonDrawer from "../build/JsonDrawer";
import RightPanel from "../build/RightPanel"; //右边表单组件
import {
    inputComponents,
    selectComponents,
    layoutComponents,
    newSelectComponents,
    newKitComponents,
    formConf,
    toolComponents
} from "@/components/generator/config"; //左边组件配置文件
import { beautifierConf, titleCase, deepClone, isObjectObject } from "@/utils";
import {
    makeUpHtml,
    vueTemplate,
    vueScript,
    cssStyle,
} from "@/components/generator/html";
import { makeUpJs } from "@/components/generator/js";
import { makeUpCss } from "@/components/generator/css";
import drawingDefalut from "@/components/generator/drawingDefalut";
import logo from "@/assets/close.png";
import CodeTypeDialog from "../build/CodeTypeDialog";
import DraggableItem from "../build/DraggableItem";
import {
    getDrawingList,
    saveDrawingList,
    getIdGlobal,
    saveIdGlobal,
    getFormConf,
} from "@/utils";
import loadBeautifier from "@/utils/loadBeautifier";
import { CommonStatusEnum } from "@/utils";
import { createForm, getForm, updateForm, getRelationApprovalByC } from "@/api/form";
import { decodeFields } from "@/utils/formGenerator";

let beautifier;
const emptyActiveData = { style: {}, autosize: {} };
let oldActiveId;
let tempActiveData;
const drawingListInDB = getDrawingList();
const formConfInDB = getFormConf();
const idGlobal = getIdGlobal();

export default {
    components: {
        draggable,
        render,
        FormDrawer,
        //   JsonDrawer,
        RightPanel,
        CodeTypeDialog,
        DraggableItem,
    },
    props: ["formInfo", "checkList", "bpmnModeler"],
    data() {
        return {
            queryParams: {
                key: "",
            },
            form: {
                // remark: "",
                name: "动态表单",
                status: CommonStatusEnum.ENABLE,
            },
            logo,
            idGlobal,
            formConf,
            inputComponents,
            selectComponents,
            layoutComponents,
            labelWidth: 100,
            // drawingList: drawingDefalut,
            drawingData: {}, // 生成后的表单数据
            activeId: drawingDefalut[0].__config__.formId,

            drawingList: [], // 表单项的数组
            // activeId: undefined,
            // activeData: {},

            drawerVisible: false,
            formData: {},
            dialogVisible: false,
            generateConf: null,
            showFileName: false,
            activeData: drawingDefalut[0], // 右边编辑器激活的表单项
            saveDrawingListDebounce: debounce(340, saveDrawingList),
            saveIdGlobalDebounce: debounce(340, saveIdGlobal),
            leftComponents: [
                {
                    title: "输入型组件",
                    list: inputComponents,
                },
                {
                    title: "选择型组件",
                    list: selectComponents,
                },
                {
                    title: "布局型组件",
                    list: layoutComponents,
                },
                {
                    title: "增强组件",
                    list: newSelectComponents,
                },
                {
                    title: "组合组件",
                    list: toolComponents,
                },
            ],
            turnPlace: null,
        };
    },
    computed: {
        ...mapState({
            contact: (state) => state.bpm.contact,
        }),
    },

    watch: {
        // eslint-disable-next-line func-names
        "activeData.__config__.label": function (val, oldVal) {
            if (
                this.activeData.placeholder === undefined ||
                !this.activeData.__config__.tag ||
                oldActiveId !== this.activeId
            ) {
                return;
            }
            if (!turnPlace) {
                turnPlace = setTimeout(() => {
                    this.activeData.placeholder = this.activeData.placeholder.replace(oldVal, "") + val
                    turnPlace = null;
                }, 200);
            }
        },
        activeId: {
            handler(val) {
                oldActiveId = val;
            },
            immediate: true,
        },
        drawingList: {
            handler(val) {
                this.saveDrawingListDebounce(val);
                if (val.length === 0) this.idGlobal = 100;
                // console.log(val, "drawingList");
                this.setFormInfo(val);
            },
            deep: true,
        },
        idGlobal: {
            handler(val) {
                this.saveIdGlobalDebounce(val);
            },
            immediate: true,
        },
        formInfo: {
            immediate: true,
            handler(val) {
                if (val.fields) {
                    this.formConf = JSON.parse(val.conf);
                    this.drawingList = decodeFields(val.fields);
                    this.form.id = val.id;
                    // 设置激活的表单项
                    this.activeData = this.drawingList[0];
                    this.activeId = this.activeData.__config__.formId;
                    // 设置 idGlobal，避免重复
                    this.idGlobal += this.drawingList.length;
                    // this.setFormInfo(this.drawingList);
                } else {
                    this.$store.commit("CHANGE_formId", []);
                }
            },
        },
    },
    mounted() {
        // 【add by 火凤源码】不读缓存
        // if (Array.isArray(drawingListInDB) && drawingListInDB.length > 0) {
        //   this.drawingList = drawingListInDB
        // } else {
        //   this.drawingList = drawingDefalut
        // }
        // this.activeFormItem(this.drawingList[0])
        // if (formConfInDB) {
        //   this.formConf = formConfInDB
        // }
        loadBeautifier((btf) => {
            beautifier = btf;
        });
        const clipboard = new ClipboardJS("#copyNode", {
            text: (trigger) => {
                const codeStr = this.generateCode();
                this.$notify({
                    title: "成功",
                    message: "代码已复制到剪切板，可粘贴。",
                    type: "success",
                });
                return codeStr;
            },
        });
        clipboard.on("error", (e) => {
            this.$message.error("代码复制失败");
        });
    },
    created() {
        // const formId = this.$route.query && this.$route.query.flowId;
        // if (formId) {
        //   getForm(formId).then((response) => {
        //     const data = response.data;

        //     console.log(data.form, "data.form");
        //     this.form = data.form
        //   });
        // }
    },
    methods: {
        setFormInfo(data) {
            if (!data) return;
            let fields = [];
            data.forEach((e) => {
                let optList = [];
                if (e.__slot__ && e.__slot__.options) {
                    optList = e.__slot__.options;
                }
                // 行容器处理
                if (e.__config__.layout == "rowFormItem") {
                    fields.push({
                        formType: "rowFormItem",
                        fieldName: e.__config__.form["标题"],
                        fieldVal: e.__config__.componentName,
                        children: e.__config__.children,
                        optList,
                    })
                } else {
                    fields.push({
                        formType: e.__config__.type
                            ? e.__config__.type
                            : e.__config__.tagIcon,
                        fieldName: e.__config__.label,
                        fieldVal: e.__vModel__,
                        isRequired: e.__config__.required,
                        optList,
                    })
                }
            });
            this.$store.commit("CHANGE_formId", fields);
            this.$emit("changeForm", fields);
        },
        setObjectValueReduce(obj, strKeys, data) {
            const arr = strKeys.split(".");
            arr.reduce((pre, item, i) => {
                if (arr.length === i + 1) {
                    pre[item] = data;
                } else if (!isObjectObject(pre[item])) {
                    pre[item] = {};
                }
                return pre[item];
            }, obj);
        },
        setRespData(component, resp) {
            // console.log(component, "component11");
            const { dataPath, renderKey, dataConsumer } = component.__config__;
            if (!dataPath || !dataConsumer) return;
            const respData = dataPath
                .split(".")
                .reduce((pre, item) => pre[item], resp);
            // 将请求回来的数据，赋值到指定属性。
            // 以el-tabel为例，根据Element文档，应该将数据赋值给el-tabel的data属性，所以dataConsumer的值应为'data';
            // 此时赋值代码可写成 component[dataConsumer] = respData；
            // 但为支持更深层级的赋值（如：dataConsumer的值为'options.data'）,使用setObjectValueReduce
            this.setObjectValueReduce(component, dataConsumer, respData);
            const i = this.drawingList.findIndex(
                (item) => item.__config__.renderKey === renderKey
            );
            if (i > -1) this.$set(this.drawingList, i, component);
        },
        fetchData(component) {
            // console.log(this.drawingList, "fetchData");
            const { type } = component.__config__;
            switch (type) {
                case "approval": //关联审批
                    request({
                        url: "/bpm/flowManagement/findGroupAndFlow?isValid=true",
                        method: "get",
                    }).then((res) => {
                        // console.log(res, "3333");
                        component.__config__.options = res.data;
                    });
                    break;
            }
            return component;
        },
        setLoading(component, val) {
            const { directives } = component;
            if (Array.isArray(directives)) {
                const t = directives.find((d) => d.name === "loading");
                if (t) t.value = val;
            }
        },
        async activeFormItem(currentItem) {
            this.handleDragChange();
            if (currentItem.type === "approval") {
                const res = await request({
                    url: "/bpm/flowManagement/findGroupAndFlow?isValid=true",
                    method: "get"
                });
                currentItem.__config__.options = res.data;
            }
            this.activeData = currentItem;
            this.activeId = currentItem.__config__.formId;
        },
        onEnd(obj) {
            if (obj.from !== obj.to) {
                this.fetchData(tempActiveData);
                this.activeData = tempActiveData;
                // this.activeId = this.idGlobal;

            }
        },
        onStart(obj) {
            // console.log(`output->obj`,obj.draggedContext)

        },
        onMove(e) {
            let type = e.draggedContext.element.__config__.type
            // 判断-是否已存在
            let arr = this.drawingList.filter(item => {
                return item.__config__ && item.__config__.type == 'litigant'
            })
            console.log(`output->arr`, arr, type)
            // 符合条件返回false，否则返回true
            if (type == "litigant" && arr.length > 0) {

                return false
            }
            return true
        },
        addComponent(item) {
            if (item.__config__.type == "litigant") {
                let arr = this.drawingList.filter(e => {
                    return e.__config__ && e.__config__.type == 'litigant'
                })
                if (arr.length > 0) {
                    return false
                }
            }
            const clone = this.cloneComponent(item);
            this.fetchData(clone);
            this.drawingList.push(clone);
            this.activeFormItem(clone);
            // console.log(this.drawingList, "addComponent");
        },
        cloneComponent(origin) {
            const clone = deepClone(origin);
            const config = clone.__config__;
            config.span = this.formConf.span; // 生成代码时，会根据span做精简判断
            const show = this.createIdAndKey(clone);
            if (!show) { throw new Error("拷贝被取消"); }
            clone.placeholder !== undefined && (clone.placeholder += config.label);
            tempActiveData = clone;
            return tempActiveData;
        },
        createIdAndKey(item) {
            const config = item.__config__;
            let timeStamp = new Date().getTime()
            if (config.form && config.form.type) {
                // 检查是否已经存在相同类型的组件
                const existingComponent = this.drawingList.some(err => {
                    return err.__config__.componentName && err.__config__.componentName === config.componentName;
                });

                if (existingComponent) {
                    this.$message.warning("组合组件只能添加一个");
                    // 退出当前方法
                    return false;
                }
            }
            // config.formId = ++this.idGlobal;
            config.formId = timeStamp;
            this.activeId = config.formId
            config.renderKey = `${config.formId}${+new Date()}`; // 改变renderKey后可以实现强制更新组件
            if (config.layout === "colFormItem") {
                if (config.type == 'litigant') {
                    item.__vModel__ = 'litigant';
                } else {
                    item.__vModel__ = `field${timeStamp}`;
                }
                if (config.type == 'approval') {
                    item.ref = item.__vModel__
                }
            } else if (config.layout === "rowFormItem") {
                config.componentName = `row${this.idGlobal}`;
                this.idGlobal++;
                !Array.isArray(config.children) && (config.children = []);
                !Array.isArray(config.children[0]) && (config.children[0] = []);
                delete config.label; // rowFormItem无需配置label属性
            }
            return item;
        },
        // 获得表单数据
        AssembleFormData() {
            this.formData = {
                fields: deepClone(this.drawingList),
                ...this.formConf,
            };
        },
        //   save() {
        //     this.$refs.RightPanel.$refs["dynamicValidateForm"].validate((valid) => {
        //       if (!valid) {
        //         return;
        //       } else {
        //         const form = {
        //           conf: JSON.stringify(this.formConf), // 表单配置
        //           fields: this.encodeFields(), // 表单项的数组
        //           ...this.form,
        //         };
        //         console.log(form);
        //         return;
        //       }
        //     });
        //   },
        /** 关闭按钮 */
        close() {
            this.$router.go(-1);
        },
        encodeFields() {
            const fields = [];
            this.drawingList.forEach((item) => {
                fields.push(JSON.stringify(item));
            });
            return fields;
        },
        generate(data) {
            const func = this[`exec${titleCase(this.operationType)}`];
            this.generateConf = data;
            func && func(data);
        },
        execRun(data) {
            this.AssembleFormData();
            this.drawerVisible = true;
        },
        execDownload(data) {
            const codeStr = this.generateCode();
            const blob = new Blob([codeStr], { type: "text/plain;charset=utf-8" });
            saveAs(blob, data.fileName);
        },
        execCopy(data) {
            document.getElementById("copyNode").click();
        },
        empty() {
            this.$confirm("确定要清空所有组件吗？", "提示", { type: "warning" }).then(
                () => {
                    this.drawingList = [];
                    this.idGlobal = 100;
                }
            );
        },
        drawingItemCopy(item, list) {
            console.log(`output->item`, item, list)
            if (item.__config__.type == "litigant") {
                this.$message.warning("实际申请人组件无法复制");
                return
            }
            let clone = deepClone(item);
            clone = this.createIdAndKey(clone);
            let index = list.findIndex(i => i.__vModel__ === item.__vModel__);
            if (index !== -1) {
                list.splice(index + 1, 0, clone); // 在该元素的后面插入 obj
            } else {
                list.push(clone);
            }

            this.activeFormItem(clone);
        },
        drawingItemDelete(index, list) {
            let vModel = list[index] && list[index].__vModel__;
            // 联系人
            if (vModel == this.contact) {
                this.$message.warning("该组件已被设为联系人,无法删除");
                return;
            }
            // 条件分支
            if (this.checkList.length) {
                for (let index = 0; index < this.checkList.length; index++) {
                    const item = this.checkList[index];

                    if (item == vModel) {
                        this.$message.warning("该组件已被设为审批条件,无法删除");
                        return;
                    }
                }
            }
            // 组合套件
            if (list[index].type == 'default' && list[index].__config__.form.type) {
                for (let i = 0; i < list[index].__config__.children[0].length; i++) {
                    const err = list[index].__config__.children[0][i];
                    for (let j = 0; j < this.checkList.length; j++) {
                        const item = this.checkList[j];
                        if (item == err.__vModel__) {
                            this.$message.warning("该组合组件中有组件被设置为审批条件,无法删除");
                            return; // 中断方法执行
                        }
                    }
                }
            }
            // 组合套件下的组件不允许删除
            if (list[index].__config__._type_) {
                this.$message.warning("组合组件中的组件,无法删除");
                return; // 中断方法执行
            }

            //信息关联关联的组件不允许删除
            const list1 = list.find(err => err.__config__.type && err.__config__.type == 'selectOptions');
            if (list1) {
                const hasWarning = list1.__slot__.options.some((e) => {
                    const index = e.defaultChecked?.findIndex(a => a == vModel);
                    if (index > -1) {
                        this.$message.warning("该组件已被设为信息关联,无法删除");
                        return true;
                    }
                    return false;
                });
                if (hasWarning) {
                    return;
                }
            }


            list.splice(index, 1);
            this.$nextTick(() => {
                const len = this.drawingList.length;
                if (len) {
                    this.activeFormItem(this.drawingList[len - 1]);
                }
            });
        },
        generateCode() {
            const { type } = this.generateConf;
            this.AssembleFormData();
            const script = vueScript(makeUpJs(this.formData, type));
            const html = vueTemplate(makeUpHtml(this.formData, type));
            const css = cssStyle(makeUpCss(this.formData));
            return beautifier.html(html + script + css, beautifierConf.html);
        },
        //   showJson() {
        //     this.AssembleFormData();
        //     this.jsonDrawerVisible = true;
        //   },
        download() {
            this.dialogVisible = true;
            this.showFileName = true;
            this.operationType = "download";
        },
        run() {
            this.dialogVisible = true;
            this.showFileName = false;
            this.operationType = "run";
        },
        copy() {
            this.dialogVisible = true;
            this.showFileName = false;
            this.operationType = "copy";
        },
        tagChange(newTag) {
            newTag = this.cloneComponent(newTag);
            const config = newTag.__config__;
            newTag.__vModel__ = this.activeData.__vModel__;
            config.formId = this.activeId;
            config.span = this.activeData.__config__.span;
            this.activeData.__config__.tag = config.tag;
            this.activeData.__config__.tagIcon = config.tagIcon;
            this.activeData.__config__.document = config.document;
            if (
                typeof this.activeData.__config__.defaultValue ===
                typeof config.defaultValue
            ) {
                config.defaultValue = this.activeData.__config__.defaultValue;
            }
            Object.keys(newTag).forEach((key) => {
                if (this.activeData[key] !== undefined) {
                    newTag[key] = this.activeData[key];
                }
            });
            this.activeData = newTag;
            this.updateDrawingList(newTag, this.drawingList);
        },
        updateDrawingList(newTag, list) {
            const index = list.findIndex(
                (item) => item.__config__.formId === this.activeId
            );
            if (index > -1) {
                list.splice(index, 1, newTag);
            } else {
                list.forEach((item) => {
                    if (Array.isArray(item.__config__.children[0]))
                        this.updateDrawingList(newTag, item.__config__.children[0]);
                });
            }
        },
        //   refreshJson(data) {
        //     this.drawingList = deepClone(data.fields);
        //     delete data.fields;
        //     this.formConf = data;
        //   },

        // 拖拽完成后事件
        handleDragChange(a, e, b) {

            // 判断是否为行容器，如果是修改唯一的表示componentName。避免重复
            if (this.drawingList[this.drawingList.length - 1].type == "default") {
                this.drawingList[this.drawingList.length - 1].__config__.componentName = this.drawingList[this.drawingList.length - 1].__config__.componentName ? this.drawingList[this.drawingList.length - 1].__config__.componentName : "row" + (this.drawingList.length - 1);
            }
        },

        switchRadioChange(e) {
            // 获取所有 class 为 'file' 的 div 元素
            const fileDivs = document.getElementsByClassName(this.activeData.__vModel__);

            if (e) {
                fileDivs[0]?.classList.add('hideRadio')
            } else {
                fileDivs[0]?.classList.remove('hideRadio')
            }
        },
        changeXml(xml) {
            this.$emit('changeXml', xml)
        }
    },
};
</script>

<style lang="scss">
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}

@import "@/styles/home";

.hideRadio {
    .el-radio-group {
        display: none;
    }


    .el-checkbox-group {
        display: none;
    }
}
</style>