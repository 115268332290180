var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          attrs: {
            "header-cell-style": { background: "#f5f6f6" },
            data: _vm.formData,
            border: ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "title",
              "show-overflow-tooltip": "",
              label: "表单字段"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.required
                      ? _c("span", { staticStyle: { color: "#c75450" } }, [
                          _vm._v(" * ")
                        ])
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(scope.row.title))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "readOnly", label: "只读" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function(scope) {
                  return [
                    _c(
                      "el-checkbox",
                      {
                        attrs: { indeterminate: _vm.isIndeterminate },
                        on: { change: _vm.handleCheckAllChange },
                        model: {
                          value: _vm.checkAll,
                          callback: function($$v) {
                            _vm.checkAll = $$v
                          },
                          expression: "checkAll"
                        }
                      },
                      [_vm._v("只读")]
                    )
                  ]
                }
              },
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-radio", {
                      attrs: { label: "R", name: scope.row.id },
                      model: {
                        value: scope.row.perm,
                        callback: function($$v) {
                          _vm.$set(scope.row, "perm", $$v)
                        },
                        expression: "scope.row.perm"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "editable", label: "可编辑" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function(scope) {
                  return [
                    _c(
                      "el-checkbox",
                      {
                        attrs: { indeterminate: _vm.isIndeterminate },
                        on: { change: _vm.handleCheckAllChange },
                        model: {
                          value: _vm.checkAll,
                          callback: function($$v) {
                            _vm.checkAll = $$v
                          },
                          expression: "checkAll"
                        }
                      },
                      [_vm._v("可编辑")]
                    )
                  ]
                }
              },
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-radio", {
                      attrs: { label: "E", name: scope.row.id },
                      model: {
                        value: scope.row.perm,
                        callback: function($$v) {
                          _vm.$set(scope.row, "perm", $$v)
                        },
                        expression: "scope.row.perm"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "hide", label: "隐藏" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function(scope) {
                  return [
                    _c(
                      "el-checkbox",
                      {
                        attrs: { indeterminate: _vm.isIndeterminate },
                        on: { change: _vm.handleCheckAllChange },
                        model: {
                          value: _vm.checkAll,
                          callback: function($$v) {
                            _vm.checkAll = $$v
                          },
                          expression: "checkAll"
                        }
                      },
                      [_vm._v("隐藏")]
                    )
                  ]
                }
              },
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-radio", {
                      attrs: { label: "H", name: scope.row.id },
                      model: {
                        value: scope.row.perm,
                        callback: function($$v) {
                          _vm.$set(scope.row, "perm", $$v)
                        },
                        expression: "scope.row.perm"
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }