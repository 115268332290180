var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("node", {
    attrs: {
      title: _vm.config.name,
      "show-error": _vm.showError,
      content: _vm.content,
      "error-info": _vm.errorInfo,
      placeholder: "请设置触发器",
      "header-bgc": "#47bc82",
      "header-icon": "el-icon-set-up"
    },
    on: {
      selected: function($event) {
        return _vm.$emit("selected")
      },
      delNode: function($event) {
        return _vm.$emit("delNode")
      },
      insertNode: function(type) {
        return _vm.$emit("insertNode", type)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }