<!--  -->
<template>
    <div class='customdialog'>
        <div class="dialog" ref="dialog" :style="dialogStyle">
            <div class="nav">
                <div class="title">{{ title }}</div>
                <div class="close mr-6" style="width: 16px;height: 16px;cursor: pointer;" @click="closeDialog"><img
                        src="@/assets/close.png" alt=""></div>
            </div>
            <div class="dialogMain" :style="dialogMainStyle">
                <slot name="dialogMain"></slot>

            </div>
            <div class="footer" v-if="isFooter">
                <div class="cancelBtn" @click="closeDialog">取消</div>
                <div class="determineBtn" @click="submitDialog">确定</div>

            </div>
            <div class="Bottom" style="height: 8px;" v-else></div>

        </div>

    </div>
</template>

<script>

export default {
    name: 'Customdialog',
    components: {},
    data() {
        return {
            dialogStyle: {
                width: this.dialogWidth,
                'border-radius': '8px',
                background: '#ffffff',
                height: this.dialogHeight ? this.dialogHeight : 'auto',
            },
            dialogMainStyle: {
                height: this.isFooter ? 'calc(100% - 118px)' : 'calc(100% - 64px)',
                overflow: 'auto',
            }

        };
    },
    //监听属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    filters: {},
    props: {
        title: {
            type: String,
            default: '标题'
        },
        dialogWidth: {
            type: String,
            default: '500px'
        },
        dialogHeight: {
            type: String,
            default: undefined
        },
        isFooter: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        closeDialog() {
            this.$emit('closeDialog')
        },
        submitDialog(){
            this.$emit('submitDialog')

        }

    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {


    },
    beforeCreate() { },
    beforeMount() { },
    beforeUpdate() { },
    updated() { },
    beforeDestroy() { },
    destroyed() { },
    //如果页面有keep-alive缓存功能，这个函数会触发
    activated() { },
}
</script>
<style lang='scss' scoped>
.customdialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1b1b1b4d;
    z-index: 2;

    // .dialog {}
    .nav {
        height: 56px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            font-size: 14px;
            font-weight: 600;
            color: #0d0d26;
            margin-left: 24px;
        }

    }

    .dialogMain {}

    .footer {
        height: 62px;
        display: flex;
        padding-top: 16px;
        padding-right: 24px;
        justify-content: flex-end;

        .cancelBtn {
            height: 28px;
            width: 68px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            cursor: pointer;
            background-color: #f3f4f6;
            color: #848484;
            margin-right:8px ;

        }

        .determineBtn {
            height: 28px;
            width: 68px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            cursor: pointer;
            background-color: #5669ec;
            color: #fff;

        }
    }
}

//@import url(); 引入公共css类</style>