var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.name && _vm.formConfig.length > 0
        ? _c(
            "el-tabs",
            {
              model: {
                value: _vm.active,
                callback: function($$v) {
                  _vm.active = $$v
                },
                expression: "active"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: _vm.name, name: "properties" } },
                [
                  _c((_vm.selectNode.type || "").toLowerCase(), {
                    tag: "component",
                    attrs: { config: _vm.selectNode.props }
                  })
                ],
                1
              )
            ],
            1
          )
        : _c((_vm.selectNode.type || "").toLowerCase(), {
            tag: "component",
            attrs: { config: _vm.selectNode.props }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }